import React, { useState, useEffect } from 'react'
import deburr from 'lodash/deburr'
import Downshift from 'downshift'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core/styles'
import { styles } from '../styles/js/autosuggestComponent'

let suggestions = []

const renderInput = inputProps => {
  const { InputProps, classes, ref, ...other } = inputProps

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  )
}

const renderSuggestion = suggestionProps => {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps
  const isHighlighted = highlightedIndex === index
  const isSelected = (selectedItem || '').indexOf(suggestion.nome) > -1

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.nome}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.nome}
    </MenuItem>
  )
}

const getSuggestions = (value, { showEmpty = false } = {}) => {
  const inputValue = deburr(value.trim()).toLowerCase()
  const inputLength = inputValue.length

  return inputLength === 0 && !showEmpty
    ? []
    : suggestions.filter(suggestion => {
        return suggestion.nome.toLowerCase().includes(value.toLowerCase())
      })
}

const DownshiftMultiple = props => {
  const { classes, label, inputValue, setInputValue, setParentSuggestions,  placeholder } = props
  const [selectedItem, setSelectedItem] = useState([])

  useEffect(() => {
    setParentSuggestions(selectedItem)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem])

  const handleKeyDown = event => {
    if (selectedItem.length && !inputValue.length && event.key === 'Backspace') {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1))
    }

    if (inputValue && event.key === 'Tab') {
      event.preventDefault()
      if (!selectedItem.includes(inputValue)) {  
        setSelectedItem([...selectedItem, inputValue])
      }
      setInputValue('')
    }
  }

  const handleInputChange = event => {
    setInputValue(event.target.value)
  }

  const handleChange = item => {
    let newSelectedItem = [...selectedItem]
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item]
    }
    setInputValue('')
    setSelectedItem(newSelectedItem)
  }

  const handleDelete = item => () => {
    const newSelectedItem = [...selectedItem]
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
    setSelectedItem(newSelectedItem)
  }

  return (
    <Downshift
      id="downshift-multiple"
      inputValue={inputValue}
      onChange={handleChange}
      selectedItem={selectedItem}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        isOpen,
        inputValue: inputValue2,
        selectedItem: selectedItem2,
        highlightedIndex,
      }) => {
        const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
          onKeyDown: handleKeyDown,
          placeholder: placeholder,
        })

        return (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              classes,
              label: label,
              InputLabelProps: getLabelProps(),
              InputProps: {
                startAdornment: selectedItem.map(item => (
                  <Chip
                    key={item}
                    tabIndex={-1}
                    label={item}
                    className={classes.chip}
                    onDelete={handleDelete(item)}
                  />
                )),
                onBlur,
                onChange: event => {
                  handleInputChange(event)
                  onChange(event)
                },
                onFocus,
              },
              inputProps,
            })}

            {isOpen ? (
              <Paper className={classes.paper} square>
                {getSuggestions(inputValue2).map((suggestion, index) =>
                  renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({ item: suggestion.nome }),
                    highlightedIndex,
                    selectedItem: selectedItem2,
                  }),
                )}
              </Paper>
            ) : null}
          </div>
        )
      }}
    </Downshift>
  )
}

const DownshiftSimple = props => {
  const { classes, label, inputValue, setInputValue, placeholder } = props

  const handleChange = item => {
    setInputValue(item)
  }

  const handleInputChange = event => {
    setInputValue(event.target.value)
  }

  return (
    <div className={classes.root}>
      <Downshift 
        id="downshift-simple"
        inputValue={inputValue}
        onChange={handleChange}
        selectedItem={inputValue}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
        }) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            placeholder: placeholder,
          })

          return (
            <div className={classes.container}>
              {renderInput({
                fullWidth: true,
                classes,
                label: label,
                InputLabelProps: getLabelProps({ shrink: true }),
                InputProps: { 
                  onBlur, 
                  onFocus,
                  onChange: event => {
                    handleInputChange(event)
                    onChange(event)
                  },
                },
                inputProps,
              })}

              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {getSuggestions(inputValue).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion.nome }),
                        highlightedIndex,
                        selectedItem,
                      }),
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          )
        }}
      </Downshift>
    </div>
  )
}

const AutosuggestComponent = props => {
  const { 
    classes,
    inputValue,
    setInputValue,
    setParentSuggestions,
    label, 
    placeholder, 
    multiple,
  } = props

  useEffect(() => {
    if (props.suggestions.length > 0) {
      suggestions = props.suggestions
    }
  }, [props.suggestions])

  useEffect(() => {
    if (inputValue.length < 3) {
      suggestions = []
    }
  }, [inputValue])

  return (
    <div className={classes.root}>
      {multiple ?
        <DownshiftMultiple 
          classes={classes} 
          inputValue={inputValue}
          setInputValue={setInputValue}
          setParentSuggestions={setParentSuggestions}
          label={label} 
          placeholder={placeholder}

        />
          :
        <DownshiftSimple 
          classes={classes} 
          inputValue={inputValue}
          setInputValue={setInputValue}
          label={label} 
          placeholder={placeholder} 
        />
      }
    </div>
  )
}

export default withStyles(styles)(AutosuggestComponent)