import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'

import { Button, Grid } from '@material-ui/core'

import { ApplicationContext } from '../ContextProvider'
import { useRequest } from '../hooks'

import { atendimentosSearchFormFields } from '../constants/initialConstants'
import SearchFormAtendimentos from '../components/SearchForms/SearchFormAtendimentos'
import TableAtendimentos from '../components/Tables/TableAtendimentos'
import * as usuarios from '../constants/usuarios'
import { styles } from '../styles/js/atendimentos'
import { withStyles } from '@material-ui/core/styles'

import moment from 'moment'

const Atendimentos = props => {
  const { user } = useContext(ApplicationContext)
  const { classes, populateLookupField } = props

  const [filterState, setFilterState] = useState({
    ...atendimentosSearchFormFields,
    usuario: user.usuario_crc === usuarios.CRC ? user.nome : '',
  })

  const [tabSelected, setTabSelected] = useState('myTickets')
  const [disableTableButtons, setDisableTableButtons] = useState(false)

  const [pagination, setPagination] = useState({
    currentPage: 0,
    pageSize: window.localStorage.getItem('pageSizeAtendimentos')
      ? JSON.parse(window.localStorage.getItem('pageSizeAtendimentos'))
      : 20,
  })

  const [tickets, statusRequestTickets, fetchTickets] = useRequest(
    [],
    {
      url: 'tickets',
      method: 'get',
      params: {
        pageSize: pagination.pageSize,
        currentPage: pagination.currentPage,
        ...filterState,
        status: filterState.status || 2,
        dataInicial: filterState.dataInicial
          ? moment(filterState.dataInicial).format('YYYY-MM-DD 00:00:00')
          : '',
        dataFinal: filterState.dataFinal
          ? moment(filterState.dataFinal).format('YYYY-MM-DD 23:59:59')
          : '',
      },
    },
    null
  )

  const [regioes, , fetchRegioes] = useRequest(
    [],
    {
      url: 'regioes',
      params: {
        active: 1,
      },
      method: 'get',
    },
    null
  )

  const [categorias, , fetchCategorias] = useRequest(
    [],
    {
      url: 'categorias',
      params: {
        active: 1,
      },
      method: 'get',
    },
    null
  )

  const [origens, , fetchOrigens] = useRequest(
    [],
    {
      url: 'origens',
      params: {
        active: 1,
      },
      method: 'get',
    },
    null
  )

  useEffect(() => {
    fetchRegioes()
    fetchCategorias()
    fetchOrigens()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchTickets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  const filter = tabSelected => {
    setPagination({
      currentPage: 0,
      pageSize: window.localStorage.getItem('pageSizeAtendimentos')
        ? JSON.parse(window.localStorage.getItem('pageSizeAtendimentos'))
        : 20,
    })
    setTabSelected(tabSelected)
    setDisableTableButtons(true)
  }

  const handleOnRowClick = row => {
    if (populateLookupField) {
      populateLookupField(row)
    }
  }

  const activateMyTickets = async () => {
    await setFilterState({
      ...filterState,
      usuario: user.nome,
      otherUsers: false,
    })
    filter('myTickets')
  }

  const activateOtherTickets = async () => {
    await setFilterState({ ...filterState, usuario: '', otherUsers: true })
    filter('otherTickets')
  }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <Button
            color='primary'
            variant={tabSelected === 'myTickets' ? 'contained' : 'text'}
            className={
              tabSelected === 'myTickets'
                ? classes.activeButton
                : classes.button
            }
            onClick={activateMyTickets}
          >
            Meus atendimentos
          </Button>
        </Grid>

        <Grid item xs={12} sm={4} md={3} lg={2}>
          <Button
            color='primary'
            variant={tabSelected === 'otherTickets' ? 'contained' : 'text'}
            className={
              tabSelected === 'otherTickets'
                ? classes.activeButton
                : classes.button
            }
            onClick={activateOtherTickets}
          >
            Outros atendimentos
          </Button>
        </Grid>

        <SearchFormAtendimentos
          filterState={filterState}
          setFilterState={setFilterState}
          filter={filter}
          regioes={regioes}
          categorias={categorias}
          origens={origens}
          setTabSelected={setTabSelected}
        />

        <TableAtendimentos
          rows={tickets.rows === undefined ? [] : tickets.rows}
          rowsLength={tickets.count}
          onRowClick={handleOnRowClick}
          pagination={pagination}
          setPagination={setPagination}
          statusRequestTickets={statusRequestTickets}
          fetchTickets={fetchTickets}
          filterState={filterState}
          disableTableButtons={disableTableButtons}
          setDisableTableButtons={setDisableTableButtons}
        />
      </Grid>
    </div>
  )
}

export default withRouter(withStyles(styles)(Atendimentos))
