import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import TableAtendimentos from '../Tables/TableAtendimentos'

import { styles } from '../../styles/js/dialog'

const DialogResponsavelComponent = (props) => {
  const {
    classes,
    handleClose,
    dialogOpen,
    tickets,
    telespectador,
    statusRequestTickets,
    fetchTickets,
    pagination,
    setPagination,
  } = props

  return (
    <React.Fragment>
      <Dialog
        maxWidth={'lg'}
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="dialogTitle"
        scroll="body"
        PaperProps={{
          className: classes.dialog
        }}
      >
        <DialogTitle id="dialogTitle">
          Atendimentos do {telespectador.nome}
        </DialogTitle>
        
        <DialogContent className={classes.dialogContent}>
          <TableAtendimentos
            rows={tickets.rows === undefined ? [] : tickets.rows}
            rowsLength={tickets.count}
            onRowClick={() => {}}
            pagination={pagination}
            setPagination={setPagination}
            statusRequestTickets={statusRequestTickets}
            fetchTickets={fetchTickets}
            filterState={3}
            tableOnly
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} style={{ color: '#c62334' }}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default withStyles(styles)(DialogResponsavelComponent)