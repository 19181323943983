import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'

import { Button, Grid } from '@material-ui/core'
import CompareArrowsIcon from '@material-ui/icons/CompareArrows'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'

import { styles } from '../../styles/js/tableSection'
import { withStyles } from '@material-ui/core/styles'

import AdvancedTable from '../AdvancedTable.jsx'
import { setLocalStorage } from '../../helpers/table'
import { getTableAtendimentosDefinitions } from '../../constants/tablesDefinitions'
import DialogResponsavel from '../../containers/DialogResponsavel.jsx'
import DialogSuporte from '../../containers/DialogSuporte.jsx'
import DialogFinishTicket from '../../containers/DialogFinishTicket.jsx'
import DialogTelespectador from '../../containers/DialogTelespectador.jsx'
import DialogTicket from '../../containers/DialogTicket.jsx'
import { ApplicationContext } from '../../ContextProvider.jsx'
import * as usuarios from '../../constants/usuarios'

const TableAtendimentos = (props) => {
  const { user } = useContext(ApplicationContext)

  const {
    classes,
    history,
    rows, 
    rowsLength, 
    statusRequestTickets,
    pagination,
    setPagination,
    onRowClick,
    fetchTickets,
    filterState,
    tableOnly,
    disableTableButtons,
    setDisableTableButtons,
  } = props

  const tableDefinitions = getTableAtendimentosDefinitions(pagination)

  const [tableStates, setTableStates] = useState(
    { ...tableDefinitions, rows }
  )

  const [dialogResponsavelOpen, setDialogResponsavelOpen] = useState(false)
  const [dialogSuporteOpen, setDialogSuporteOpen] = useState(false)
  const [dialogFinishTicketOpen, setDialogFinishTicketOpen] = useState(false)
  const [dialogTelespectadorOpen, setDialogTelespectadorOpen] = useState(false)
  const [dialogTicketOpen, setDialogTicketOpen] = useState(false)

  const changePersonInCharge = row => {
    setTableStates({ ...tableStates, row: row })
    setDialogResponsavelOpen(true)
  }

  const requestSupport = row => {
    setTableStates({ ...tableStates, row: row })
    setDialogSuporteOpen(true)
  }

  const finishTicket = row => {
    setTableStates({ ...tableStates, row: row })
    setDialogFinishTicketOpen(true)
  }

  const editTelespectador = row => {
    setTableStates({ ...tableStates, row: row })
    setDialogTelespectadorOpen('edit')
  }

  const editTicket = row => {
    setTableStates({ ...tableStates, row: row })
    setDialogTicketOpen('edit')
  }

  const goToChat = (row) => {
    history.push({ 
      pathname: '/chat/' + row.id, 
      state: { 
        row: row,
      }
    })
  }

  useEffect(() => {
    if (disableTableButtons || filterState.status) {
      setTableStates({ ...tableStates, selection: [] })
      setDisableTableButtons(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableTableButtons, filterState.status])

  useEffect(() => {
    if (rows.length > 0) {
      rows.map(val => val.palavras_chaves.map((valInside) => {
        if (val.palavras !== undefined) {
          val.palavras = val.palavras + `, ${valInside.nome}`
        } else {
          val.palavras = `${valInside.nome}`
        }
      }))
    }
    setTableStates({ ...tableStates, rows: rows, rowsLength: rowsLength })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows])

  useEffect(() => {
    setLocalStorage('pageSizeAtendimentos', pagination.pageSize)
  }, [pagination.pageSize])

  useEffect(() => {
    setLocalStorage('columnOrderAtendimentos', tableStates.columnOrder)
    setLocalStorage('columnWidthsAtendimentos', tableStates.columnWidths)
  }, [tableStates.columnOrder, tableStates.columnWidths])

  return (
    <>
      <Grid container>
        {!tableOnly &&
          <Button 
            onClick={() => changePersonInCharge('')}
            className={classes.button} 
            variant="contained" 
            color="primary"
            disabled={
              !tableStates.selection.length || 
              filterState.status === 3 || 
              filterState.status === 'todos' ||
              user.usuario_crc !== usuarios.CRC
            }>

            <CompareArrowsIcon 
              className={classes.buttonIcon} 
            /> 
            Definir Responsável ({tableStates.selection.length})
          </Button>
        }
        {!tableOnly && 
          <Button 
            onClick={() => requestSupport('')}
            className={classes.button} 
            variant="contained" 
            color="primary"
            disabled={
              !tableStates.selection.length || 
              filterState.status === 3 || 
              filterState.status === 'todos' ||
              user.usuario_crc === usuarios.CONTEUDO ||
              user.usuario_crc === usuarios.CONVIDADO
            }>

            <LiveHelpIcon 
              className={classes.buttonIcon} 
            /> 
            Solicitar Suporte ({tableStates.selection.length})
          </Button>
        }
        {!tableOnly &&
          <Button 
            onClick={() => finishTicket('')}
            className={classes.button + ' ' + classes.buttonFinish} 
            variant="contained" 
            color="primary"
            disabled={
              !tableStates.selection.length || 
              filterState.status === 3 || 
              filterState.status === 'todos' ||
              user.usuario_crc !== usuarios.CRC
            }>

            <DoneAllIcon 
              className={classes.buttonIcon} 
            /> 
            Finalizar Ticket ({tableStates.selection.length})
          </Button>
        }
      </Grid>

      <br/>
      <AdvancedTable
        tableStates={tableStates}
        setTableStates={setTableStates}
        onRowClick={row => onRowClick(row)}
        goToChat={row => goToChat(row)}
        changePersonInCharge={row => changePersonInCharge(row)}
        requestSupport={row => requestSupport(row)}
        finishTicket={row => finishTicket(row)}
        tableEdit={row => editTelespectador(row)}
        tableEditTicket={row => editTicket(row)}
        totalCount={rowsLength}
        pagination={pagination}
        setPagination={setPagination}
        statusRequest={statusRequestTickets}
        showSelect={!tableOnly}
        withoutActions={!tableOnly}
        user={user}
      />

      <DialogResponsavel
        dialogOpen={dialogResponsavelOpen}
        setDialogOpen={setDialogResponsavelOpen}
        fetchTickets={fetchTickets}
        rows={rows}
        selectedRow={tableStates.row}
        selectedRows={tableStates.selection}
        eraseSelectedRows={() => 
          setTableStates({ ...tableStates, selection: [] })
        }
      />

      <DialogSuporte
        dialogOpen={dialogSuporteOpen}
        setDialogOpen={setDialogSuporteOpen}
        fetchTickets={fetchTickets}
        rows={rows}
        selectedRow={tableStates.row}
        selectedRows={tableStates.selection}
        eraseSelectedRows={() => 
          setTableStates({ ...tableStates, selection: [] })
        }
      />

      <DialogFinishTicket
        dialogOpen={dialogFinishTicketOpen}
        setDialogOpen={setDialogFinishTicketOpen}
        fetchTickets={fetchTickets}
        rows={rows}
        selectedRow={tableStates.row}
        selectedRows={tableStates.selection}
        eraseSelectedRows={() => 
          setTableStates({ ...tableStates, selection: [] })
        }
      />

      <DialogTelespectador
        dialogOpen={dialogTelespectadorOpen}
        setDialogOpen={setDialogTelespectadorOpen}
        idTelespectador={tableStates.row ? tableStates.row.id_telespectador : ''}
        fetchTelespectadores={fetchTickets}
        handleTelespectadorDialogReset={() => {}}
      />

      <DialogTicket
        dialogOpen={dialogTicketOpen}
        setDialogOpen={setDialogTicketOpen}
        ticket={tableStates.row}
        fetchTickets={fetchTickets}
        handleTicketDialogReset={() => {}}
      />
    </>
  )
}

export default withStyles(styles)(withRouter(TableAtendimentos))
