import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { Grid } from '@material-ui/core'

import { useRequest } from '../hooks'

import { inboxSearchFormFields } from '../constants/initialConstants'
import SearchFormInbox from '../components/SearchForms/SearchFormInbox'
import TableInbox from '../components/Tables/TableInbox'

import moment from 'moment'

const Inbox = props => {
  const { populateLookupField } = props

  const [filterState, setFilterState] = useState({ ...inboxSearchFormFields })

  const [pagination, setPagination] = useState({
    currentPage: 0,
    pageSize: window.localStorage.getItem('pageSizeInbox')
      ? JSON.parse(window.localStorage.getItem('pageSizeInbox'))
      : 20,
  })

  const [tickets, statusRequestTickets, fetchTickets] = useRequest(
    [],
    {
      url: 'tickets',
      method: 'get',
      params: {
        pageSize: pagination.pageSize,
        currentPage: pagination.currentPage,
        ...filterState,
        status: 1,
        dataInicial: filterState.dataInicial
          ? moment(filterState.dataInicial).format('YYYY-MM-DD 00:00:00')
          : '',
        dataFinal: filterState.dataFinal
          ? moment(filterState.dataFinal).format('YYYY-MM-DD 23:59:59')
          : '',
      },
    },
    null
  )

  const [regioes, , fetchRegioes] = useRequest(
    [],
    {
      url: 'regioes',
      params: {
        active: 1,
      },
      method: 'get',
    },
    null
  )

  const [origens, , fetchOrigens] = useRequest(
    [],
    {
      url: 'origens',
      params: {
        active: 1,
      },
      method: 'get',
    },
    null
  )

  useEffect(() => {
    fetchRegioes()
    fetchOrigens()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchTickets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  // const filter = searchFormState => {
  //   setFilterState(searchFormState)
  // }

  const filter = () => {
    setPagination({
      currentPage: 0,
      pageSize: window.localStorage.getItem('pageSizeInbox')
        ? JSON.parse(window.localStorage.getItem('pageSizeInbox'))
        : 20,
    })
  }

  const handleOnRowClick = row => {
    if (populateLookupField) {
      populateLookupField(row)
    }
  }

  return (
    <div>
      <Grid container>
        <SearchFormInbox
          filterState={filterState}
          setFilterState={setFilterState}
          filter={filter}
          regioes={regioes}
          origens={origens}
        />

        <TableInbox
          rows={tickets.rows === undefined ? [] : tickets.rows}
          rowsLength={tickets.count}
          onRowClick={handleOnRowClick}
          pagination={pagination}
          setPagination={setPagination}
          statusRequestTickets={statusRequestTickets}
          fetchTickets={fetchTickets}
        />
      </Grid>
    </div>
  )
}

export default withRouter(Inbox)
