import React, { useContext } from 'react'
import { useRequest } from '../hooks'
import { SnackbarContext } from '../ContextProvider'
import DialogFinishTicketConteudoComponent from '../components/Dialogs/DialogFinishTicketConteudoComponent'

const DialogFinishTicketConteudo = (props) => {
  const { showSnackbar } = useContext(SnackbarContext)

  const { 
    dialogOpen,
    setDialogOpen,
    ticket,
    fetchTickets,
  } = props

  const [, , finalizarTicketConteudo] = useRequest(
    {},
    {
      url: 'tickets',
      method: 'put',
      data: {
        id: ticket.id,
        categoria: ticket.id_categoria,
        origem: ticket.id_origem,
        praca: ticket.id_regiao,
        status: 5,
      },
    },
    null,
    {
      onComplete: () => {
        showSnackbar('Atendimento #' + ticket.id + ' finalizado com sucesso!', 'success')
        setDialogOpen(false)
        fetchTickets()
      },
      onError: error => {
        showSnackbar(error.response.data.code, 'error')
      },
    }
  )

  return (
    <DialogFinishTicketConteudoComponent
      dialogOpen={dialogOpen}
      description={ticket.id + ' - ' + ticket.telespectador.nome}
      handleClose={() => setDialogOpen(false)}
      handleFinalizar={finalizarTicketConteudo}
    />
  )
}

export default DialogFinishTicketConteudo
