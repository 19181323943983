import * as React from 'react'
import { Link } from 'react-router-dom'
import Chip from '@material-ui/core/Chip'
// import Menu from '@material-ui/core/Menu'
import Tooltip from '@material-ui/core/Tooltip'
// import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  DragDropProvider,
  TableColumnReordering,
  TableColumnResizing,
  TableSelection,
  TableFixedColumns,
} from '@devexpress/dx-react-grid-material-ui'
import {
  SortingState,
  DataTypeProvider,
  PagingState,
  CustomPaging,
  IntegratedSorting,
  IntegratedSelection,
  SelectionState,
} from '@devexpress/dx-react-grid'

import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import EditIcon from '@material-ui/icons/Edit'
import CompareArrowsIcon from '@material-ui/icons/CompareArrows'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
// import InfoIcon from '@material-ui/icons/Info'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import PersonIcon from '@material-ui/icons/Person'
import PhoneIcon from '@material-ui/icons/Phone'
import ChatIcon from '@material-ui/icons/Chat'

import reclameAquiIcon from '../assets/reclame-aqui.png'

import { styles } from '../styles/js/advancedTable'

import moment from 'moment'
import { IconButton } from '@material-ui/core'
import TableLoading from './TableLoading'

import * as usuarios from '../constants/usuarios'

import { READ_ONLY_MODE } from '../constants/api'

const customTableRowBaseStyles = {
  customRow: {
    '&:hover': {
      backgroundColor: '#f5f5f5',
      cursor: 'pointer',
    },
  },
}

const CustomTableRowBase = ({ row, classes, ...restProps }) => (
  <Table.Row className={classes.customRow} {...restProps} />
)

export const CustomTableRow = withStyles(customTableRowBaseStyles, {
  name: 'CustomTableRow',
})(CustomTableRowBase)

class AdvancedTable extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      rows: this.props.tableStates.rows,
      columns: this.props.tableStates.columns,
      selection: this.props.tableStates.selection,
      tableColumnExtensions: this.props.tableStates.tableColumnExtensions,
      columnOrder: this.props.tableStates.columnOrder,
      columnWidths: this.props.tableStates.columnWidths,
      pageSize: this.props.tableStates.pageSize,
      pageSizes: this.props.tableStates.pageSizes,
      currencyColumns: this.props.tableStates.currencyColumns,
      percentColumns: this.props.tableStates.percentColumns,
      booleanColumns: this.props.tableStates.booleanColumns,
      dateColumns: this.props.tableStates.dateColumns,
      typeWithIconsColumns: this.props.tableStates.typeWithIconsColumns,
      multipleActionsColumns: this.props.tableStates.multipleActionsColumns,
      leftColumns: [TableSelection.COLUMN_TYPE, 'id'],
      sorting: [],
      currentPage: this.props.pagination.currentPage,
      totalCount: this.props.totalCount,
      detailsMenu: null,
      detailsMenuData: [],
      statusRequest: this.props.statusRequest,
    }

    this.Cell = props => {
      if (props.row.status) {
        if (props.row.status === 1) {
          props.row.status = 'Aberto'
        }

        if (props.row.status === 2) {
          props.row.status = 'Aguardando Atendimento'
        }

        if (props.row.status === 3) {
          props.row.status = 'Fechado'
        }

        if (props.row.status === 4) {
          props.row.status = 'Aguardando Resposta'
        }

        if (props.row.status === 5) {
          props.row.status = 'Aguardando Finalização'
        }
      }

      if(props.row.id_salesforce_caso) {
        props.row.id = props.row.id_salesforce_caso
      }

      return (
        <Table.Cell
          size='small'
          onClick={this.handleRowClick(props)}
          {...props}
        />
      )
    }

    this.handleRowClick = props => event => {
      if (
        props.column.name === 'actions' ||
        event.target.tagName === 'BUTTON' ||
        event.target.tagName === 'path' ||
        event.target.tagName === 'svg'
      ) {
        return
      }
      this.props.onRowClick(props.row)
    }

    this.getRowId = row => row.id_salesforce_caso || row.id

    const TypeWithIconsFormatter = ({ value }) => {
      let icon = ''
      switch (value.toLowerCase()) {
        case 'facebook':
          icon = <i className='fab fa-facebook fa-2x'></i>
          break
        case 'app':
          icon = <PhoneAndroidIcon />
          break
        case 'e-mail':
          icon = <AlternateEmailIcon />
          break
        case 'telefone':
          icon = <PhoneIcon />
          break
        case 'reclame aqui':
          // icon = <GavelIcon />
          icon = (
            <img
              style={{ width: '1.3rem' }}
              src={reclameAquiIcon}
              alt='Reclame Aqui'
            />
          )
          break
        case 'carta':
          icon = <MailOutlineIcon />
          break
        default:
          icon = ''
      }
      return <p style={{ color: '#006497' }}>{icon}</p>
    }
    this.TypeWithIconsTypeProvider = props => (
      <DataTypeProvider
        formatterComponent={TypeWithIconsFormatter}
        {...props}
      />
    )

    this.handleDetailsMenuClick = row => event => {
      this.setState({
        detailsMenu: event.currentTarget,
        detailsMenuData: row.telespectador,
      })
    }

    this.handleDetailsMenuClose = () => {
      this.setState({ detailsMenu: null })
    }

    const MultipleActionsFormatter = ({ value, row }) => {
      // const { classes } = this.props

      if (this.props.tableStates.atendimentos) {
        return (
          <span>
            <Link to={'/chat/' + row.id}>
              <Tooltip title='Chat'>
                <span>
                  <IconButton>
                    <ChatIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Link>

            {this.props.withoutActions && (
              <Tooltip title='Editar Telespectador'>
                <span>
                  <IconButton
                    onClick={() => this.props.tableEdit(row)}
                    disabled={
                      this.props.user.usuario_crc === usuarios.CONTEUDO ||
                      this.props.user.usuario_crc === usuarios.CONVIDADO ||
                      READ_ONLY_MODE
                    }
                  >
                    <PersonIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {this.props.withoutActions && (
              <Tooltip title='Editar Atendimento'>
                <span>
                  <IconButton
                    onClick={() => this.props.tableEditTicket(row)}
                    disabled={
                      row.status === 'Fechado' ||
                      this.props.user.usuario_crc !== usuarios.CRC ||
                      READ_ONLY_MODE
                    }
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {this.props.withoutActions && (
              <Tooltip title='Definir Responsável'>
                <span>
                  <IconButton
                    onClick={() => this.props.changePersonInCharge(row)}
                    disabled={
                      row.status === 'Fechado' ||
                      this.props.user.usuario_crc !== usuarios.CRC ||
                      READ_ONLY_MODE
                    }
                  >
                    <CompareArrowsIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {/* {this.props.withoutActions && 
              <Tooltip title='Histórico do Atendimento'>
                <span>
                  <IconButton 
                    aria-owns={this.state.detailsMenu ? 'detailsMenu' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleDetailsMenuClick(row)}>
                    <InfoIcon />
                  </IconButton>
                </span>
              </Tooltip>
            }
            <Menu
              id="detailsMenu"
              anchorEl={this.state.detailsMenu}
              open={Boolean(this.state.detailsMenu)}
              onClose={this.handleDetailsMenuClose}
              PaperProps={{
                elevation: 1,
                className: classes.detailsMenu,
              }}
            >
              <Typography paragraph style={{ outline: 0, fontWeight: 500 }}>
                {this.state.detailsMenuData.nome}
              </Typography>
              <Typography paragraph style={{ outline: 0 }}>
                <span style={{ fontWeight: 'bold' }}>01/03/2019</span> - João Pereira assumiu o atendimento.
              </Typography>
              <Typography paragraph style={{ outline: 0 }}>
                <span style={{ fontWeight: 'bold' }}>01/03/2019</span> - João Pereira assumiu o atendimento.
              </Typography>
              <Typography paragraph style={{ outline: 0 }}>
                <span style={{ fontWeight: 'bold' }}>02/03/2019</span> - Fulano de Tal adicionou informações - “Estarei de férias e não poderei continuar com o atendimento.”
              </Typography>
              <Typography paragraph style={{ outline: 0 }}>
                <span style={{ fontWeight: 'bold' }}>01/03/2019</span> - Paulo finalizou o atendimento.
              </Typography>
            </Menu> */}
            {this.props.withoutActions && (
              <Tooltip title='Solicitar Suporte'>
                <span>
                  <IconButton
                    onClick={() => this.props.requestSupport(row)}
                    disabled={
                      row.status === 'Fechado' ||
                      this.props.user.usuario_crc === usuarios.CONTEUDO ||
                      this.props.user.usuario_crc === usuarios.CONVIDADO ||
                      READ_ONLY_MODE
                    }
                  >
                    <LiveHelpIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {this.props.withoutActions && (
              <Tooltip title='Finalizar Ticket'>
                <span>
                  <IconButton
                    disabled={
                      row.status === 'Fechado' ||
                      this.props.user.usuario_crc !== usuarios.CRC ||
                      this.props.user?.id !== row.responsavel?.id ||
                      READ_ONLY_MODE
                    }
                    onClick={() => this.props.finishTicket(row)}
                  >
                    <DoneAllIcon
                      style={{
                        color:
                          row.status === 'Em Atendimento' &&
                          this.props.user.usuario_crc === usuarios.CRC
                            ? '#009688'
                            : '',
                      }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </span>
        )
      } else if (this.props.tableStates.inbox) {
        return (
          <span>
            {(this.props.user.usuario_crc === usuarios.CONVIDADO ||
              this.props.user.usuario_crc === usuarios.CONTEUDO) && (
              <Link to={'/chat/' + row.id}>
                <Tooltip title='Chat'>
                  <span>
                    <IconButton>
                      <ChatIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Link>
            )}

            <Tooltip title='Definir Responsável'>
              <span>
                <IconButton
                  onClick={() => this.props.changePersonInCharge(row)}
                  disabled={this.props.user.usuario_crc !== usuarios.CRC || READ_ONLY_MODE}
                >
                  <CompareArrowsIcon />
                </IconButton>
              </span>
            </Tooltip>
          </span>
        )
      } else if (this.props.tableStates.telespectadores) {
        return (
          <span>
            <Tooltip title='Editar Telespectador'>
              <span>
                <IconButton
                  onClick={() => this.props.tableEdit(row)}
                  disabled={
                    this.props.user.usuario_crc === usuarios.CONTEUDO ||
                    this.props.user.usuario_crc === usuarios.CONVIDADO ||
                    READ_ONLY_MODE
                  }
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
          </span>
        )
      }
    }
    this.MultipleActionsTypeProvider = props => (
      <DataTypeProvider
        formatterComponent={MultipleActionsFormatter}
        {...props}
      />
    )

    // Boolean DataType
    const BooleanFormatter = ({ value }) => (
      <Chip label={value ? 'Sim' : 'Não'} />
    )
    this.BooleanTypeProvider = props => (
      <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />
    )

    // Date DataType
    const DateFormatter = ({ value }) => (
      <p>{moment(value).format('DD/MM/YYYY HH:mm')}</p>
    )
    this.DateTypeProvider = props => (
      <DataTypeProvider formatterComponent={DateFormatter} {...props} />
    )

    this.changeSorting = sorting => this.setState({ sorting })

    this.changeColumnWidths = columnWidths => {
      this.setState({ columnWidths }, () => {
        this.props.setTableStates({
          ...this.props.tableStates,
          columnWidths: columnWidths,
        })
      })
    }

    this.changeCurrentPage = currentPage => {
      this.setState({ currentPage }, () => {
        this.props.setPagination({
          ...this.props.pagination,
          currentPage: currentPage,
        })
      })
    }

    this.changePageSize = pageSize => {
      this.setState({ pageSize }, () => {
        this.props.setPagination({
          ...this.props.pagination,
          pageSize: pageSize,
          currentPage: 0,
        })
      })
    }

    this.changeColumnOrder = order => {
      this.setState({ columnOrder: order }, () => {
        this.props.setTableStates({
          ...this.props.tableStates,
          columnOrder: order,
        })
      })
    }

    this.changeSelection = selection =>
      this.setState({ selection }, () => {
        this.props.setTableStates({
          ...this.props.tableStates,
          selection: selection,
        })
      })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tableStates.rows) {
      this.setState({
        rows: nextProps.tableStates.rows,
        totalCount: nextProps.tableStates.rowsLength,
        statusRequest: nextProps.statusRequest,
      })
    }

    if (!nextProps.pagination.currentPage) {
      this.setState({
        currentPage: nextProps.pagination.currentPage,
      })
    }

    if (!this.state.statusRequest.done) {
      this.setState({
        selection: [],
      })
    }
  }

  render() {
    const { classes } = this.props
    const {
      rows,
      columns,
      selection,
      tableColumnExtensions,
      sorting,
      columnWidths,
      currentPage,
      pageSize,
      pageSizes,
      columnOrder,
      booleanColumns,
      dateColumns,
      typeWithIconsColumns,
      multipleActionsColumns,
      leftColumns,
      totalCount,
    } = this.state

    return (
      <div className={classes.root}>
        <Paper className={classes.container} elevation={0}>
          <Grid
            rows={rows}
            columns={columns}
            getRowId={this.getRowId}
            getCellValue={(row, columnName) =>
              columnName.split('.').reduce((a, b) => {
                if (typeof a === 'object') return a[b] || ''
                else return a
              }, row)
            }
          >
            <SortingState
              sorting={sorting}
              onSortingChange={this.changeSorting}
            />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={this.changeCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={this.changePageSize}
            />

            <CustomPaging totalCount={totalCount} />

            <IntegratedSorting />

            <SelectionState
              selection={selection}
              onSelectionChange={this.changeSelection}
            />

            <IntegratedSelection />

            <this.TypeWithIconsTypeProvider for={typeWithIconsColumns} />

            <this.MultipleActionsTypeProvider for={multipleActionsColumns} />

            <this.BooleanTypeProvider for={booleanColumns} />

            <this.DateTypeProvider for={dateColumns} />

            <DragDropProvider />

            <Table
              rowComponent={this.props.withHover ? CustomTableRow : Table.Row}
              columnExtensions={tableColumnExtensions}
              cellComponent={this.Cell}
              messages={{
                noData: this.state.statusRequest.done
                  ? 'Não há registros na tabela!'
                  : '',
              }}
            />
            <TableColumnResizing
              columnWidths={columnWidths}
              onColumnWidthsChange={this.changeColumnWidths}
            />
            <TableColumnReordering
              order={columnOrder}
              onOrderChange={this.changeColumnOrder}
            />
            <TableHeaderRow showSortingControls />
            {this.props.showSelect ? <TableSelection showSelectAll /> : ''}

            <TableFixedColumns leftColumns={leftColumns} />

            <PagingPanel
              pageSizes={pageSizes}
              messages={{
                showAll: 'Todos',
                rowsPerPage: 'Resultados por página',
              }}
            />
          </Grid>
        </Paper>
        {!this.state.statusRequest.done && <TableLoading />}
      </div>
    )
  }
}

export default withStyles(styles)(AdvancedTable)
