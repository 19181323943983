export const styles = theme => ({
  button: {
    marginRight: 10,
    borderRadius: 0,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginRight: 0,
      marginBottom: 10,
    },
  },

  buttonFinish: {
    backgroundColor: '#009688',
    color: 'white',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#00776C',
    }
  },

  buttonSave: {
    backgroundColor: '#53aba6',
    color: 'white',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#009688',
    },
  },

  buttonIcon: {
    fontSize: '13pt',
    marginRight: 10,
  },
});