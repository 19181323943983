import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import MaskedInputComponent from '../../components/MaskedInputComponent.jsx'
import DialogLookup from '../../components/Dialogs/DialogLookup'
import Cidades from '../../containers/Cidades'
import { styles } from '../../styles/js/dialog'
import { withStyles } from '@material-ui/core/styles'
import { validateEmail } from '../../helpers/validation'
import { telespectadoresValidationState } from '../../constants/initialConstants'
import * as usuarios from '../../constants/usuarios'
import { ApplicationContext } from '../../ContextProvider'

import { READ_ONLY_MODE } from '../../constants/api'

const TelespectadorDialogComponent = props => {
  const { user } = useContext(ApplicationContext)

  const {
    classes,
    regioes,
    handleAction,
    dialogOpen,
    handleClose,
    actionTitle,
    telespectador,
    setTelespectador,
    cidade,
    fetchCidade,
    statusRequest,
  } = props

  const [validation, setValidation] = useState(telespectadoresValidationState)
  const [isDialogLookupOpen, setIsDialogLookupOpen] = useState(false)

  useEffect(() => {
    if (cidade) {
      setTelespectador({
        ...telespectador,
        endereco: {
          ...telespectador.endereco,
          id_cidade: cidade.id,
          cidade: {
            ...telespectador.endereco.cidade,
            id: cidade.id,
            id_ibge: cidade.id_ibge,
            nome: cidade.nome,
          },
        },
      })
    } else {
      setTelespectador({
        ...telespectador,
        endereco: {
          ...telespectador.endereco,
          id_cidade: '',
          cidade: null,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cidade])

  useEffect(() => {
    if (telespectador.endereco.id_ibge) {
      setTimeout(() => {
        fetchCidade()
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [telespectador.endereco.id_ibge])

  useEffect(() => {
    if (telespectador.endereco.fora_do_estado) {
      setTelespectador({
        ...telespectador,
        endereco: {
          ...telespectador.endereco,
          fora_do_estado: telespectador.endereco.fora_do_estado,
          cep: '',
          logradouro: '',
          numero: '',
          bairro: '',
          estado: '',
          id_ibge: '',
          id_cidade: '',
          cidade: '',
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [telespectador.endereco.fora_do_estado])

  const handleChange = event => {
    setTelespectador({
      ...telespectador,
      [event.target.id]: event.target.value,
    })

    validateField(event.target.id, event.target.value)
  }

  const handleMultipleChange = changes => {
    setTelespectador({
      ...telespectador,
      ...changes,
    })
  }

  const handleEnderecoValueChange = event => {
    handleMultipleChange({
      endereco: {
        ...telespectador.endereco,
        [event.target.id]: event.target.value,
      },
    })
  }

  const handleValidationChange = (key, value) => {
    setValidation({
      ...validation,
      [key]: value,
    })
  }

  const validateField = (name, value) => {
    let validationValue = { valid: false, text: '' }
    switch (name) {
      case 'nome':
        validationValue =
          value !== ''
            ? { valid: true, text: '' }
            : { valid: false, text: 'Por favor, insira um nome.' }
        break
      case 'email':
        validationValue = validateEmail(value)
          ? { valid: true, text: '' }
          : { valid: false, text: 'Por favor, insira um email válido.' }
        break
      default:
        break
    }

    handleValidationChange(name, validationValue)
  }

  const renderError = name =>
    !validation[name].valid && validation[name].text !== ''

  const renderErrorMessages = () => {
    const invalidFields = Object.keys(validation)
      .map(key => ({ ...validation[key], name: key }))
      .filter(item => !item.valid && item.text !== '')

    if (invalidFields.length > 0) {
      return invalidFields.map(item => (
        <Typography
          style={{ color: '#f44336' }}
          paragraph
          key={`${item.name}-error`}
        >
          {item.text}
        </Typography>
      ))
    } else {
      return <p></p>
    }
  }

  const autocompleteWithZipCode = async () => {
    const cepSanitizado = telespectador.endereco.cep.replace(/([\D])/i, '')
    const cepData = await axios.get(
      `https://viacep.com.br/ws/${cepSanitizado}/json/`
    )

    if (!cepData.data.erro) {
      handleMultipleChange({
        endereco: {
          ...telespectador.endereco,
          logradouro: cepData.data.logradouro,
          numero: '',
          bairro: cepData.data.bairro,
          estado: cepData.data.uf,
          id_ibge: cepData.data.ibge,
        },
      })
    }
  }

  return (
    <React.Fragment>
      <Dialog
        maxWidth={'md'}
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby='dialogTitle'
        scroll='body'
        PaperProps={{
          className: classes.dialog,
        }}
      >
        <DialogTitle id='dialogTitle'>{actionTitle} Telespectador</DialogTitle>

        {statusRequest ? (
          <DialogContent className={classes.dialogContent}>
            {renderErrorMessages()}
            <Typography variant='subtitle1'>Dados Gerais</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id='nome'
                  label='Nome'
                  className={classes.textField}
                  value={telespectador.nome}
                  onChange={handleChange}
                  type='nome'
                  name='nome'
                  margin='normal'
                  error={renderError('nome')}
                  fullWidth
                  disabled={user.usuario_crc !== usuarios.CRC || READ_ONLY_MODE}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id='praca'
                  name='praca'
                  select
                  label='Praça'
                  className={classes.textField}
                  value={telespectador.id_regiao}
                  onChange={event =>
                    handleChange({
                      target: { id: 'id_regiao', value: event.target.value },
                    })
                  }
                  margin='normal'
                  fullWidth
                  disabled={user.usuario_crc !== usuarios.CRC || READ_ONLY_MODE}
                >
                  {regioes.map(option => (
                    <MenuItem key={option.id} id='praca' value={option.id}>
                      {option.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id='email'
                  label='E-mail'
                  className={classes.textField}
                  value={telespectador.email}
                  onChange={handleChange}
                  type='email'
                  name='email'
                  autoComplete='off'
                  margin='normal'
                  fullWidth
                  disabled={user.usuario_crc !== usuarios.CRC || READ_ONLY_MODE}
                  error={renderError('email')}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <MaskedInputComponent
                  handleParentState={handleChange}
                  id='telefone'
                  name='telefone'
                  label='Telefone'
                  state='telefone'
                  value={telespectador.telefone}
                  phoneField
                  disabled={user.usuario_crc !== usuarios.CRC || READ_ONLY_MODE}
                />
              </Grid>
            </Grid>

            <Typography variant='subtitle1' style={{ marginTop: 30 }}>
              Endereço
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={telespectador.endereco.fora_do_estado}
                      onChange={event =>
                        setTelespectador({
                          ...telespectador,
                          endereco: {
                            ...telespectador.endereco,
                            fora_do_estado: event.target.checked,
                          },
                        })
                      }
                      value='fora_do_estado'
                      disabled={user.usuario_crc !== usuarios.CRC || READ_ONLY_MODE}
                    />
                  }
                  label='Fora do Estado?'
                  style={{ paddingBottom: 0, marginTop: 16 }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <MaskedInputComponent
                  handleParentState={handleEnderecoValueChange}
                  id='cep'
                  name='cep'
                  label='CEP'
                  state='cep'
                  disabled={
                    telespectador.endereco.fora_do_estado ||
                    user.usuario_crc !== usuarios.CRC ||
                    READ_ONLY_MODE
                  }
                  value={telespectador.endereco.cep}
                  autocompleteWithZipCode={autocompleteWithZipCode}
                  zipCodeField
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={7}>
                <TextField
                  id='logradouro'
                  label='Logradouro'
                  className={classes.textField}
                  value={telespectador.endereco.logradouro || ''}
                  onChange={handleEnderecoValueChange}
                  disabled={
                    telespectador.endereco.fora_do_estado ||
                    user.usuario_crc !== usuarios.CRC ||
                    READ_ONLY_MODE
                  }
                  name='logradouro'
                  autoComplete='off'
                  margin='normal'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <TextField
                  id='numero'
                  label='Número'
                  className={classes.textField}
                  value={telespectador.endereco.numero || ''}
                  onChange={handleEnderecoValueChange}
                  disabled={
                    telespectador.endereco.fora_do_estado ||
                    user.usuario_crc !== usuarios.CRC ||
                    READ_ONLY_MODE
                  }
                  name='numero'
                  type='number'
                  autoComplete='off'
                  margin='normal'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id='bairro'
                  label='Bairro'
                  className={classes.textField}
                  value={telespectador.endereco.bairro || ''}
                  onChange={handleEnderecoValueChange}
                  disabled={
                    telespectador.endereco.fora_do_estado ||
                    user.usuario_crc !== usuarios.CRC ||
                    READ_ONLY_MODE
                  }
                  name='bairro'
                  autoComplete='off'
                  margin='normal'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  color='primary'
                  style={{
                    width: '100%',
                    marginTop: 24,
                    fontSize: '1rem',
                    textTransform: 'none',
                  }}
                  onClick={() => setIsDialogLookupOpen(true)}
                  disabled={
                    telespectador.endereco.fora_do_estado ||
                    user.usuario_crc !== usuarios.CRC ||
                    READ_ONLY_MODE
                  }
                >
                  {telespectador.endereco.cidade
                    ? 'Cidade: ' + telespectador.endereco.cidade.nome
                    : 'Clique aqui para escolher uma cidade'}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <LinearProgress />
        )}

        <DialogActions>
          {!READ_ONLY_MODE ? (<>
          <Button onClick={handleClose} style={{ color: '#c62334' }}>
              Cancelar
          </Button>
          <Button
            onClick={handleAction}
            style={{ color: '#009d93' }}
            disabled={user.usuario_crc !== usuarios.CRC}
          >
            {actionTitle}
          </Button>
          </>) : (
            <Button onClick={handleClose} style={{ color: '#c62334' }}>
            Fechar
          </Button>
          )}
        </DialogActions>
      </Dialog>

      <DialogLookup
        Component={Cidades}
        title={'Cidades'}
        size='xs'
        isOpen={isDialogLookupOpen}
        setIsOpen={setIsDialogLookupOpen}
        setLookupField={row =>
          setTelespectador({
            ...telespectador,
            endereco: {
              ...telespectador.endereco,
              id_cidade: row.id,
              cidade: {
                ...telespectador.endereco.cidade,
                id: row.id,
                id_ibge: row.id_ibge,
                nome: row.nome,
              },
            },
          })
        }
      />
    </React.Fragment>
  )
}

export default withStyles(styles)(TelespectadorDialogComponent)
