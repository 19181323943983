
const inboxColumns = ['id', 'origem.nome', 'regiao.nome', 'abertura', 'telespectador.nome', 'acoes']

export const getTableInboxDefinitions = (pagination) => {
  return {
    columns: [
      { name: inboxColumns[0], title: 'ID' },
      { name: inboxColumns[1], title: 'Origem' },
      { name: inboxColumns[2], title: 'Praça' },
      { name: inboxColumns[3], title: 'Abertura' },
      { name: inboxColumns[4], title: 'Telespectador' },
      { name: inboxColumns[5], title: 'Ações' },
    ],
    selection: [],
    row: '',
    columnOrder: 
      window.localStorage.getItem('columnOrderInbox') ? 
        JSON.parse(window.localStorage.getItem('columnOrderInbox')) : 
        inboxColumns,
    columnWidths: 
      window.localStorage.getItem('columnWidthsInbox') ?
        JSON.parse(window.localStorage.getItem('columnWidthsInbox')) :   
        [
          { columnName: inboxColumns[0], width:  55 },
          { columnName: inboxColumns[1], width:  90 },
          { columnName: inboxColumns[2], width: 175 },
          { columnName: inboxColumns[3], width: 345 },
          { columnName: inboxColumns[4], width: 185 },
          { columnName: inboxColumns[5], width: 150 },
        ],
    tableColumnExtensions: [
      { columnName: inboxColumns[0], wordWrapEnabled: true },
      { columnName: inboxColumns[1], wordWrapEnabled: true },
      { columnName: inboxColumns[2], wordWrapEnabled: true },
      { columnName: inboxColumns[3], wordWrapEnabled: true },
      { columnName: inboxColumns[4], wordWrapEnabled: true },
      { columnName: inboxColumns[5], wordWrapEnabled: true },
    ],
    pageSize: pagination.pageSize,
    pageSizes: [7, 10, 20, 50, 75, 100],
    currencyColumns: [''],
    percentColumns: [''],
    booleanColumns: [''],
    dateColumns: ['abertura'],
    typeWithIconsColumns: ['origem.nome'],
    multipleActionsColumns: ['acoes'],
    inbox: true,
  }
}

const telespectadoresColumns = ['nome', 'email', 'telefone', 'regiao.nome', 'acoes']

export const getTableTelespectadoresDefinitions = (pagination, telespectadoresApp) => {
  return {
    columns: [
      { name: telespectadoresColumns[0], title: 'Nome' },
      { name: telespectadoresColumns[1], title: 'E-mail' },
      { name: telespectadoresColumns[2], title: 'Telefone' },
      { name: telespectadoresColumns[3], title: 'Praça' },
      { name: telespectadoresColumns[4], title: 'Ações' },
    ],
    row: '',
    columnOrder: 
      window.localStorage.getItem('columnOrderTelespectadores') ? 
        JSON.parse(window.localStorage.getItem('columnOrderTelespectadores')) : 
        telespectadoresColumns,
    columnWidths: 
      window.localStorage.getItem('columnWidthsTelespectadores') ?
        JSON.parse(window.localStorage.getItem('columnWidthsTelespectadores')) :   
        [
          { columnName: telespectadoresColumns[0], width: 315 },
          { columnName: telespectadoresColumns[1], width: 305 },
          { columnName: telespectadoresColumns[2], width: 255 },
          { columnName: telespectadoresColumns[3], width: 260 },
          { columnName: telespectadoresColumns[4], width:  75 },
        ],
    tableColumnExtensions: [
      { columnName: telespectadoresColumns[0], wordWrapEnabled: true },
      { columnName: telespectadoresColumns[1], wordWrapEnabled: true },
      { columnName: telespectadoresColumns[2], wordWrapEnabled: true },
      { columnName: telespectadoresColumns[3], wordWrapEnabled: true },
      { columnName: telespectadoresColumns[4], wordWrapEnabled: true },
    ],
    pageSize: pagination.pageSize,
    pageSizes: [7, 10, 20, 50, 75, 100],
    currencyColumns: [''],
    percentColumns: [''],
    booleanColumns: [''],
    dateColumns: [''],
    typeWithIconsColumns: [''],
    multipleActionsColumns: telespectadoresApp ? [''] : ['acoes'],
    telespectadores: true,
  }
}

const atendimentosColumns = ['id', 'origem.nome', 'status', 'categoria.nome', 'abertura', 'regiao.nome', 'telespectador.nome', 'responsavel.nome', 'palavras', 'acoes']

export const getTableAtendimentosDefinitions = (pagination) => {
  return {
    columns: [
      { name: atendimentosColumns[0], title: 'ID' },
      { name: atendimentosColumns[1], title: 'Origem' },
      { name: atendimentosColumns[2], title: 'Status' },
      { name: atendimentosColumns[3], title: 'Categoria' },
      { name: atendimentosColumns[4], title: 'Abertura' },
      { name: atendimentosColumns[5], title: 'Praça' },
      { name: atendimentosColumns[6], title: 'Telespectador' },
      { name: atendimentosColumns[7], title: 'Responsável' },
      { name: atendimentosColumns[8], title: 'Palavras-Chave'},
      { name: atendimentosColumns[9], title: 'Ações' },
    ],
    row: '',
    selection: [],
    columnOrder: 
      window.localStorage.getItem('columnOrderAtendimentos') ? 
        JSON.parse(window.localStorage.getItem('columnOrderAtendimentos')) : 
        atendimentosColumns,
    columnWidths: 
      window.localStorage.getItem('columnWidthsAtendimentos') ?
        JSON.parse(window.localStorage.getItem('columnWidthsAtendimentos')) :   
        [
          { columnName: atendimentosColumns[0], width: 55 },
          { columnName: atendimentosColumns[1], width: 80 },
          { columnName: atendimentosColumns[2], width: 90 },
          { columnName: atendimentosColumns[3], width: 109 },
          { columnName: atendimentosColumns[4], width: 106 },
          { columnName: atendimentosColumns[5], width:  93 },
          { columnName: atendimentosColumns[6], width: 165 },
          { columnName: atendimentosColumns[7], width: 165 },
          { columnName: atendimentosColumns[8], width: 140 },
          { columnName: atendimentosColumns[9], width: 350 },
        ],
    tableColumnExtensions: [
      { columnName: atendimentosColumns[0], wordWrapEnabled: true },
      { columnName: atendimentosColumns[1], wordWrapEnabled: true },
      { columnName: atendimentosColumns[2], wordWrapEnabled: true },
      { columnName: atendimentosColumns[3], wordWrapEnabled: true },
      { columnName: atendimentosColumns[4], wordWrapEnabled: true },
      { columnName: atendimentosColumns[5], wordWrapEnabled: true },
      { columnName: atendimentosColumns[6], wordWrapEnabled: true },
      { columnName: atendimentosColumns[7], wordWrapEnabled: true },
      { columnName: atendimentosColumns[8], wordWrapEnabled: true },
      { columnName: atendimentosColumns[9], wordWrapEnabled: true },
    ],
    pageSize: pagination.pageSize,
    pageSizes: [7, 10, 20, 50, 75, 100],
    currencyColumns: [''],
    percentColumns: [''],
    booleanColumns: [''],
    dateColumns: ['abertura'],
    typeWithIconsColumns: ['origem.nome'],
    multipleActionsColumns: ['acoes'],
    atendimentos: true,
  }
}

const usuariosColumns = ['nome', 'email', 'matricula']

export const getTableUsuariosDefinitions = (pagination) => {
  return {
    columns: [
      { name: usuariosColumns[0], title: 'Nome' },
      { name: usuariosColumns[1], title: 'E-mail' },
      { name: usuariosColumns[2], title: 'Matrícula' },
    ],
    selection: [],
    row: '',
    columnOrder: 
      window.localStorage.getItem('columnOrderUsuarios') ? 
        JSON.parse(window.localStorage.getItem('columnOrderUsuarios')) : 
        usuariosColumns,
    columnWidths: 
      window.localStorage.getItem('columnWidthsUsuarios') ?
        JSON.parse(window.localStorage.getItem('columnWidthsUsuarios')) :   
        [
          { columnName: usuariosColumns[0], width: 315 },
          { columnName: usuariosColumns[1], width: 305 },
          { columnName: usuariosColumns[2], width: 255 },
        ],
    tableColumnExtensions: [
      { columnName: usuariosColumns[0], wordWrapEnabled: true },
      { columnName: usuariosColumns[1], wordWrapEnabled: true },
      { columnName: usuariosColumns[2], wordWrapEnabled: true },
    ],
    pageSize: pagination.pageSize,
    pageSizes: [7, 10, 20],
    currencyColumns: [''],
    percentColumns: [''],
    booleanColumns: [''],
    dateColumns: [''],
    typeWithIconsColumns: [''],
    multipleActionsColumns: [''],
    usuarios: true,
  }
}

const cidadesColumns = ['nome']

export const getTableCidadesDefinitions = (pagination) => {
  return {
    columns: [
      { name: cidadesColumns[0], title: 'Nome da Cidade' },
    ],
    selection: [],
    row: '',
    columnOrder: 
      window.localStorage.getItem('columnOrderCidades') ? 
        JSON.parse(window.localStorage.getItem('columnOrderCidades')) : 
        cidadesColumns,
    columnWidths: 
      window.localStorage.getItem('columnWidthsCidades') ?
        JSON.parse(window.localStorage.getItem('columnWidthsCidades')) :   
        [
          { columnName: cidadesColumns[0], width: 300 },
        ],
    tableColumnExtensions: [
      { columnName: cidadesColumns[0], wordWrapEnabled: true },
    ],
    pageSize: pagination.pageSize,
    pageSizes: [400],
    currencyColumns: [''],
    percentColumns: [''],
    booleanColumns: [''],
    dateColumns: [''],
    typeWithIconsColumns: [''],
    multipleActionsColumns: [''],
  }
}