export const styles = theme => ({
  viewerSectionRoot: {
    width: '100%',
    display: 'table',
    height: 'calc(100vh - 100px)',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 16,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      marginTop: 16,
    },
  },
});