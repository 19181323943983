import React, { useState, useEffect, useContext } from 'react'

import { useRequest } from '../hooks'

import DialogFinishTicketComponent from '../components/Dialogs/DialogFinishTicketComponent.jsx'
import { initialFinishTicketState } from '../constants/initialConstants'
import { SnackbarContext } from '../ContextProvider'

const DialogFinishTicket = props => {
  const { showSnackbar } = useContext(SnackbarContext)

  const {
    dialogOpen,
    setDialogOpen,
    fetchTickets,
    rows,
    selectedRow,
    selectedRows,
    eraseSelectedRows,
  } = props

  const [finishTicketDialogState, setFinishTicketDialogState] = useState(
    initialFinishTicketState
  )

  const [palavrasChaveInput, setPalavrasChaveInput] = useState('')

  const [, , finalizarTicket] = useRequest(
    {},
    {
      url: 'tickets_tags',
      method: 'post',
      data: {
        ...finishTicketDialogState,
      },
    },
    null,
    {
      onComplete: () => {
        eraseSelectedRows()
        showSnackbar('Ticket(s) finalizado(s) com sucesso!', 'success')
        setFinishTicketDialogState(initialFinishTicketState)
        fetchTickets()
      },
    }
  )

  const [, , finalizarTicketPesquisa] = useRequest(
    {},
    {
      url: 'tickets_tags',
      method: 'post',
      data: {
        ...finishTicketDialogState,
        sendPesquisa: true,
      },
    },
    null,
    {
      onComplete: () => {
        eraseSelectedRows()
        showSnackbar('Ticket(s) finalizado(s) com sucesso!', 'success')
        setFinishTicketDialogState(initialFinishTicketState)
        fetchTickets()
      },
    }
  )

  const [categorias, statusRequestCategorias, fetchCategorias] = useRequest(
    [],
    {
      url: 'categorias',
      params: {
        active: 1,
      },
      method: 'get',
    },
    null
  )

  const [areas, statusRequestAreas, fetchAreas] = useRequest(
    [],
    {
      url: 'areas',
      params: {
        active: 1,
      },
      method: 'get',
    },
    null
  )

  const [palavrasChave, , fetchPalavrasChave] = useRequest(
    [],
    {
      url: 'palavras_chave',
      params: {
        active: 1,
        input: palavrasChaveInput,
      },
      method: 'get',
    },
    null
  )

  useEffect(() => {
    if (!!dialogOpen && areas.length === 0 && categorias.length === 0) {
      fetchCategorias()
      fetchAreas()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen])

  useEffect(() => {
    if (palavrasChaveInput.length >= 3) {
      fetchPalavrasChave()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [palavrasChaveInput])

  useEffect(() => {
    if (selectedRow) {
      setFinishTicketDialogState({
        ...finishTicketDialogState,
        idsTickets: [selectedRow.id],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow])

  useEffect(() => {
    if (selectedRows) {
      setFinishTicketDialogState({
        ...finishTicketDialogState,
        idsTickets: selectedRows,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows])

  const handleClose = () => {
    setFinishTicketDialogState(initialFinishTicketState)
    setDialogOpen(false)
  }

  const handleAction = () => {
    finalizarTicket()
    handleClose()
  }

  const handlePesquisaAction = () => {
    finalizarTicketPesquisa()
    handleClose()
  }

  useEffect(() => {
    console.log({ finishTicketDialogState })
  }, [finishTicketDialogState])

  return (
    <DialogFinishTicketComponent
      finishTicketDialogState={finishTicketDialogState}
      setFinishTicketDialogState={setFinishTicketDialogState}
      palavrasChave={palavrasChave}
      palavrasChaveInput={palavrasChaveInput}
      setPalavrasChaveInput={setPalavrasChaveInput}
      handleAction={handleAction}
      handlePesquisaAction={handlePesquisaAction}
      handleClose={handleClose}
      dialogOpen={dialogOpen}
      actionTitle={'Finalizar Ticket'}
      rows={rows}
      selectedRow={selectedRow}
      selectedRows={selectedRows}
      categorias={categorias}
      areas={areas}
      statusRequest={statusRequestAreas.done && statusRequestCategorias.done}
    />
  )
}

export default DialogFinishTicket
