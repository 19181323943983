// Geral
export const initialTicketState = {
  dataContatoInicio: null,
  dataContatoFim: null,
  origem: '',
  categoria: '',
  praca: '',
  status: '',
  selectedTelespectador: '',
  selectedResponsavel: '',
  telespectador: '',
  responsavel: '',
  mensagemRecebida: '',
  mensagemEnviada: '',
}

export const initialTelespectadorState = {
  nome: '',
  email: '',
  telefone: '',
  id_regiao: '',
  regiao: {
    id: '',
    nome: '',
  },
  endereco: {
    fora_do_estado: false,
    cep: '',
    logradouro: '',
    numero: '',
    bairro: '',
    cidade: '',
    estado: '',
    id_ibge: '',
  },
}

export const initialResponsavelState = {
  idUsuario: '',
  idsTickets: [],
  idResponsavel: '',
  responsavel: '',
  informacoesInternas: '',
}

export const initialSuporteState = {
  idUsuario: '',
  idsTickets: [],
  suportes: [],
  informacoesInternas: '',
}

export const initialFinishTicketState = {
  checkboxesState: {
    comercial: '',
    engenharia: '',
    jornalismo: '',
    marketing: '',
    programacao: '',
  },
  idsTickets: [],
  tagsState: [],
  idCategoria: 1,
  observacoes: '',
  idUsuario: '',
  palavrasChave: [],
}

export const initialChatNotesState = {
  idComentario: '',
  noteInput: '',
  editingMessageBuffer: '',
  idTicket: '',
  idUsuario: '',
  idUsuarioComentario: '',
}

export const initialChatComponentState = {
  idTicket: '',
  idMessage: '',
  idUsuario: '',
  idTelespectador: '',
  idUsuarioMensagem: '',

  editingMessageBuffer: '',
  editingDateBuffer: null,

  userMessageInput: '',
  userDateInput: null,

  viewerMessageInput: '',
  viewerDateInput: null,
}

// Atendimentos
export const atendimentosSearchFormFields = {
  id: '',
  dataInicial: null,
  dataFinal: null,
  telespectador: '',
  usuario: '',
  praca: '',
  status: 'todos',
  origem: '',
  categoria: '',
  mensagem: '',
  palavrasChave: '',
  otherUsers: false,
}

// InboxCRC
export const inboxSearchFormFields = {
  dataInicial: null,
  dataFinal: null,
  telespectador: '',
  praca: '',
  origem: '',
}

// Telespectadores
export const telespectadoresSearchFormFields = {
  nome: '',
  email: '',
  telefone: '',
  praca: '',
}

// Usuários
export const usuariosSearchFormFields = {
  nome: '',
}

// Mensagens
export const mensagensSearchFormFields = {
  dataInicial: null,
  dataFinal: null,
  id: '',
  telespectador: '',
  praca: '',
  categoria: '',
  mensagem: '',
  palavrasChave: '',
}

export const cidadesSearchFormFields = {
  nome: '',
}

export const telespectadoresValidationState = {
  nome: {
    valid: false,
    text: '',
  },
  praca: {
    valid: false,
    text: '',
  },
  email: {
    valid: false,
    text: '',
  },
  telefone: {
    valid: false,
    text: '',
  },
  cep: {
    valid: false,
    text: '',
  },
  logradouro: {
    valid: false,
    text: '',
  },
  numero: {
    valid: false,
    text: '',
  },
  bairro: {
    valid: false,
    text: '',
  },
  cidade: {
    valid: false,
    text: '',
  },
  estado: {
    valid: false,
    text: '',
  },
}

// Geral
export const initialPagination = {
  pageSize: 10,
  currentPage: 0,
}

export const initialCidades = [
  { id: 'Abatiá', nome: 'Abatiá' },
  { id: 'Adrianópolis', nome: 'Adrianópolis' },
  { id: 'Agudos do Sul', nome: 'Agudos do Sul' },
  { id: 'Almirante Tamandaré', nome: 'Almirante Tamandaré' },
  { id: 'Altamira do Paraná', nome: 'Altamira do Paraná' },
  { id: 'Alto Paraná', nome: 'Alto Paraná' },
  { id: 'Alto Piquiri', nome: 'Alto Piquiri' },
  { id: 'Altônia', nome: 'Altônia' },
  { id: 'Alvorada do Sul', nome: 'Alvorada do Sul' },
  { id: 'Amaporã', nome: 'Amaporã' },
  { id: 'Ampére', nome: 'Ampére' },
  { id: 'Anahy', nome: 'Anahy' },
  { id: 'Andirá', nome: 'Andirá' },
  { id: 'Ângulo', nome: 'Ângulo' },
  { id: 'Antonina', nome: 'Antonina' },
  { id: 'Antônio Olinto', nome: 'Antônio Olinto' },
  { id: 'Apucarana', nome: 'Apucarana' },
  { id: 'Arapongas', nome: 'Arapongas' },
  { id: 'Arapoti', nome: 'Arapoti' },
  { id: 'Arapuã', nome: 'Arapuã' },
  { id: 'Araruna', nome: 'Araruna' },
  { id: 'Araucária', nome: 'Araucária' },
  { id: 'Ariranha do Ivaí', nome: 'Ariranha do Ivaí' },
  { id: 'Assaí', nome: 'Assaí' },
  { id: 'Assis Chateaubriand', nome: 'Assis Chateaubriand' },
  { id: 'Astorga', nome: 'Astorga' },
  { id: 'Atalaia', nome: 'Atalaia' },
  { id: 'Balsa Nova', nome: 'Balsa Nova' },
  { id: 'Bandeirantes', nome: 'Bandeirantes' },
  { id: 'Barbosa Ferraz', nome: 'Barbosa Ferraz' },
  { id: 'Barra do Jacaré', nome: 'Barra do Jacaré' },
  { id: 'Barracão', nome: 'Barracão' },
  { id: 'Bela Vista da Caroba', nome: 'Bela Vista da Caroba' },
  { id: 'Bela Vista do Paraíso', nome: 'Bela Vista do Paraíso' },
  { id: 'Bituruna', nome: 'Bituruna' },
  { id: 'Boa Esperança', nome: 'Boa Esperança' },
  { id: 'Boa Esperança do Iguaçu', nome: 'Boa Esperança do Iguaçu' },
  { id: 'Boa Ventura de São Roque', nome: 'Boa Ventura de São Roque' },
  { id: 'Boa Vista da Aparecida', nome: 'Boa Vista da Aparecida' },
  { id: 'Bocaiúva do Sul', nome: 'Bocaiúva do Sul' },
  { id: 'Bom Jesus do Sul', nome: 'Bom Jesus do Sul' },
  { id: 'Bom Sucesso', nome: 'Bom Sucesso' },
  { id: 'Bom Sucesso do Sul', nome: 'Bom Sucesso do Sul' },
  { id: 'Borrazópolis', nome: 'Borrazópolis' },
  { id: 'Braganey', nome: 'Braganey' },
  { id: 'Brasilândia do Sul', nome: 'Brasilândia do Sul' },
  { id: 'Cafeara', nome: 'Cafeara' },
  { id: 'Cafelândia', nome: 'Cafelândia' },
  { id: 'Cafezal do Sul', nome: 'Cafezal do Sul' },
  { id: 'Califórnia', nome: 'Califórnia' },
  { id: 'Cambará', nome: 'Cambará' },
  { id: 'Cambé', nome: 'Cambé' },
  { id: 'Cambira', nome: 'Cambira' },
  { id: 'Campina da Lagoa', nome: 'Campina da Lagoa' },
  { id: 'Campina do Simão', nome: 'Campina do Simão' },
  { id: 'Campina Grande do Sul', nome: 'Campina Grande do Sul' },
  { id: 'Campo Bonito', nome: 'Campo Bonito' },
  { id: 'Campo do Tenente', nome: 'Campo do Tenente' },
  { id: 'Campo Largo', nome: 'Campo Largo' },
  { id: 'Campo Magro', nome: 'Campo Magro' },
  { id: 'Campo Mourão', nome: 'Campo Mourão' },
  { id: 'Cândido de Abreu', nome: 'Cândido de Abreu' },
  { id: 'Candói', nome: 'Candói' },
  { id: 'Cantagalo', nome: 'Cantagalo' },
  { id: 'Capanema', nome: 'Capanema' },
  { id: 'Capitão Leônidas Marques', nome: 'Capitão Leônidas Marques' },
  { id: 'Carambeí', nome: 'Carambeí' },
  { id: 'Carlópolis', nome: 'Carlópolis' },
  { id: 'Cascavel', nome: 'Cascavel' },
  { id: 'Castro', nome: 'Castro' },
  { id: 'Catanduvas', nome: 'Catanduvas' },
  { id: 'Centenário do Sul', nome: 'Centenário do Sul' },
  { id: 'Cerro Azul', nome: 'Cerro Azul' },
  { id: 'Céu Azul', nome: 'Céu Azul' },
  { id: 'Chopinzinho', nome: 'Chopinzinho' },
  { id: 'Cianorte', nome: 'Cianorte' },
  { id: 'Cidade Gaúcha', nome: 'Cidade Gaúcha' },
  { id: 'Clevelândia', nome: 'Clevelândia' },
  { id: 'Colombo', nome: 'Colombo' },
  { id: 'Colorado', nome: 'Colorado' },
  { id: 'Congonhinhas', nome: 'Congonhinhas' },
  { id: 'Conselheiro Mairinck', nome: 'Conselheiro Mairinck' },
  { id: 'Contenda', nome: 'Contenda' },
  { id: 'Corbélia', nome: 'Corbélia' },
  { id: 'Cornélio Procópio', nome: 'Cornélio Procópio' },
  { id: 'Coronel Domingos Soares', nome: 'Coronel Domingos Soares' },
  { id: 'Coronel Vivida', nome: 'Coronel Vivida' },
  { id: 'Corumbataí do Sul', nome: 'Corumbataí do Sul' },
  { id: 'Cruz Machado', nome: 'Cruz Machado' },
  { id: 'Cruzeiro do Iguaçu', nome: 'Cruzeiro do Iguaçu' },
  { id: 'Cruzeiro do Oeste', nome: 'Cruzeiro do Oeste' },
  { id: 'Cruzeiro do Sul', nome: 'Cruzeiro do Sul' },
  { id: 'Cruzmaltina', nome: 'Cruzmaltina' },
  { id: 'Curitiba', nome: 'Curitiba' },
  { id: 'Curiúva', nome: 'Curiúva' },
  { id: "Diamante d'Oeste", nome: "Diamante d'Oeste" },
  { id: 'Diamante do Norte', nome: 'Diamante do Norte' },
  { id: 'Diamante do Sul', nome: 'Diamante do Sul' },
  { id: 'Dois Vizinhos', nome: 'Dois Vizinhos' },
  { id: 'Douradina', nome: 'Douradina' },
  { id: 'Doutor Camargo', nome: 'Doutor Camargo' },
  { id: 'Doutor Ulysses', nome: 'Doutor Ulysses' },
  { id: 'Enéas Marques', nome: 'Enéas Marques' },
  { id: 'Engenheiro Beltrão', nome: 'Engenheiro Beltrão' },
  { id: 'Entre Rios do Oeste', nome: 'Entre Rios do Oeste' },
  { id: 'Esperança Nova', nome: 'Esperança Nova' },
  { id: 'Espigão Alto do Iguaçu', nome: 'Espigão Alto do Iguaçu' },
  { id: 'Farol', nome: 'Farol' },
  { id: 'Faxinal', nome: 'Faxinal' },
  { id: 'Fazenda Rio Grande', nome: 'Fazenda Rio Grande' },
  { id: 'Fênix', nome: 'Fênix' },
  { id: 'Fernandes Pinheiro', nome: 'Fernandes Pinheiro' },
  { id: 'Figueira', nome: 'Figueira' },
  { id: 'Flor da Serra do Sul', nome: 'Flor da Serra do Sul' },
  { id: 'Floraí', nome: 'Floraí' },
  { id: 'Floresta', nome: 'Floresta' },
  { id: 'Florestópolis', nome: 'Florestópolis' },
  { id: 'Flórida', nome: 'Flórida' },
  { id: 'Formosa do Oeste', nome: 'Formosa do Oeste' },
  { id: 'Foz do Iguaçu', nome: 'Foz do Iguaçu' },
  { id: 'Foz do Jordão', nome: 'Foz do Jordão' },
  { id: 'Francisco Alves', nome: 'Francisco Alves' },
  { id: 'Francisco Beltrão', nome: 'Francisco Beltrão' },
  { id: 'General Carneiro', nome: 'General Carneiro' },
  { id: 'Godoy Moreira', nome: 'Godoy Moreira' },
  { id: 'Goioerê', nome: 'Goioerê' },
  { id: 'Goioxim', nome: 'Goioxim' },
  { id: 'Grandes Rios', nome: 'Grandes Rios' },
  { id: 'Guaíra', nome: 'Guaíra' },
  { id: 'Guairaçá', nome: 'Guairaçá' },
  { id: 'Guamiranga', nome: 'Guamiranga' },
  { id: 'Guapirama', nome: 'Guapirama' },
  { id: 'Guaporema', nome: 'Guaporema' },
  { id: 'Guaraci', nome: 'Guaraci' },
  { id: 'Guaraniaçu', nome: 'Guaraniaçu' },
  { id: 'Guarapuava', nome: 'Guarapuava' },
  { id: 'Guaraqueçaba', nome: 'Guaraqueçaba' },
  { id: 'Guaratuba', nome: 'Guaratuba' },
  { id: 'Honório Serpa', nome: 'Honório Serpa' },
  { id: 'Ibaiti', nome: 'Ibaiti' },
  { id: 'Ibema', nome: 'Ibema' },
  { id: 'Ibiporã', nome: 'Ibiporã' },
  { id: 'Icaraíma', nome: 'Icaraíma' },
  { id: 'Iguaraçu', nome: 'Iguaraçu' },
  { id: 'Iguatu', nome: 'Iguatu' },
  { id: 'Imbaú', nome: 'Imbaú' },
  { id: 'Imbituva', nome: 'Imbituva' },
  { id: 'Inácio Martins', nome: 'Inácio Martins' },
  { id: 'Inajá', nome: 'Inajá' },
  { id: 'Indianópolis', nome: 'Indianópolis' },
  { id: 'Ipiranga', nome: 'Ipiranga' },
  { id: 'Iporã', nome: 'Iporã' },
  { id: 'Iracema do Oeste', nome: 'Iracema do Oeste' },
  { id: 'Irati', nome: 'Irati' },
  { id: 'Iretama', nome: 'Iretama' },
  { id: 'Itaguajé', nome: 'Itaguajé' },
  { id: 'Itaipulândia', nome: 'Itaipulândia' },
  { id: 'Itambaracá', nome: 'Itambaracá' },
  { id: 'Itambé', nome: 'Itambé' },
  { id: "Itapejara d'Oeste", nome: "Itapejara d'Oeste" },
  { id: 'Itaperuçu', nome: 'Itaperuçu' },
  { id: 'Itaúna do Sul', nome: 'Itaúna do Sul' },
  { id: 'Ivaí', nome: 'Ivaí' },
  { id: 'Ivaiporã', nome: 'Ivaiporã' },
  { id: 'Ivaté', nome: 'Ivaté' },
  { id: 'Ivatuba', nome: 'Ivatuba' },
  { id: 'Jaboti', nome: 'Jaboti' },
  { id: 'Jacarezinho', nome: 'Jacarezinho' },
  { id: 'Jaguapitã', nome: 'Jaguapitã' },
  { id: 'Jaguariaíva', nome: 'Jaguariaíva' },
  { id: 'Jandaia do Sul', nome: 'Jandaia do Sul' },
  { id: 'Janiópolis', nome: 'Janiópolis' },
  { id: 'Japira', nome: 'Japira' },
  { id: 'Japurá', nome: 'Japurá' },
  { id: 'Jardim Alegre', nome: 'Jardim Alegre' },
  { id: 'Jardim Olinda', nome: 'Jardim Olinda' },
  { id: 'Jataizinho', nome: 'Jataizinho' },
  { id: 'Jesuítas', nome: 'Jesuítas' },
  { id: 'Joaquim Távora', nome: 'Joaquim Távora' },
  { id: 'Jundiaí do Sul', nome: 'Jundiaí do Sul' },
  { id: 'Juranda', nome: 'Juranda' },
  { id: 'Jussara', nome: 'Jussara' },
  { id: 'Kaloré', nome: 'Kaloré' },
  { id: 'Lapa', nome: 'Lapa' },
  { id: 'Laranjal', nome: 'Laranjal' },
  { id: 'Laranjeiras do Sul', nome: 'Laranjeiras do Sul' },
  { id: 'Leópolis', nome: 'Leópolis' },
  { id: 'Lidianópolis', nome: 'Lidianópolis' },
  { id: 'Lindoeste', nome: 'Lindoeste' },
  { id: 'Loanda', nome: 'Loanda' },
  { id: 'Lobato', nome: 'Lobato' },
  { id: 'Londrina', nome: 'Londrina' },
  { id: 'Luiziana', nome: 'Luiziana' },
  { id: 'Lunardelli', nome: 'Lunardelli' },
  { id: 'Lupionópolis', nome: 'Lupionópolis' },
  { id: 'Mallet', nome: 'Mallet' },
  { id: 'Mamborê', nome: 'Mamborê' },
  { id: 'Mandaguaçu', nome: 'Mandaguaçu' },
  { id: 'Mandaguari', nome: 'Mandaguari' },
  { id: 'Mandirituba', nome: 'Mandirituba' },
  { id: 'Manfrinópolis', nome: 'Manfrinópolis' },
  { id: 'Mangueirinha', nome: 'Mangueirinha' },
  { id: 'Manoel Ribas', nome: 'Manoel Ribas' },
  { id: 'Marechal Cândido Rondon', nome: 'Marechal Cândido Rondon' },
  { id: 'Maria Helena', nome: 'Maria Helena' },
  { id: 'Marialva', nome: 'Marialva' },
  { id: 'Marilândia do Sul', nome: 'Marilândia do Sul' },
  { id: 'Marilena', nome: 'Marilena' },
  { id: 'Mariluz', nome: 'Mariluz' },
  { id: 'Maringá', nome: 'Maringá' },
  { id: 'Mariópolis', nome: 'Mariópolis' },
  { id: 'Maripá', nome: 'Maripá' },
  { id: 'Marmeleiro', nome: 'Marmeleiro' },
  { id: 'Marquinho', nome: 'Marquinho' },
  { id: 'Marumbi', nome: 'Marumbi' },
  { id: 'Matelândia', nome: 'Matelândia' },
  { id: 'Matinhos', nome: 'Matinhos' },
  { id: 'Mato Rico', nome: 'Mato Rico' },
  { id: 'Mauá da Serra', nome: 'Mauá da Serra' },
  { id: 'Medianeira', nome: 'Medianeira' },
  { id: 'Mercedes', nome: 'Mercedes' },
  { id: 'Mirador', nome: 'Mirador' },
  { id: 'Miraselva', nome: 'Miraselva' },
  { id: 'Missal', nome: 'Missal' },
  { id: 'Moreira Sales', nome: 'Moreira Sales' },
  { id: 'Morretes', nome: 'Morretes' },
  { id: 'Munhoz de Melo', nome: 'Munhoz de Melo' },
  { id: 'Nossa Senhora das Graças', nome: 'Nossa Senhora das Graças' },
  { id: 'Nova Aliança do Ivaí', nome: 'Nova Aliança do Ivaí' },
  { id: 'Nova América da Colina', nome: 'Nova América da Colina' },
  { id: 'Nova Aurora', nome: 'Nova Aurora' },
  { id: 'Nova Cantu', nome: 'Nova Cantu' },
  { id: 'Nova Esperança', nome: 'Nova Esperança' },
  { id: 'Nova Esperança do Sudoeste', nome: 'Nova Esperança do Sudoeste' },
  { id: 'Nova Fátima', nome: 'Nova Fátima' },
  { id: 'Nova Laranjeiras', nome: 'Nova Laranjeiras' },
  { id: 'Nova Londrina', nome: 'Nova Londrina' },
  { id: 'Nova Olímpia', nome: 'Nova Olímpia' },
  { id: 'Nova Prata do Iguaçu', nome: 'Nova Prata do Iguaçu' },
  { id: 'Nova Santa Bárbara', nome: 'Nova Santa Bárbara' },
  { id: 'Nova Santa Rosa', nome: 'Nova Santa Rosa' },
  { id: 'Nova Tebas', nome: 'Nova Tebas' },
  { id: 'Novo Itacolomi', nome: 'Novo Itacolomi' },
  { id: 'Ortigueira', nome: 'Ortigueira' },
  { id: 'Ourizona', nome: 'Ourizona' },
  { id: 'Ouro Verde do Oeste', nome: 'Ouro Verde do Oeste' },
  { id: 'Paiçandu', nome: 'Paiçandu' },
  { id: 'Palmas', nome: 'Palmas' },
  { id: 'Palmeira', nome: 'Palmeira' },
  { id: 'Palmital', nome: 'Palmital' },
  { id: 'Palotina', nome: 'Palotina' },
  { id: 'Paraíso do Norte', nome: 'Paraíso do Norte' },
  { id: 'Paranacity', nome: 'Paranacity' },
  { id: 'Paranaguá', nome: 'Paranaguá' },
  { id: 'Paranapoema', nome: 'Paranapoema' },
  { id: 'Paranavaí', nome: 'Paranavaí' },
  { id: 'Pato Bragado', nome: 'Pato Bragado' },
  { id: 'Pato Branco', nome: 'Pato Branco' },
  { id: 'Paula Freitas', nome: 'Paula Freitas' },
  { id: 'Paulo Frontin', nome: 'Paulo Frontin' },
  { id: 'Peabiru', nome: 'Peabiru' },
  { id: 'Perobal', nome: 'Perobal' },
  { id: 'Pérola', nome: 'Pérola' },
  { id: "Pérola d'Oeste", nome: "Pérola d'Oeste" },
  { id: 'Piên', nome: 'Piên' },
  { id: 'Pinhais', nome: 'Pinhais' },
  { id: 'Pinhal de São Bento', nome: 'Pinhal de São Bento' },
  { id: 'Pinhalão', nome: 'Pinhalão' },
  { id: 'Pinhão', nome: 'Pinhão' },
  { id: 'Piraí do Sul', nome: 'Piraí do Sul' },
  { id: 'Piraquara', nome: 'Piraquara' },
  { id: 'Pitanga', nome: 'Pitanga' },
  { id: 'Pitangueiras', nome: 'Pitangueiras' },
  { id: 'Planaltina do Paraná', nome: 'Planaltina do Paraná' },
  { id: 'Planalto', nome: 'Planalto' },
  { id: 'Ponta Grossa', nome: 'Ponta Grossa' },
  { id: 'Pontal do Paraná', nome: 'Pontal do Paraná' },
  { id: 'Porecatu', nome: 'Porecatu' },
  { id: 'Porto Amazonas', nome: 'Porto Amazonas' },
  { id: 'Porto Barreiro', nome: 'Porto Barreiro' },
  { id: 'Porto Rico', nome: 'Porto Rico' },
  { id: 'Porto Vitória', nome: 'Porto Vitória' },
  { id: 'Prado Ferreira', nome: 'Prado Ferreira' },
  { id: 'Pranchita', nome: 'Pranchita' },
  { id: 'Presidente Castelo Branco', nome: 'Presidente Castelo Branco' },
  { id: 'Primeiro de Maio', nome: 'Primeiro de Maio' },
  { id: 'Prudentópolis', nome: 'Prudentópolis' },
  { id: 'Quarto Centenário', nome: 'Quarto Centenário' },
  { id: 'Quatiguá', nome: 'Quatiguá' },
  { id: 'Quatro Barras', nome: 'Quatro Barras' },
  { id: 'Quatro Pontes', nome: 'Quatro Pontes' },
  { id: 'Quedas do Iguaçu', nome: 'Quedas do Iguaçu' },
  { id: 'Querência do Norte', nome: 'Querência do Norte' },
  { id: 'Quinta do Sol', nome: 'Quinta do Sol' },
  { id: 'Quitandinha', nome: 'Quitandinha' },
  { id: 'Ramilândia', nome: 'Ramilândia' },
  { id: 'Rancho Alegre', nome: 'Rancho Alegre' },
  { id: "Rancho Alegre d'Oeste", nome: "Rancho Alegre d'Oeste" },
  { id: 'Realeza', nome: 'Realeza' },
  { id: 'Rebouças', nome: 'Rebouças' },
  { id: 'Renascença', nome: 'Renascença' },
  { id: 'Reserva', nome: 'Reserva' },
  { id: 'Reserva do Iguaçu', nome: 'Reserva do Iguaçu' },
  { id: 'Ribeirão Claro', nome: 'Ribeirão Claro' },
  { id: 'Ribeirão do Pinhal', nome: 'Ribeirão do Pinhal' },
  { id: 'Rio Azul', nome: 'Rio Azul' },
  { id: 'Rio Bom', nome: 'Rio Bom' },
  { id: 'Rio Bonito do Iguaçu', nome: 'Rio Bonito do Iguaçu' },
  { id: 'Rio Branco do Ivaí', nome: 'Rio Branco do Ivaí' },
  { id: 'Rio Branco do Sul', nome: 'Rio Branco do Sul' },
  { id: 'Rio Negro', nome: 'Rio Negro' },
  { id: 'Rolândia', nome: 'Rolândia' },
  { id: 'Roncador', nome: 'Roncador' },
  { id: 'Rondon', nome: 'Rondon' },
  { id: 'Rosário do Ivaí', nome: 'Rosário do Ivaí' },
  { id: 'Sabáudia', nome: 'Sabáudia' },
  { id: 'Salgado Filho', nome: 'Salgado Filho' },
  { id: 'Salto do Itararé', nome: 'Salto do Itararé' },
  { id: 'Salto do Lontra', nome: 'Salto do Lontra' },
  { id: 'Santa Amélia', nome: 'Santa Amélia' },
  { id: 'Santa Cecília do Pavão', nome: 'Santa Cecília do Pavão' },
  { id: 'Santa Cruz Monte Castelo', nome: 'Santa Cruz Monte Castelo' },
  { id: 'Santa Fé', nome: 'Santa Fé' },
  { id: 'Santa Helena', nome: 'Santa Helena' },
  { id: 'Santa Inês', nome: 'Santa Inês' },
  { id: 'Santa Isabel do Ivaí', nome: 'Santa Isabel do Ivaí' },
  { id: 'Santa Izabel do Oeste', nome: 'Santa Izabel do Oeste' },
  { id: 'Santa Lúcia', nome: 'Santa Lúcia' },
  { id: 'Santa Maria do Oeste', nome: 'Santa Maria do Oeste' },
  { id: 'Santa Mariana', nome: 'Santa Mariana' },
  { id: 'Santa Mônica', nome: 'Santa Mônica' },
  { id: 'Santa Tereza do Oeste', nome: 'Santa Tereza do Oeste' },
  { id: 'Santa Terezinha de Itaipu', nome: 'Santa Terezinha de Itaipu' },
  { id: 'Santana do Itararé', nome: 'Santana do Itararé' },
  { id: 'Santo Antônio da Platina', nome: 'Santo Antônio da Platina' },
  { id: 'Santo Antônio do Caiuá', nome: 'Santo Antônio do Caiuá' },
  { id: 'Santo Antônio do Paraíso', nome: 'Santo Antônio do Paraíso' },
  { id: 'Santo Antônio do Sudoeste', nome: 'Santo Antônio do Sudoeste' },
  { id: 'Santo Inácio', nome: 'Santo Inácio' },
  { id: 'São Carlos do Ivaí', nome: 'São Carlos do Ivaí' },
  { id: 'São Jerônimo da Serra', nome: 'São Jerônimo da Serra' },
  { id: 'São João', nome: 'São João' },
  { id: 'São João do Caiuá', nome: 'São João do Caiuá' },
  { id: 'São João do Ivaí', nome: 'São João do Ivaí' },
  { id: 'São João do Triunfo', nome: 'São João do Triunfo' },
  { id: "São Jorge d'Oeste", nome: "São Jorge d'Oeste" },
  { id: 'São Jorge do Ivaí', nome: 'São Jorge do Ivaí' },
  { id: 'São Jorge do Patrocínio', nome: 'São Jorge do Patrocínio' },
  { id: 'São José da Boa Vista', nome: 'São José da Boa Vista' },
  { id: 'São José das Palmeiras', nome: 'São José das Palmeiras' },
  { id: 'São José dos Pinhais', nome: 'São José dos Pinhais' },
  { id: 'São Manoel do Paraná', nome: 'São Manoel do Paraná' },
  { id: 'São Mateus do Sul', nome: 'São Mateus do Sul' },
  { id: 'São Miguel do Iguaçu', nome: 'São Miguel do Iguaçu' },
  { id: 'São Pedro do Iguaçu', nome: 'São Pedro do Iguaçu' },
  { id: 'São Pedro do Ivaí', nome: 'São Pedro do Ivaí' },
  { id: 'São Pedro do Paraná', nome: 'São Pedro do Paraná' },
  { id: 'São Sebastião da Amoreira', nome: 'São Sebastião da Amoreira' },
  { id: 'São Tomé', nome: 'São Tomé' },
  { id: 'Sapopema', nome: 'Sapopema' },
  { id: 'Sarandi', nome: 'Sarandi' },
  { id: 'Saudade do Iguaçu', nome: 'Saudade do Iguaçu' },
  { id: 'Sengés', nome: 'Sengés' },
  { id: 'Serranópolis do Iguaçu', nome: 'Serranópolis do Iguaçu' },
  { id: 'Sertaneja', nome: 'Sertaneja' },
  { id: 'Sertanópolis', nome: 'Sertanópolis' },
  { id: 'Siqueira Campos', nome: 'Siqueira Campos' },
  { id: 'Sulina', nome: 'Sulina' },
  { id: 'Tamarana', nome: 'Tamarana' },
  { id: 'Tamboara', nome: 'Tamboara' },
  { id: 'Tapejara', nome: 'Tapejara' },
  { id: 'Tapira', nome: 'Tapira' },
  { id: 'Teixeira Soares', nome: 'Teixeira Soares' },
  { id: 'Telêmaco Borba', nome: 'Telêmaco Borba' },
  { id: 'Terra Boa', nome: 'Terra Boa' },
  { id: 'Terra Rica', nome: 'Terra Rica' },
  { id: 'Terra Roxa', nome: 'Terra Roxa' },
  { id: 'Tibagi', nome: 'Tibagi' },
  { id: 'Tijucas do Sul', nome: 'Tijucas do Sul' },
  { id: 'Toledo', nome: 'Toledo' },
  { id: 'Tomazina', nome: 'Tomazina' },
  { id: 'Três Barras do Paraná', nome: 'Três Barras do Paraná' },
  { id: 'Tunas do Paraná', nome: 'Tunas do Paraná' },
  { id: 'Tuneiras do Oeste', nome: 'Tuneiras do Oeste' },
  { id: 'Tupãssi', nome: 'Tupãssi' },
  { id: 'Turvo', nome: 'Turvo' },
  { id: 'Ubiratã', nome: 'Ubiratã' },
  { id: 'Umuarama', nome: 'Umuarama' },
  { id: 'União da Vitória', nome: 'União da Vitória' },
  { id: 'Uniflor', nome: 'Uniflor' },
  { id: 'Uraí', nome: 'Uraí' },
  { id: 'Ventania', nome: 'Ventania' },
  { id: 'Vera Cruz do Oeste', nome: 'Vera Cruz do Oeste' },
  { id: 'Verê', nome: 'Verê' },
  { id: 'Vila Alta', nome: 'Vila Alta' },
  { id: 'Virmond', nome: 'Virmond' },
  { id: 'Vitorino', nome: 'Vitorino' },
  { id: 'Wenceslau Braz', nome: 'Wenceslau Braz' },
  { id: 'Xambrê', nome: 'Xambrê' },
]
