export const pracas = [
  {
    value: '',
    label: '',
  },
  {
    value: 'Cascavel',
    label: 'Cascavel',
  },
  {
    value: 'Curitiba',
    label: 'Curitiba',
  },
  {
    value: 'Maringá',
    label: 'Maringá',
  },
  {
    value: 'Londrina',
    label: 'Londrina',
  },
  {
    value: 'Ponta Grossa',
    label: 'Ponta Grossa',
  },

  {
    value: 'Foz do Iguaçu',
    label: 'Foz do Iguaçu',
  },
];

export const statusesCreate = [
  {
    id: 1,
    nome: 'Aberto',
  },
  {
    id: 2,
    nome: 'Aguardando Atendimento',
  },
  {
    id: 4,
    nome: 'Aguardando Resposta',
  },
  {
    id: 5,
    nome: 'Aguardando Finalização',
  },
  {
    id: 3,
    nome: 'Fechado',
  },
];

export const statusesEdit = [
  {
    id: 2,
    nome: 'Aguardando Atendimento',
  },
  {
    id: 4,
    nome: 'Aguardando Resposta',
  },
  {
    id: 5,
    nome: 'Aguardando Finalização',
  },
];

export const atendimentosStatuses = [
  {
    id: 'todos',
    nome: 'Todos',
  },
  {
    id: 6,
    nome: 'Em Atendimento',
  },
  {
    id: 2,
    nome: 'Aguardando Atendimento',
  },
  {
    id: 4,
    nome: 'Aguardando Resposta',
  },
  {
    id: 5,
    nome: 'Aguardando Finalização',
  },
  {
    id: 3,
    nome: 'Fechado',
  },
];

export const origens = [
  {
    value: '',
    label: '',
  },
  {
    value: 'App',
    label: 'App',
  },
  {
    value: 'E-mail',
    label: 'E-mail',
  },
  {
    value: 'Telefone',
    label: 'Telefone',
  },
  {
    value: 'Facebook',
    label: 'Facebook',
  },
  {
    value: 'Reclame Aqui',
    label: 'Reclame Aqui',
  },
  {
    value: 'Carta',
    label: 'Carta',
  },
];

export const categorias = [
  {
    value: '',
    label: '',
  },
  {
    value: 'Reclamação',
    label: 'Reclamação',
  },
  {
    value: 'Dúvida',
    label: 'Dúvida',
  },
  {
    value: 'Sugestão',
    label: 'Sugestão',
  },
]

export const suggestions = [
  { label: 'Afghanistan' },
  { label: 'Aland Islands' },
  { label: 'Albania' },
  { label: 'Algeria' },
  { label: 'American Samoa' },
  { label: 'Andorra' },
  { label: 'Angola' },
  { label: 'Anguilla' },
  { label: 'Antarctica' },
  { label: 'Antigua and Barbuda' },
  { label: 'Argentina' },
  { label: 'Armenia' },
  { label: 'Aruba' },
  { label: 'Australia' },
  { label: 'Austria' },
  { label: 'Azerbaijan' },
  { label: 'Bahamas' },
  { label: 'Bahrain' },
  { label: 'Bangladesh' },
  { label: 'Barbados' },
  { label: 'Belarus' },
  { label: 'Belgium' },
  { label: 'Belize' },
  { label: 'Benin' },
  { label: 'Bermuda' },
  { label: 'Bhutan' },
  { label: 'Bolivia, Plurinational State of' },
  { label: 'Bonaire, Sint Eustatius and Saba' },
  { label: 'Bosnia and Herzegovina' },
  { label: 'Botswana' },
  { label: 'Bouvet Island' },
  { label: 'Brazil' },
  { label: 'British Indian Ocean Territory' },
  { label: 'Brunei Darussalam' },
];

export const estados = [
  { label: "Acre", value: "AC"},
  { label: "Alagoas", value: "AL"},
  { label: "Amapá", value: "AP"},
  { label: "Amazonas", value: "AM"},
  { label: "Bahia", value: "BA"},
  { label: "Ceará", value: "CE"},
  { label: "Distrito Federal", value: "DF"},
  { label: "Espírito Santo", value: "ES"},
  { label: "Goiás", value: "GO"},
  { label: "Maranhão", value: "MA"},
  { label: "Mato Grosso", value: "MT"},
  { label: "Mato Grosso do Sul", value: "MS"},
  { label: "Minas Gerais", value: "MG"},
  { label: "Pará", value: "PA"},
  { label: "Paraíba", value: "PB"},
  { label: "Paraná", value: "PR"},
  { label: "Pernambuco", value: "PE"},
  { label: "Piauí", value: "PI"},
  { label: "Rio de Janeiro", value: "RJ"},
  { label: "Rio Grande do Norte", value: "RN"},
  { label: "Rio Grande do Sul", value: "RS"},
  { label: "Rondônia", value: "RO"},
  { label: "Roraima", value: "RR"},
  { label: "Santa Catarina", value: "SC"},
  { label: "São Paulo", value: "SP"},
  { label: "Sergipe", value: "SE"},
  { label: "Tocantins", value: "TO"}
]