import React, { useState, useEffect } from 'react'
import { ApplicationContext } from './ContextProvider'
import ApplicationStyle from './ApplicationStyle'
import SnackbarProvider from './SnackbarProvider'
import { redirectToAuthentication, isAuthenticated, getLocalStorageToken } from './helpers/authenticator'
import { authStatuses } from './constants/authentication'

import firebaseApp from './constants/firebase'

const useAuth = () => {
  const [authentication, setAuthentication] = useState('')
  const [authenticationFb, setAuthenticationFb] = useState('')
  const [authenticationAuth, setAuthenticationAuth] = useState('')
  const [user, setUser] = useState('')

  useEffect(() => {
    if (authenticationAuth === authStatuses.UNAUTHENTICATED || authenticationAuth === authStatuses.EXPIRED) {
      redirectToAuthentication()
    }

    if (authenticationFb === authStatuses.UNAUTHENTICATED) {
      redirectToAuthentication()
    }

    if (authenticationAuth === authStatuses.AUTHENTICATED && authenticationFb === authStatuses.AUTHENTICATED) {
      setAuthentication(authStatuses.AUTHENTICATED)
      setUser(JSON.parse(getLocalStorageToken()).user)
    }


  }, [authenticationFb, authenticationAuth])

  useEffect(() => {
    setAuthenticationAuth(isAuthenticated())

    firebaseApp.auth().onAuthStateChanged(function(user) {
      if (user) {
        setAuthenticationFb(authStatuses.AUTHENTICATED)
      } else {
        setAuthenticationFb(authStatuses.UNAUTHENTICATED)
      }
    })

  }, [])

  return [authentication, user, setUser]
}

const ApplicationContainer = () => {
  const [authentication, user, setUser] = useAuth()
  const [ticketCreated, setTicketCreated] = useState(false)

  const applicationContextValue = { 
    user,
    setUser,
    authentication,
    ticketCreated,
    setTicketCreated
  }

  return (
    <ApplicationContext.Provider value={applicationContextValue}>
      <SnackbarProvider>
        <ApplicationStyle />
      </SnackbarProvider>
    </ApplicationContext.Provider>
  )
}

export default ApplicationContainer
