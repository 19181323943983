import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { KeyboardDateTimePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import { styles } from '../styles/js/datePickerComponent'
import 'moment/locale/pt-br'

const DatePickerComponent = (props) => {
  const { 
    classes,
    value,
    withTime,
    id,
    label,
    disablePast,
    disableFuture,
    onChange,
    fullWidth,
    required
  } = props

  const renderComponent = (WrappedComponent) => {
    return (
      <MuiPickersUtilsProvider locale={'pt-br'} moment={moment} utils={MomentUtils}>
        <WrappedComponent
          id={id}
          label={label}
          className={fullWidth ? classes.textFieldFullWidth : classes.textField}
          value={value}
          onChange={
            date => onChange(date)
          }
          disableFuture={disableFuture}
          disablePast={disablePast}
          placeholder={withTime ? '01/01/1990 12:00' : '01/01/1990'}
          format={withTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY'}
          clearable
          clearLabel='Limpar'
          cancelLabel='Cancelar'
          autoOk
          invalidDateMessage='Formato inválido'
          minDateMessage='Data deve ser maior que a data mínima'
          maxDateMessage='Data não deve ultrapassar a data máxima'
          ampm={false}
          margin='normal'
          required={required}
        />
      </MuiPickersUtilsProvider>
    )
  }

  return (
    <div>
      {withTime ? (
        renderComponent(KeyboardDateTimePicker)
      ) : (
        renderComponent(KeyboardDatePicker)
      )}
    </div>
  )
}


export default withStyles(styles)(DatePickerComponent)

// const [date, setDate] = useState(null)
// <DatePickerComponent
//   onChange={value => setDate(value)}
//   value={date}
//   id="editingDateBuffer"
//   label="Data de Contato"
//   disablePast={false}
//   disableFuture={false}
//   withTime
// />