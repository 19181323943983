import React, { useState, useEffect, useContext, Fragment } from 'react'
import CheckIcon from '@material-ui/icons/Check'
import EditIcon from '@material-ui/icons/Edit'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import EmojiEmotionsIcon from '@material-ui/icons/Face'
import ListIcon from '@material-ui/icons/List'
import SendIcon from '@material-ui/icons/Send'
import { withStyles } from '@material-ui/core/styles'
import DatePickerComponent from '../DatePickerComponent.jsx'
import moment from 'moment'
import 'moment/locale/pt-br'
import { styles } from '../../styles/js/chatComponent'
import { ApplicationContext } from '../../ContextProvider.jsx'
import DialogChatSuggestions from '../../containers/DialogChatSuggestions'

import * as usuarios from '../../constants/usuarios'

import { READ_ONLY_MODE } from '../../constants/api.js'

moment.locale('pt-BR')

let chat = []

const ACTIONS = {
  ['PESQUISA']: '* Link de pesquisa de atendimento enviado! *'
}

const Message = ({ balloon, classes }) => {
  return (
    <Typography
      paragraph
      className={
        balloon.isViewer
          ? classes.chatGuestBalloonText
          : classes.chatUserBalloonText
      }
      style={{ whiteSpace: 'pre-wrap', ...(!!balloon.action ? { fontWeight: 500, fontStyle: 'italic' } : {}) }}
    >
      { balloon.action === 'PESQUISA' ? ACTIONS[balloon.action] : balloon.message}
    </Typography>
  )
}

const Chat = props => {
  const [chatState, setChatState] = useState(chat)
  const [chatLength, setChatLength] = useState(chat.length)
  const [dialogChatSuggestionsOpen, setDialogChatSuggestionsOpen] = useState(
    false
  )

  const {
    classes,
    isClosed,
    ticket,
    statusRequestTicket,
    chatComponentState,
    setChatComponentState,
    criarMensagemUsuario,
    criarMensagemTelespectador,
    updateMensagem,
  } = props

  const {
    editingMessageBuffer,
    editingDateBuffer,
    userMessageInput,
    viewerMessageInput,
    viewerDateInput,
  } = chatComponentState

  const { user } = useContext(ApplicationContext)

  useEffect(() => {
    scrollToBottom()
    chat = []
    setChatComponentState({
      ...chatComponentState,
      idTicket: props.ticket.id,
      idUsuario: user.id,
      idTelespectador: ticket.telespectador.id,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (statusRequestTicket.done) {
      chat = []
      ticket.mensagens.forEach(mensagem => {
        chat.push({
          id: mensagem.id,
          date: mensagem.data_criacao,
          message: mensagem.mensagem,
          action: mensagem.action,
          isViewer: mensagem.tipo ? true : false,
          editingActive: false,
          author: mensagem.tipo
            ? mensagem.telespectador?.nome
            : mensagem.usuario?.nome,
          idAuthor: mensagem.usuario ? mensagem.usuario.id : '',
          lastEditionBy: mensagem.usuarioUltimaModificacao
            ? mensagem.usuarioUltimaModificacao.nome
            : '',
          lastEditionAt: mensagem.updatedAt,
        })
      })
    }

    setChatState(chat)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket])

  useEffect(() => {
    if (chatState.length !== chatLength) {
      setChatLength(chatState.length)
      scrollToBottom()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatState])

  const scrollToBottom = () => {
    if (statusRequestTicket.done) {
      let chatBody = document.getElementById('chatBody')
      chatBody.scrollTop = chatBody.scrollHeight
    }
  }

  const handleEditClick = balloon => {
    if (balloon.editingActive) {
      updateMensagem()
      chat.forEach(balloon => {
        balloon.editingActive = false
      })
    } else {
      setChatComponentState({
        ...chatComponentState,
        editingMessageBuffer: balloon.message,
        editingDateBuffer: balloon.isViewer ? balloon.date : null,
        idMessage: balloon.id,
        idUsuarioMensagem: balloon.idAuthor,
      })

      chat.forEach(balloon => {
        balloon.editingActive = false
      })
      chat.find(x => x.id === balloon.id).editingActive = true
    }
    setChatState(chat)
  }

  const sendMessage = () => {
    setChatState(chat)
    criarMensagemUsuario()
    setChatComponentState({ ...chatComponentState, userMessageInput: '' })
    scrollToBottom()
  }

  const newViewerMessage = () => {
    if (viewerMessageInput && viewerDateInput) {
      setChatState(chat)
      criarMensagemTelespectador()
      setChatComponentState({
        ...chatComponentState,
        viewerMessageInput: '',
        viewerDateInput: null,
      })
      scrollToBottom()
    }
  }

  const newUserMessage = () => {
    if (userMessageInput) {
      setChatState(chat)
      criarMensagemUsuario()
      setChatComponentState({
        ...chatComponentState,
        userMessageInput: '',
        userDateInput: null,
      })
      scrollToBottom()
    }
  }

  const renderCidade = ticket => {
    if (ticket.telespectador.endereco && ticket.telespectador.endereco.cidade) {
      return (
        <span style={{ fontWeight: 300, marginLeft: 8 }}>
          [{ticket.telespectador.endereco.cidade.nome}]
        </span>
      )
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.chatPaper} elevation={0}>
        <Paper className={classes.chatHeader}>
          <Typography className={classes.chatHeaderTitle} variant='h6'>
            Telespectador: {ticket.telespectador.nome}
            {renderCidade(ticket)}
          </Typography>
          {ticket.responsavel && (
            <Typography
              className={classes.chatHeaderSubtitle}
              variant='subtitle2'
              gutterBottom
            >
              Responsável: {ticket.responsavel.nome}
            </Typography>
          )}
        </Paper>

        {!statusRequestTicket.done && <p></p>}
        <div
          id='chatBody'
          className={classes.chatBody}
          style={{ height: props.fromApp ? 'calc(100% - 200px)' : '' }}
        >
          {chatState.map(balloon => (
            <div
              key={balloon.id}
              className={
                balloon.isViewer
                  ? classes.chatGuestBalloon
                  : classes.chatUserBalloon
              }
            >
              {!balloon.editingActive && (
                <Typography paragraph className={classes.chatBalloonTime}>
                  {balloon.author} | {moment(balloon.date).calendar()}
                </Typography>
              )}
              {balloon.editingActive && balloon.isViewer && (
                <DatePickerComponent
                  onChange={value =>
                    value !== null
                      ? setChatComponentState({
                        ...chatComponentState,
                        editingDateBuffer: value,
                      })
                      : ''
                  }
                  value={editingDateBuffer}
                  id='editingDateBuffer'
                  label='Data de Contato'
                  disablePast={false}
                  disableFuture={false}
                  withTime
                  fullWidth
                />
              )}

              {!balloon.editingActive && (
                <Message balloon={balloon} classes={classes} />
                // <Typography
                //   paragraph
                //   className={
                //     balloon.isViewer
                //       ? classes.chatGuestBalloonText
                //       : classes.chatUserBalloonText
                //   }
                //   style={{ whiteSpace: 'pre-wrap' }}
                // >
                //   {balloon.message}
                // </Typography>
              )}
              {balloon.editingActive && (
                <TextField
                  id={'editingMessage' + balloon.id}
                  fullWidth
                  multiline
                  rows='5'
                  value={editingMessageBuffer}
                  onChange={event =>
                    setChatComponentState({
                      ...chatComponentState,
                      editingMessageBuffer: event.target.value,
                    })
                  }
                />
              )}
              {ticket.responsavel &&
                !props.fromApp &&
                !props.isClosed &&
                user.usuario_crc === usuarios.CRC &&
                (ticket.responsavel
                  ? ticket.responsavel.id === user.id
                  : false) && (
                  <IconButton
                    onClick={() => handleEditClick(balloon)}
                    className={
                      balloon.isViewer
                        ? classes.chatGuestBalloonEditIcon
                        : classes.chatUserBalloonEditIcon
                    }
                  >
                    {balloon.editingActive ? <CheckIcon /> : <EditIcon />}
                  </IconButton>
                )}
              {balloon.lastEditionBy && !balloon.editingActive && (
                <span className={classes.chatBalloonAudit}>
                  Editado por {balloon.lastEditionBy} |{' '}
                  {moment(balloon.lastEditionAt).calendar()}
                </span>
              )}
            </div>
          ))}

          {ticket.responsavel &&
            !props.fromApp &&
            !isClosed &&
            user.usuario_crc === usuarios.CRC &&
            !READ_ONLY_MODE &&
            (ticket.responsavel
              ? ticket.responsavel.id === user.id
              : false) && (
              <div className={classes.inputSection}>
                <br />
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <div
                      key={'newViewerMessage'}
                      className={classes.newViewerMessage}
                    >
                      <Typography
                        paragraph
                        style={{ marginBottom: 0, color: '#555' }}
                      >
                        Envie como {ticket.telespectador.nome}
                      </Typography>

                      <DatePickerComponent
                        onChange={value =>
                          value !== null
                            ? setChatComponentState({
                              ...chatComponentState,
                              viewerDateInput: value,
                            })
                            : ''
                        }
                        value={viewerDateInput}
                        id='viewerDateInput'
                        label='Data'
                        disablePast={false}
                        disableFuture={false}
                        withTime
                        fullWidth
                      />

                      <TextField
                        id='editingNewViewerMessage'
                        fullWidth
                        placeholder='Mensagem do telespectador...'
                        value={viewerMessageInput}
                        onChange={event =>
                          setChatComponentState({
                            ...chatComponentState,
                            viewerMessageInput: event.target.value,
                          })
                        }
                      />

                      <IconButton
                        onClick={newViewerMessage}
                        className={classes.chatGuestBalloonEditIcon}
                      >
                        <SendIcon />
                      </IconButton>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div
                      key={'newUserMessage'}
                      className={classes.newUserMessage}
                    >
                      <Typography
                        paragraph
                        style={{ marginBottom: 0, color: '#555' }}
                      >
                        Envie como {user.nome}
                      </Typography>

                      <Typography
                        paragraph
                        style={{ marginTop: 32, color: '#666' }}
                      >
                        Data atual selecionada
                      </Typography>

                      <TextField
                        id='editingNewUserMessage'
                        fullWidth
                        placeholder='Mensagem do usuário...'
                        value={userMessageInput}
                        onChange={event =>
                          setChatComponentState({
                            ...chatComponentState,
                            userMessageInput: event.target.value,
                          })
                        }
                      />

                      <IconButton
                        onClick={newUserMessage}
                        className={classes.chatUserBalloonEditIcon}
                      >
                        <SendIcon />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}
        </div>

        {ticket.responsavel &&
          props.fromApp &&
          !isClosed &&
          (ticket.responsavel ? ticket.responsavel.id === user.id : false) &&
          (user.usuario_crc === usuarios.CRC ||
            user.usuario_crc === usuarios.CONTEUDO) &&
          ticket.status !== 5 && (
            <div className={classes.chatFooter}>
              <TextField
                id='userInput'
                placeholder='Digite aqui (Shift + Enter)...'
                fullWidth
                margin='normal'
                multiline
                rowsMax='5'
                value={userMessageInput}
                onChange={event =>
                  setChatComponentState({
                    ...chatComponentState,
                    userMessageInput: event.target.value,
                  })
                }
                onKeyPress={event => {
                  if (event.key === 'Enter' && event.shiftKey) {
                    sendMessage()
                  }
                }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() =>
                          setChatComponentState({
                            ...chatComponentState,
                            userMessageInput: `${userMessageInput}\n\nAcesse o link e avalie nosso atendimento: http://goo.gl/forms/fISpC6cUs4`,
                          })
                        }
                        aria-label='Adicionar Pesquisa de Satisfação'
                      >
                        <EmojiEmotionsIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => setDialogChatSuggestionsOpen(true)}
                        aria-label='Ver sugestões'
                      >
                        <ListIcon />
                      </IconButton>
                      <IconButton onClick={sendMessage} aria-label='Enviar'>
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
      </Paper>

      <DialogChatSuggestions
        dialogOpen={dialogChatSuggestionsOpen}
        setDialogOpen={setDialogChatSuggestionsOpen}
        getSuggestion={suggestion =>
          setChatComponentState({
            ...chatComponentState,
            userMessageInput: suggestion.mensagem,
          })
        }
      />
    </div>
  )
}

export default withStyles(styles)(Chat)
