import React, { useState, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import CompareArrowsIcon from '@material-ui/icons/CompareArrows'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import EditIcon from '@material-ui/icons/Edit'
import ListIcon from '@material-ui/icons/List'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import PersonIcon from '@material-ui/icons/Person'
import { withStyles } from '@material-ui/core/styles'
import { styles } from '../../styles/js/chatActions'
import { ApplicationContext } from '../../ContextProvider.jsx'
import { useRequest } from '../../hooks'

import DialogResponsavel from '../../containers/DialogResponsavel'
import DialogSuporte from '../../containers/DialogSuporte'
import DialogTelespectador from '../../containers/DialogTelespectador'
import DialogFinishTicket from '../../containers/DialogFinishTicket'
import DialogTicketsFromTelespectador from '../../containers/DialogTicketsFromTelespectador'
import DialogTicket from '../../containers/DialogTicket'
import * as usuarios from '../../constants/usuarios'
import DialogFinishTicketConteudo from '../../containers/DialogFinishTicketConteudo'
import { SnackbarContext } from '../../ContextProvider'
import firebaseApp from '../../constants/firebase'

import { READ_ONLY_MODE } from '../../constants/api.js'

function ChatActions(props) {
  const [dialogResponsavelOpen, setDialogResponsavelOpen] = useState(false)
  const [dialogSuporteOpen, setDialogSuporteOpen] = useState(false)
  const [dialogFinishTicketOpen, setDialogFinishTicketOpen] = useState(false)
  const [
    dialogFinishTicketConteudoOpen,
    setDialogFinishTicketConteudoOpen,
  ] = useState(false)
  const [dialogTelespectadorOpen, setDialogTelespectadorOpen] = useState(false)
  const [dialogTicketOpen, setDialogTicketOpen] = useState(false)
  const [
    dialogTicketsFromTelespectadorOpen,
    setDialogTicketsFromTelespectadorOpen,
  ] = useState(false)
  const { showSnackbar } = useContext(SnackbarContext)

  const {
    classes,
    isClosed,
    ticket,
    setRenderFullTicket,
    fetchTicket,
    telespectador,
  } = props

  const { user } = useContext(ApplicationContext)

  const handleFetchTelespectador = () => {
    setRenderFullTicket(false)
    fetchTicket()
  }

  const configTelespectadorLinguagemOfensiva = () => {
    telespectador.linguagem_ofensiva = telespectador.linguagem_ofensiva === 1 ? 0 : 1 
    setLinguagemOfensiva()
  }

  const [, , setLinguagemOfensiva] = useRequest(
    {},
    {
      url: 'telespectadores',
      method: 'put',
      data: telespectador
    },
    null,
    {
      onComplete: (response) => {
        showSnackbar('Telespectador ' + (telespectador.nome || '') + ' alterado com sucesso!', 'success')
        firebaseApp.firestore().collection('flerken-users').doc(telespectador.uid).onSnapshot((doc) => {
          console.log(doc);
        }, (error) => {
          console.log('FirebaseError', error)
        })
      },
      onError: error => {
        console.log(error)
        showSnackbar(error.response.data.code, 'error')
      },
    }
  )

  return (
    <div className={classes.viewerSectionInfoAndActions}>
      <Grid container spacing={1}>
        <Grid item xs>
          <Tooltip title='Histórico de Atendimentos'>
            <span>
              <Button
                onClick={() => setDialogTicketsFromTelespectadorOpen(true)}
                className={classes.button}
                variant='contained'
                color='primary'
              >
                <ListIcon className={classes.buttonIcon} />
              </Button>
            </span>
          </Tooltip>
        </Grid>

        <Grid item xs>
          <Tooltip title={READ_ONLY_MODE ? 'Exibir Telespectador' : 'Editar Telespectador'}>
            <span>
              <Button
                onClick={() => setDialogTelespectadorOpen('edit')}
                className={classes.button}
                variant='contained'
                color='primary'
                style={{ marginTop: 0 }}
                disabled={READ_ONLY_MODE ? false : !ticket.responsavel }
              >
                <PersonIcon className={classes.buttonIcon} />
              </Button>
            </span>
          </Tooltip>
        </Grid>

        <Grid item xs>
          <Tooltip title={telespectador.linguagem_ofensiva === 1 ? "Retirar 'Linguagem Ofensiva' do Telespectador" : "Definir Telespectador como 'Linguagem Ofensiva'"}>
            <span>
              <Button
                onClick={configTelespectadorLinguagemOfensiva}
                className={`${classes.button} ${telespectador.linguagem_ofensiva === 1 ? classes.buttonDanger : ''}`}
                color='primary'
                variant='contained'
                disabled={
                  (user.usuario_crc !== usuarios.CRC &&
                    user.usuario_crc !== usuarios.CONTEUDO) ||
                  (ticket.responsavel
                    ? user.id !== ticket.responsavel.id
                    : false) || READ_ONLY_MODE
                }
              >
                <VerifiedUserIcon className={classes.buttonIcon} />
              </Button>
            </span>
          </Tooltip>
        </Grid>

        <Grid item xs>
          <Tooltip title='Editar Atendimento'>
            <span>
              <Button
                onClick={() => setDialogTicketOpen('edit')}
                className={classes.button}
                variant='contained'
                color='primary'
                style={{ marginTop: 0 }}
                disabled={
                  isClosed ||
                  user.usuario_crc !== usuarios.CRC ||
                  !ticket.responsavel ||
                  READ_ONLY_MODE
                }
              >
                <EditIcon className={classes.buttonIcon} />
              </Button>
            </span>
          </Tooltip>
        </Grid>

        <Grid item xs>
          <Tooltip title='Definir Responsável'>
            <span>
              <Button
                onClick={() => setDialogResponsavelOpen(true)}
                className={classes.button}
                variant='contained'
                color='primary'
                disabled={isClosed || user.usuario_crc !== usuarios.CRC || READ_ONLY_MODE}
              >
                <CompareArrowsIcon className={classes.buttonIcon} />
              </Button>
            </span>
          </Tooltip>
        </Grid>

        <Grid item xs>
          <Tooltip title='Solicitar Suporte'>
            <span>
              <Button
                onClick={() => setDialogSuporteOpen(true)}
                className={classes.button}
                variant='contained'
                color='primary'
                disabled={
                  isClosed ||
                  user.usuario_crc === usuarios.CONTEUDO ||
                  user.usuario_crc === usuarios.CONVIDADO ||
                  !ticket.responsavel ||
                  READ_ONLY_MODE
                }
              >
                <LiveHelpIcon className={classes.buttonIcon} />
              </Button>
            </span>
          </Tooltip>
        </Grid>

        <Grid item xs>
          <Tooltip title='Finalizar Ticket'>
            <span>
              <Button
                onClick={() =>
                  user.usuario_crc !== usuarios.CONTEUDO
                    ? setDialogFinishTicketOpen(true)
                    : setDialogFinishTicketConteudoOpen(true)
                }
                className={classes.button + ' ' + classes.buttonFinish}
                variant='contained'
                disabled={
                  isClosed ||
                  ticket.status === 5 ||
                  (user.usuario_crc !== usuarios.CRC &&
                    user.usuario_crc !== usuarios.CONTEUDO) ||
                  (ticket.responsavel
                    ? user.id !== ticket.responsavel.id
                    : false) ||
                  READ_ONLY_MODE
                }
              >
                <DoneAllIcon className={classes.buttonIcon} />
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>

      <DialogResponsavel
        dialogOpen={dialogResponsavelOpen}
        setDialogOpen={setDialogResponsavelOpen}
        fetchTickets={fetchTicket}
        selectedRow={ticket}
        eraseSelectedRows={() => {}}
      />

      <DialogSuporte
        dialogOpen={dialogSuporteOpen}
        setDialogOpen={setDialogSuporteOpen}
        fetchTickets={fetchTicket}
        selectedRow={ticket}
        eraseSelectedRows={() => {}}
      />

      <DialogFinishTicket
        dialogOpen={dialogFinishTicketOpen}
        setDialogOpen={setDialogFinishTicketOpen}
        fetchTickets={fetchTicket}
        selectedRow={ticket}
        eraseSelectedRows={() => {}}
      />

      <DialogTelespectador
        dialogOpen={dialogTelespectadorOpen}
        setDialogOpen={setDialogTelespectadorOpen}
        telespectador={telespectador ? telespectador : ''}
        fetchTelespectadores={handleFetchTelespectador}
        handleTelespectadorDialogReset={() => {}}
      />

      <DialogTicketsFromTelespectador
        dialogOpen={dialogTicketsFromTelespectadorOpen}
        setDialogOpen={setDialogTicketsFromTelespectadorOpen}
        telespectador={telespectador ? telespectador : ''}
      />

      <DialogTicket
        dialogOpen={dialogTicketOpen}
        setDialogOpen={setDialogTicketOpen}
        ticket={ticket}
        fetchTickets={fetchTicket}
        handleTicketDialogReset={() => {}}
      />

      <DialogFinishTicketConteudo
        dialogOpen={dialogFinishTicketConteudoOpen}
        setDialogOpen={setDialogFinishTicketConteudoOpen}
        ticket={ticket}
        fetchTickets={fetchTicket}
        handleTicketDialogReset={() => {}}
      />
    </div>
  )
}

export default withStyles(styles)(withRouter(ChatActions))
