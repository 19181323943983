import React, { useState, useEffect } from 'react'

import AdvancedTable from '../AdvancedTable.jsx'
import { setLocalStorage } from '../../helpers/table'
import { getTableUsuariosDefinitions } from '../../constants/tablesDefinitions'

const TableUsuarios = (props) => {
  const { 
    rows, 
    rowsLength, 
    statusRequestUsuarios,
    pagination,
    setPagination,
    onRowClick,
    withSelect,
    setSelection,
    withHover,
  } = props

  const tableDefinitions = getTableUsuariosDefinitions(pagination)

  const [tableStates, setTableStates] = useState(
    { ...tableDefinitions, rows }
  )

  useEffect(() => {
    setTableStates({ ...tableStates, rows: rows, rowsLength: rowsLength })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows])

  useEffect(() => {
    let selectedRows = []
    tableStates.selection.map(selection => {
      return selectedRows.push(rows.find(row => row.id === selection))
    })

    setSelection(selectedRows)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableStates.selection])

  useEffect(() => {
    setLocalStorage('columnOrderUsuarios', tableStates.columnOrder)
    setLocalStorage('columnWidthsUsuarios', tableStates.columnWidths)
  }, [tableStates.columnOrder, tableStates.columnWidths])

  return (
    <AdvancedTable
      tableStates={tableStates}
      setTableStates={setTableStates}
      onRowClick={row => onRowClick(row)}
      totalCount={rowsLength}
      pagination={pagination}
      setPagination={setPagination}
      statusRequest={statusRequestUsuarios}
      bigPadding
      showSelect={withSelect}
      withHover={withHover}
    />
  )
}

export default TableUsuarios
