import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import CheckIcon from '@material-ui/icons/Check'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Paper from '@material-ui/core/Paper'
import SendIcon from '@material-ui/icons/Send'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { styles } from '../../styles/js/chatNotes'
import * as usuarios from '../../constants/usuarios'

import Loading from '../Loading.jsx'
import { ApplicationContext } from '../../ContextProvider.jsx'

import { READ_ONLY_MODE } from '../../constants/api.js'

let notes = []

const ChatNotes = (props) => {
  const [notesState, setNotesState] = useState(notes)
  
  const { user } = useContext(ApplicationContext)

  const { 
    chatNotesState, 
    setChatNotesState, 
    updateComentario, 
    criarComentario, 
    ticket,
    statusRequestTicket,
  } = props
  
  const { editingMessageBuffer, noteInput } = props.chatNotesState

  useEffect(() => {
    notes = []
    setChatNotesState({ ...chatNotesState, idTicket: props.ticket.id, idUsuario: user.id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (statusRequestTicket.done) {
      notes = []
      ticket.comentariosTicket.forEach(comentario => {
        notes.push({
          id: comentario.id,
          date: comentario.createdAt,
          message: comentario.mensagem,
          author: comentario.usuario?.nome,
          idAuthor: comentario.usuario?.id,
          editingActive: false,
          lastEditionBy: comentario.usuarioUltimaModificacao ? comentario.usuarioUltimaModificacao.nome : '',
          lastEditionAt: comentario.updatedAt,
        })
      })
    }

    setNotesState(notes)
    scrollToBottom()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket])

  useEffect(() => {
    scrollToBottom()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesState])

  const scrollToBottom = () => {
    if (statusRequestTicket.done) {
      let notesBody = document.getElementById('notesBody')
      notesBody.scrollTop = notesBody.scrollHeight
    }
  }

  const handleEditClick = (selectedNote) => {
    if (selectedNote.editingActive) {
      notes.forEach(note => {
        note.editingActive = false
      })
      updateComentario()
    } else {
      setChatNotesState({ ...chatNotesState, editingMessageBuffer: selectedNote.message, idUsuarioComentario: selectedNote.idAuthor, idComentario: selectedNote.id })

      notes.forEach(note => {
        note.editingActive = false
      })
      notes.find(note => note.id === selectedNote.id).editingActive = true
    }
    setNotesState(notes)
  }

  const sendNote = () => {
    if (chatNotesState.noteInput) {
      setNotesState(notes)
      scrollToBottom()
      setChatNotesState({ ...chatNotesState, noteInput: '' })
      criarComentario()
    }
  }

  const renderNotes = () => {
    return (
      <div>
        {notesState.map(note => (
          <Paper key={note.id} className={classes.notesBalloon}>
            <Typography className={classes.notesBalloonDate} paragraph>
              {moment(note.date).format('DD/MM/YYYY, H:mm')}
            </Typography>
  
            {!note.editingActive &&
            <Typography className={classes.notesBalloonMessage} style={{ whiteSpace: 'pre-wrap' }} paragraph>
              {note.message}
            </Typography>
            }
            {note.editingActive && 
            <TextField
              id={"editingMessage" + note.id}
              fullWidth
              multiline
              rows="5"
              margin="normal"
              value={editingMessageBuffer}
              onChange={event => setChatNotesState({ ...chatNotesState, editingMessageBuffer: event.target.value })}
            />
            }
  
            <Typography className={classes.notesBalloonAuthor} paragraph>
              {note.author}
            </Typography>
  
            {user.usuario_crc !== usuarios.CONVIDADO && !READ_ONLY_MODE &&
              <IconButton onClick={() => handleEditClick(note)} className={classes.notesBalloonEditIcon}>
                {note.editingActive && <CheckIcon />}
                {!note.editingActive && <EditIcon />}
              </IconButton>
            }
  
            {note.lastEditionBy && !note.editingActive &&
              <span className={classes.notesBalloonAudit}>
                Editado por {note.lastEditionBy} | {moment(note.lastEditionAt).format('DD/MM/YYYY, H:mm')}
              </span>
            }
          </Paper>
        ))}
      </div>
    )
  }

  const { classes } = props

  return (
    <Paper className={classes.notesSection}>
      <div className={classes.notesSectionHeader}>
        <Typography variant="h6">
          Notas da conversa
        </Typography>
      </div>
      
      {!statusRequestTicket.done && 
      <Loading />
      }
      <div id="notesBody" className={classes.notesContent}>
        {renderNotes()}
      </div>

      {ticket.responsavel &&
        <div className={classes.notesFooter}>
          <TextField
            id="userInput"
            placeholder={READ_ONLY_MODE ? 'Apenas leitura' : 'Escreva uma nota (Shift + Enter)...'}
            fullWidth
            disabled={READ_ONLY_MODE}
            margin="normal"
            value={noteInput}
            multiline
            rowsMax='5'
            onChange={event => setChatNotesState({ ...chatNotesState, noteInput: event.target.value })}
            onKeyPress={event => {
              if (event.key === 'Enter' && event.shiftKey) {
                sendNote()
              }
            }}
            InputProps={{
              disabled: READ_ONLY_MODE,
              disableUnderline: true,
              endAdornment: !READ_ONLY_MODE &&
                <InputAdornment position="end">
                  <IconButton onClick={sendNote} aria-label="Enviar">
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              }
            }
          />
        </div>
      }
    </Paper>
  )
}

export default withStyles(styles)(withRouter(ChatNotes))