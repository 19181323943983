export const styles = theme => ({
  container: {
    // display: "inline-block",
    position: "relative",
    top: 8,
  },

  containerDialog: {
    display: "inline-block",
    position: "relative",
    top: 8,
  },
  
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
  },
});