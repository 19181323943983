export const styles = theme => ({
  viewerSectionInfoAndActions: {
    height: 50,
    display: 'table-row',
  },

  viewerInfoTitle: {
    fontSize: '10pt',
    fontWeight: 400,
  },

  viewerInfo: {
    fontSize: '13pt',
    wordBreak: 'break-all',
  },

  button: {
    minWidth: 'unset',
    width: '100%',
    boxShadow: 'none',
    borderRadius: 2,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: '#26C1C3',
    '&:hover': {
      backgroundColor: '#1e9c9e',
    },
  },

  buttonFinish: {
    backgroundColor: '#009688',
    color: 'white',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#00776C',
    },
  },

  buttonDanger: {
    backgroundColor: '#dc3545',
    color: 'white',
    '&:hover': {
      backgroundColor: '#c82333'
    }
  },

  buttonIcon: {
    fontSize: '16pt',
  },
})
