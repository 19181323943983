import React, { useState, useEffect, useContext } from 'react'

import { useRequest } from '../hooks'

import DialogTicketComponent from '../components/Dialogs/DialogTicketComponent'
import { getTicketStatusByName } from '../helpers/index'
import { initialTicketState } from '../constants/initialConstants'
import { SnackbarContext } from '../ContextProvider'
import { APP_URL } from '../constants/api'

const DialogTicket = props => {
  const {
    dialogOpen,
    setDialogOpen,
    ticket,
    fetchTickets,
    handleTicketDialogReset,
    initialTelespectador,
  } = props

  const { showSnackbar } = useContext(SnackbarContext)
  const [ticketState, setTicketState] = useState(initialTicketState)

  const [, , create] = useRequest(
    {},
    {
      url: 'tickets',
      method: 'post',
      data: ticketState
    },
    null,
    {
      onComplete: response => {
        setDialogOpen(false)
        handleTicketDialogReset()
        fetchTickets()
        showSnackbar('Atendimento criado com sucesso!', 'success')

        if (response.status !== 1) {
          const win = window.open(`${APP_URL}/chat/${response.id}`, '_blank')
          win.focus()
        }
      },
      onError: error => {
        showSnackbar(error.response.data.code, 'error')
      },
    }
  )

  const [, , update] = useRequest(
    {},
    {
      url: 'tickets',
      method: 'put',
      data: ticketState,
    },
    null,
    {
      onComplete: () => {
        showSnackbar('Atendimento #' + ticketState.id + ' alterado com sucesso!', 'success')
        setDialogOpen(false)
        handleTicketDialogReset()
        fetchTickets()
      },
      onError: error => {
        showSnackbar(error.response.data.code, 'error')
      },
    }
  )

  const [regioes, statusRequestRegioes, fetchRegioes] = useRequest(
    [],
    {
      url: 'regioes',
      params: {
        active: 1
      },
      method: 'get',
    },
    null
  )

  const [origens, statusRequestOrigens, fetchOrigens] = useRequest(
    [],
    {
      url: 'origens',
      params: {
        active: 1,
        withoutApp: 1,
      },
      method: 'get',
    },
    null
  )

  const [origensWithoutApp, statusRequestOrigensWithoutApp, fetchOrigensWithoutApp] = useRequest(
    [],
    {
      url: 'origens',
      params: {
        active: 1,
        withoutApp: 1,
      },
      method: 'get',
    },
    null
  )

  const [categorias, statusRequestCategorias, fetchCategorias] = useRequest(
    [],
    {
      url: 'categorias',
      params: {
        active: 1
      },
      method: 'get',
    },
    null
  )

  useEffect(() => {
    if (!!dialogOpen && regioes.length === 0 && categorias.length === 0 && origens.length === 0 && origensWithoutApp.length === 0) {
      fetchRegioes()
      fetchCategorias()
      fetchOrigens()
      fetchOrigensWithoutApp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen])

  useEffect(() => {
    if (initialTelespectador) {
      setTicketState({
        ...initialTicketState,
        selectedTelespectador: initialTelespectador,
        telespectador: initialTelespectador.nome,
      })
    }
  }, [initialTelespectador])

  useEffect(() => {
    if (ticket) {
      setTicketState({
        ...initialTicketState,
        id: ticket.id,
        praca: ticket.id_regiao, 
        origem: ticket.id_origem, 
        categoria: ticket.id_categoria,
        status: typeof ticket.status === 'string' ? getTicketStatusByName(ticket.status) : ticket.status,
      })
    }
  }, [ticket])

  const handleClose = () => {
    setDialogOpen(false)
    setTicketState(initialTicketState)
    handleTicketDialogReset()
  }

  return (
    <DialogTicketComponent
      ticket={ticketState}
      setTicket={setTicketState}
      regioes={regioes}
      categorias={categorias}
      origens={origens}
      origensWithoutApp={origensWithoutApp}
      handleAction={(dialogOpen === 'create' || dialogOpen === 'createApp') ? create : update}
      handleClose={handleClose}
      dialogOpen={dialogOpen}
      statusRequest={statusRequestCategorias.done && statusRequestOrigens.done && statusRequestRegioes.done && statusRequestOrigensWithoutApp.done}
    />
  )
}

export default DialogTicket
