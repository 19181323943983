import React, { useState, useEffect } from 'react'
import { Button, Grid, Paper, MenuItem, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import MaskedInputComponent from '../../components/MaskedInputComponent'
import { telespectadoresSearchFormFields } from '../../constants/initialConstants'
import { styles } from '../../styles/js/searchForm'
import 'moment/locale/pt-br'

function SearchFormTelespectadores(props) {
  const [searchFormState, setSearchFormState] = useState(telespectadoresSearchFormFields)

  const { 
    classes,
    filter,
    setFilterState,
    regioes,
  } = props

  const {
    nome,
    email,
    telefone,
    praca,
  } = searchFormState

  useEffect(() => {
    if (JSON.stringify(searchFormState) === JSON.stringify(telespectadoresSearchFormFields)) {
      setFilterState('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFormState])

  const resetSearchFormState = () => {
    setSearchFormState(telespectadoresSearchFormFields)
    setFilterState('')
  }

  const handleChange = event => {
    setSearchFormState({
      ...searchFormState,
      [event.target.id]: event.target.value
    })
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      filter(searchFormState)
    }
  }

  return (
    <Paper className={classes.paper} elevation={0} onKeyPress={handleKeyPress}>
      <form className={classes.container} noValidate autoComplete="off">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={classes.formSection}>
              <span className={classes.formSectionLabel}>
                Geral
              </span>
              <Grid container>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="nome"
                    label="Nome"
                    className={classes.textField}
                    value={nome}
                    onChange={handleChange}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <TextField
                    id="email"
                    label="E-mail"
                    className={classes.textField}
                    value={email}
                    onChange={handleChange}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <MaskedInputComponent 
                    handleParentState={handleChange}
                    id="telefone"
                    name="telefone"
                    label="Telefone"
                    state="telefone"
                    value={telefone}
                    phoneField
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <TextField
                    id="praca"
                    label="Praça"
                    className={classes.textField}
                    value={praca}
                    onChange={event => setSearchFormState({ ...searchFormState, praca: event.target.value })}
                    margin="normal"
                    select
                  >
                    {regioes.map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <Button 
          onClick={() => filter(searchFormState)} 
          variant="contained" 
          className={classes.filterButton} 
          disabled={JSON.stringify(searchFormState) === JSON.stringify(telespectadoresSearchFormFields) ? true : false}
        >
          Filtrar
        </Button>
        <Button 
          onClick={resetSearchFormState} 
          variant="contained" 
          className={classes.clearButton} 
          disabled={JSON.stringify(searchFormState) === JSON.stringify(telespectadoresSearchFormFields) ? true : false}
        >
          Limpar
        </Button>
      </form>
    </Paper>
  )
}

export default withStyles(styles)(SearchFormTelespectadores)
