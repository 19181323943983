import React, { useState, useEffect, useContext } from 'react'

import { useRequest } from '../hooks'

import DialogResponsavelComponent from '../components/Dialogs/DialogResponsavelComponent.jsx'
import { initialResponsavelState } from '../constants/initialConstants'
import { ApplicationContext, SnackbarContext } from '../ContextProvider'

import * as usuarios from '../constants/usuarios'

const DialogResponsavel = props => {
  const { user } = useContext(ApplicationContext)
  const { showSnackbar } = useContext(SnackbarContext)

  const {
    dialogOpen,
    setDialogOpen,
    fetchTickets,
    rows,
    selectedRow,
    selectedRows,
    eraseSelectedRows,
  } = props

  const [responsavelDialogState, setResponsavelDialogState] = useState(initialResponsavelState)

  const [, , trocarResponsavel] = useRequest(
    {},
    {
      url: 'tickets',
      method: 'put',
      data: responsavelDialogState,
      params: {
        trocarResponsavel: true,
      }
    },
    null,
    {
      onComplete: () => {
        eraseSelectedRows()
        handleClose()
        showSnackbar('Ticket(s) atribuido(s) a ' + responsavelDialogState.responsavel + '!', 'success')
        fetchTickets()
      },
      onError: error => {
        showSnackbar(error.response.data.code, 'error')
      },
    }
  )

  useEffect(() => {
    if (selectedRow) {
      setResponsavelDialogState({ 
        ...responsavelDialogState, 
        idsTickets: [selectedRow.id],
        idResponsavel: 
          selectedRow.responsavel ? 
            selectedRow.responsavel.id : 
            user.usuario_crc === usuarios.CRC ? 
              user.id : '',
        responsavel: 
          selectedRow.responsavel ? 
            selectedRow.responsavel.nome : 
            user.usuario_crc === usuarios.CRC ? 
              user.nome : '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow])

  useEffect(() => {
    if (selectedRows) {
      setResponsavelDialogState({ 
        ...responsavelDialogState, 
        idsTickets: selectedRows,
        idResponsavel: 
          user.usuario_crc === usuarios.CRC ? 
            user.id : 
            '',
        responsavel: 
          user.usuario_crc === usuarios.CRC ? 
            user.nome : 
            '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows])


  const handleClose = () => {
    setResponsavelDialogState(initialResponsavelState)
    setDialogOpen(false)
  }

  const handleAction = () => {
    trocarResponsavel()
  }

  return (
    <DialogResponsavelComponent
      responsavelDialogState={responsavelDialogState}
      setResponsavelDialogState={setResponsavelDialogState}
      handleAction={handleAction}
      handleClose={handleClose}
      dialogOpen={dialogOpen}
      actionTitle='Definir Responsável'
      rows={rows}
      selectedRow={selectedRow}
      selectedRows={selectedRows}
    />
  )
}

export default DialogResponsavel
