import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from "react-router-dom"

import { Grid } from '@material-ui/core'

import { useRequest } from '../hooks'

import { initialTelespectadorState } from '../constants/initialConstants'
import SearchFormTelespectadores from '../components/SearchForms/SearchFormTelespectadores'
import TableTelespectadores from '../components/Tables/TableTelespectadores'
import HeaderActions from '../components/HeaderActions'
import DialogTelespectador from './DialogTelespectador'
import { ApplicationContext } from '../ContextProvider.jsx'
import * as usuarios from '../constants/usuarios'

import { READ_ONLY_MODE } from '../constants/api.js'

const Telespectadores = (props) => {
  const { user } = useContext(ApplicationContext)
  const { populateLookupField, telespectadoresApp } = props

  const [filterState, setFilterState] = useState({})
  const [dialogOpen, setDialogOpen] = useState(false)

  const [telespectadorState, setTelespectadorState] = useState(initialTelespectadorState)

  const [pagination, setPagination] = useState({
    currentPage: 0, 
    pageSize: window.localStorage.getItem('pageSizeTelespectadores') ? 
      JSON.parse(window.localStorage.getItem('pageSizeTelespectadores')) : 
      20
  })

  const [telespectadores, statusRequestTelespectadores, fetchTelespectadores] = useRequest(
    [],
    {
      url: telespectadoresApp ? 'telespectadores_app' : 'telespectadores',
      method: 'get',
      params: {
        pageSize: pagination.pageSize,
        currentPage: pagination.currentPage,
        ...filterState,
      },
    },
    null
  )

  const [regioes, , fetchRegioes] = useRequest(
    [],
    {
      url: 'regioes',
      params: {
        active: 1
      },
      method: 'get',
    },
    null
  )

  useEffect(() => {
    fetchRegioes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPagination({
      currentPage: 0, 
      pageSize: window.localStorage.getItem('pageSizeTelespectadores') ? 
        JSON.parse(window.localStorage.getItem('pageSizeTelespectadores')) : 
        20
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState])
  
  useEffect(() => {
    fetchTelespectadores()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  const filter = (searchFormState) => {
    setFilterState(searchFormState)
  }

  const handleTelespectadorEdit = (row) => {
    setTelespectadorState(row)
    setDialogOpen('edit')
  }

  const handleOnRowClick = (row) => {
    if (populateLookupField) {
      populateLookupField(row)
    } else {
      return
    }
  }

  const handleTelespectadorDialogReset = () => {
    setTelespectadorState(initialTelespectadorState)
    setDialogOpen(false)
  }

  return (
    <div>
      <Grid container>
        {user.usuario_crc === usuarios.CRC && !READ_ONLY_MODE &&
          <HeaderActions
            setDialogOpen={setDialogOpen}
            createButtonTitle='Criar Telespectador'
            hideSearchButton
          />
        }

        <SearchFormTelespectadores
          setFilterState={setFilterState}
          filter={filter}
          regioes={regioes}
        />

        <TableTelespectadores
          rows={telespectadores.rows === undefined ? [] : telespectadores.rows}
          rowsLength={telespectadores.count}
          onRowClick={handleOnRowClick}
          tableEdit={handleTelespectadorEdit}
          pagination={pagination}
          setPagination={setPagination}
          statusRequestTelespectadores={statusRequestTelespectadores}
          withHover={populateLookupField ? true : false}
          telespectadoresApp={telespectadoresApp}
        />
      </Grid>

      <DialogTelespectador
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        telespectador={telespectadorState}
        fetchTelespectadores={fetchTelespectadores}
        handleTelespectadorDialogReset={handleTelespectadorDialogReset}
        fromLookup={!!populateLookupField}
      />
    </div>
  )
}

export default withRouter(Telespectadores)