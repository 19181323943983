import React from 'react'
import MoodIcon from '@material-ui/icons/Mood'
import MoodBadIcon from '@material-ui/icons/MoodBad'
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied'

export const chatSuggestions = [
  {
    id: 1,
    titulo: 'Agradecimento - Contato',
    mensagem:
      'Olá! Tudo bem?\n\nAgradecemos seu contato e ficamos felizes em saber que temos a sua companhia.\nContinue com a gente, sempre. 😃\nUm abraço e até mais!',
    icone: <MoodIcon />,
    avatarStyle: { backgroundColor: '#009688' },
  },
  {
    id: 2,
    titulo: 'Agradecimento - Participação App',
    mensagem:
      'Olá, tudo bem?\n\nObrigada pela sua participação em nosso app.\nQue legal saber que você nos acompanha. 🙂\n\nEntre em contato sempre que precisar, estaremos sempre à disposição.\nAbraços!',
    icone: <MoodIcon />,
    avatarStyle: { backgroundColor: '#009688' },
  },
  {
    id: 3,
    titulo: 'Sugestão de Pauta I',
    mensagem:
      'Olá! Tudo bem?\n\nAgradecemos seu contato.\n\nSua sugestão foi encaminhada à equipe de jornalismo para avaliação. Caso a matéria seja produzida entraremos em contato com você.\n\nContinue sempre com a gente.\nUm abraço e até mais!',
    icone: <SentimentSatisfiedIcon />,
    avatarStyle: { backgroundColor: '#26C1C3' },
  },
  {
    id: 4,
    titulo: 'Sugestão de Pauta II',
    mensagem:
      'Olá, tudo bem?\n\nAgradecemos pelo contato e por nos mandar essas informações.\nNossa equipe de jornalismo vai avaliar a possibilidade de fazer uma matéria a respeito da situação. Ficamos à disposição. Até mais!',
    icone: <SentimentSatisfiedIcon />,
    avatarStyle: { backgroundColor: '#26C1C3' },
  },
  {
    id: 5,
    titulo: 'Sugestão de Pauta III',
    mensagem:
      'Olá, tudo bem?\n\nVamos repassar sua mensagem à equipe de jornalismo para avaliar a possibilidade de ser feita uma matéria sobre o assunto.\nAgradecemos pelo contato e sugestão.\n\nAbraços e até mais!',
    icone: <SentimentSatisfiedIcon />,
    avatarStyle: { backgroundColor: '#26C1C3' },
  },
  {
    id: 6,
    titulo: 'Comentário',
    mensagem:
      'Olá, tudo bem?\n\nObrigada por escrever para nós!\nContinue participando da nossa programação e fique à vontade para entrar em contato conosco sempre que quiser.\n\nAbraços e até mais!',
    icone: <MoodIcon />,
    avatarStyle: { backgroundColor: '#26C1C3' },
  },
  {
    id: 7,
    titulo: 'Comentário - App',
    mensagem:
      'Olá, tudo bem?\n\nAgradecemos por entrar em contato e pela participação em nosso app.\nSua mensagem foi direcionada a nossa equipe de jornalismo.\n\nAbraços!',
    icone: <MoodIcon />,
    avatarStyle: { backgroundColor: '#26C1C3' },
  },
  {
    id: 8,
    titulo: 'Reclamação',
    mensagem:
      'Olá! Tudo bem?\n\nAgradecemos seu contato e interação pelo aplicativo.\n\nSua opinião é muito importante para nós e já foi encaminhada à equipe responsável.\n\nPermanecemos à disposição.\nUm abraço e até mais!',
    icone: <MoodBadIcon />,
  },
  {
    id: 9,
    titulo: 'PLUG - Participação',
    mensagem:
      'Oie, tudo bem?\n\nObrigada pela participação.\nQue bom que tivemos sua companhia no passeio de hoje! 🙂\n\nAbraços e tenha um ótimo fim de semana!\n\nPara rever o que já rolou no programa, acesse nosso site: https://gshow.globo.com/RPC/Plug/',
    icone: (
      <img
        alt='plug'
        style={{ width: '100%' }}
        src='http://s2.glbimg.com/ApxHjzPLis_U5aQSpFaKA2RBGJs=/s.glbimg.com/et/tv/f/original/2015/05/05/logo-4.jpg'
      />
    ),
    avatarStyle: { backgroundColor: '#fff' },
  },
  {
    id: 10,
    titulo: 'PLUG - Contato',
    mensagem:
      'Olá! Tudo bem?\n\nO Plug viaja 🛩 para mais um cantinho do nosso estado e a gente pega carona com a Michelly 😄\nAgradecemos seu contato e ficamos felizes em saber que temos sua companhia em mais um sábado. Um abraço e até mais!\n\nPara rever o programa, receitas e outros conteúdos, acesse https://gshow.globo.com/RPC/Plug/',
    icone: (
      <img
        alt='plug'
        style={{ width: '100%' }}
        src='http://s2.glbimg.com/ApxHjzPLis_U5aQSpFaKA2RBGJs=/s.glbimg.com/et/tv/f/original/2015/05/05/logo-4.jpg'
      />
    ),
    avatarStyle: { backgroundColor: '#fff' },
  },
  {
    id: 11,
    titulo: 'PLUG - Interatividade',
    mensagem:
      'Olá, boa tarde!\n\nQue legal saber que você está acompanhando a nossa programação.\nHoje o Plug desembarca em mais uma cidade do nosso Estado, e você viaja junto com a gente. 🙌\n\nUm excelente fim de semana para você!\n\nConfira todos os vídeos, receitas e curiosidades do programa pelo link abaixo: http://gshow.globo.com/RPC/Plug/',
    icone: (
      <img
        alt='plug'
        style={{ width: '100%' }}
        src='http://s2.glbimg.com/ApxHjzPLis_U5aQSpFaKA2RBGJs=/s.glbimg.com/et/tv/f/original/2015/05/05/logo-4.jpg'
      />
    ),
    avatarStyle: { backgroundColor: '#fff' },
  },
  {
    id: 12,
    titulo: 'Estúdio C - Participação',
    mensagem:
      'Olá! Tudo bem?\n\nMuito obrigada por acompanhar o Estúdio C! 🙂\nContinue participando da nossa programação e fique à vontade para entrar em contato conosco sempre que quiser.\nUm ótimo fim de semana para você.\nPara rever tudo o que foi exibido neste e em outros programas, acesse https://gshow.globo.com/RPC/Estudio-C/',
    icone: (
      <img
        alt='estudio-c'
        style={{ width: '100%' }}
        src='https://scontent.frao1-2.fna.fbcdn.net/v/t1.0-9/18198437_1432779496787294_4272814917081370985_n.png?_nc_cat=102&_nc_oc=AQlxOi5XRje4T2e-1zTFBIgBs7AUSt4mqJ_W-WBbcmAn71FC2fRUHX6KzEMz-faojy4&_nc_ht=scontent.frao1-2.fna&oh=1b2871cdb307bfbf8e3b00fa1bc45532&oe=5DF4B304'
      />
    ),
    avatarStyle: { backgroundColor: '#fff' },
  },
]
