export const generateDialogsSubtitle = (row, rows, selectedRows, fromTable) => {
  if (row) {
    return "para atendimento de " + row.telespectador.nome + ".";

  } else if (selectedRows.length) {
    if (selectedRows.length === 1 || fromTable) {
      return "para atendimento de " + rows.find(i => i.id === selectedRows[0]).telespectador.nome + ".";

    } else if (selectedRows.length === 2) {
      return "para atendimentos de " + rows.find(i => i.id === selectedRows[0]).telespectador.nome + " e " + rows.find(i => i.id === selectedRows[1]).telespectador.nome + ".";

    } else if (selectedRows.length >= 3) {
      return "para atendimentos de " + rows.find(i => i.id === selectedRows[0]).telespectador.nome + ", " + rows.find(i => i.id === selectedRows[1]).telespectador.nome + " e outros " + (selectedRows.length - 2) + " telespectadores.";
    }
  }
}

export const getTicketStatusByName = name => {
  switch (name) {
    case 'Aberto':
      return 1
    case 'Aguardando Atendimento':
      return 2
    case 'Fechado':
      return 3
    case 'Aguardando Resposta':
      return 4
    case 'Aguardando Finalização':
      return 5
    default:
      return ''
  }
}