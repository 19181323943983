import React, { useState, useEffect, useContext } from 'react'

import AdvancedTable from '../AdvancedTable.jsx'
import { setLocalStorage } from '../../helpers/table'
import { getTableTelespectadoresDefinitions } from '../../constants/tablesDefinitions'
import { ApplicationContext } from '../../ContextProvider.jsx'

const TableTelespectadores = (props) => {
  const { user } = useContext(ApplicationContext)

  const { 
    rows, 
    rowsLength, 
    statusRequestTelespectadores,
    pagination,
    setPagination,
    tableEdit,
    onRowClick,
    withHover,
    telespectadoresApp,
  } = props

  const tableDefinitions = getTableTelespectadoresDefinitions(pagination, telespectadoresApp)

  const [tableStates, setTableStates] = useState(
    { ...tableDefinitions, rows }
  )

  useEffect(() => {
    setTableStates({ ...tableStates, rows: rows, rowsLength: rowsLength })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows])

  useEffect(() => {
    setLocalStorage('pageSizeTelespectadores', pagination.pageSize)
  }, [pagination.pageSize])

  useEffect(() => {
    setLocalStorage('columnOrderTelespectadores', tableStates.columnOrder)
    setLocalStorage('columnWidthsTelespectadores', tableStates.columnWidths)
  }, [tableStates.columnOrder, tableStates.columnWidths])

  return (
    <AdvancedTable
      tableStates={tableStates}
      setTableStates={setTableStates}
      onRowClick={row => onRowClick(row)}
      tableEdit={row => tableEdit(row)}
      totalCount={rowsLength}
      pagination={pagination}
      setPagination={setPagination}
      statusRequest={statusRequestTelespectadores}
      withHover={withHover}
      user={user}
    />
  )
}

export default TableTelespectadores
