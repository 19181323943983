import React, { useState, useEffect, useContext } from 'react'

import { useRequest } from '../hooks'

import DialogTelespectadorComponent from '../components/Dialogs/DialogTelespectadorComponent.jsx'
import DialogTicket from './DialogTicket.jsx'
import { initialTelespectadorState } from '../constants/initialConstants'
import { ApplicationContext, SnackbarContext } from '../ContextProvider'

import { READ_ONLY_MODE } from '../constants/api.js'

const DialogTelespectador = props => {
  const {
    dialogOpen,
    setDialogOpen,
    fetchTelespectadores,
    idTelespectador,
    handleTelespectadorDialogReset,
    fromLookup
  } = props

  const { ticketCreated, setTicketCreated } = useContext(ApplicationContext)
  const { showSnackbar } = useContext(SnackbarContext)

  const [telespectadorState, setTelespectadorState] = useState(initialTelespectadorState)
  const [dialogTicketOpen, setDialogTicketOpen] = useState(false)

  const [, statusRequestTelespectador, fetchTelespectador] = useRequest(
    [],
    {
      url: 'telespectadores/' + idTelespectador,
      method: 'get',
    },
    null,
    {
      onComplete: async response => {
        await setTelespectadorState(response)
      }
    }
  )

  const [, , create] = useRequest(
    {},
    {
      url: 'telespectadores',
      method: 'post',
      data: telespectadorState
    },
    null,
    {
      onComplete: async response => {
        await setTelespectadorState({ ...telespectadorState, id: response.id })
        setDialogOpen(false)
        fetchTelespectadores()
        showSnackbar('Telespectador criado com sucesso!', 'success')
        if (!fromLookup) {
          setDialogTicketOpen('create')
        }
        handleTelespectadorDialogReset()
      },
      onError: error => {
        showSnackbar(error.response.data.code, 'error')
      },
    }
  )

  const [, , update] = useRequest(
    {},
    {
      url: 'telespectadores',
      method: 'put',
      data: telespectadorState
    },
    null,
    {
      onComplete: () => {
        setDialogOpen(false)
        handleTelespectadorDialogReset()
        fetchTelespectadores()
        showSnackbar('Telespectador ' + (telespectadorState.nome || '') + ' editado com sucesso!', 'success')
      },
      onError: error => {
        showSnackbar(error.response.data.code, 'error')
      },
    }
  )

  const [cidade,, fetchCidade] = useRequest(
    [],
    {
      url: 'cidade/' + telespectadorState.endereco.id_ibge,
      method: 'get',
    },
    null
  )

  const [regioes, statusRequestRegioes, fetchRegioes] = useRequest(
    [],
    {
      url: 'regioes',
      params: {
        active: 1
      },
      method: 'get',
    },
    null
  )

  useEffect(() => {
    if (!!dialogOpen && regioes.length === 0) {
      fetchRegioes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen])

  useEffect(() => {
    if (props.telespectador) {
      setTelespectadorState(props.telespectador)
    }
  }, [props.telespectador])

  useEffect(() => {
    if (idTelespectador) {
      fetchTelespectador()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idTelespectador])

  const handleClose = () => {
    setDialogOpen(false)
    handleTelespectadorDialogReset()
  }

  return (
    <div>
      <DialogTelespectadorComponent
        telespectador={telespectadorState}
        setTelespectador={setTelespectadorState}
        cidade={cidade}
        fetchCidade={fetchCidade}
        regioes={regioes}
        handleAction={dialogOpen === 'create' ? create : update}
        handleClose={handleClose}
        dialogOpen={dialogOpen === 'edit' || dialogOpen === 'create' ? true : false}
        actionTitle={READ_ONLY_MODE ? 'Informações do' :  (dialogOpen === 'edit' ? "Editar" : 'Criar')}
        statusRequest={props.telespectador ? 
            statusRequestRegioes.done :
            statusRequestRegioes.done && statusRequestTelespectador.done
        }
      />
      
      <DialogTicket
        dialogOpen={dialogTicketOpen}
        setDialogOpen={setDialogTicketOpen}
        fetchTickets={() => setTicketCreated(!ticketCreated)}
        handleTicketDialogReset={() => {}}
        initialTelespectador={telespectadorState}
      />
    </div>
  )
}

export default DialogTelespectador
