import React, { useEffect, useState } from 'react'
import { Button, Grid, Paper, MenuItem, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete';

import DatePickerComponent from '../../components/DatePickerComponent'
import { mensagensSearchFormFields } from '../../constants/initialConstants'
import { styles } from '../../styles/js/searchForm'
import 'moment/locale/pt-br'
import { useRequest } from './../../hooks'

function SearchFormMensagens(props) {
  const {
    classes,
    filter,
    filterState,
    setFilterState,
    regioes,
    categorias,
  } = props

  const {
    dataInicial,
    dataFinal,
    id,
    telespectador,
    usuario,
    praca,
    categoria,
    mensagem,
  } = filterState

  const [uniqueWords, setUniqueWords] = useState([])
  const [palavrasChaveSelecionadas, setPalavrasChaveSelecionadas] = useState([])

  const [palavrasChave, , fetchPalavrasChave] = useRequest(
    [],
    {
      url: 'palavras_chave',
      params: {
        active: 1,
        input: '',
      },
      method: 'get',
    },
    null,
  )

  useEffect(() => {
    fetchPalavrasChave()
  }, [])

  useEffect(() => {
    if (
      JSON.stringify(filterState) === JSON.stringify(mensagensSearchFormFields)
    ) {
      resetFilterState()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState])

  useEffect(() => {
    if (palavrasChave.length > 1) {
      setUniqueWords(palavrasChave.filter((val, index, self) =>
                      index === self.findIndex((t) => (t.slug === val.slug))))
    }
  }, [palavrasChave.length])

  const resetFilterState = async () => {
    setPalavrasChaveSelecionadas([])
    await setFilterState(mensagensSearchFormFields)
    filter('')
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      filter('')
    }
  }

  return (
    <Paper className={classes.paper} elevation={0} onKeyPress={handleKeyPress}>
      <form className={classes.container} noValidate autoComplete='off'>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <div className={classes.formSection}>
              <span className={classes.formSectionLabel}>
                Datas de Cadastro
              </span>

              <Grid container>
                <Grid item xs={12} sm={6}>
                  <DatePickerComponent
                    onChange={value =>
                      setFilterState({ ...filterState, dataInicial: value })
                    }
                    value={dataInicial}
                    id='dataInicial'
                    label='Data Início'
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <DatePickerComponent
                    onChange={value =>
                      setFilterState({ ...filterState, dataFinal: value })
                    }
                    value={dataFinal}
                    id='dataFinal'
                    label='Data Fim'
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className={classes.formSection}>
              <Grid container>
                <Grid item xs={12} md={3}>
                  <TextField
                    id='id'
                    label='ID'
                    className={classes.textField}
                    value={id}
                    onChange={event =>
                      setFilterState({ ...filterState, id: event.target.value })
                    }
                    margin='normal'
                  />
                </Grid>

                <Grid item xs={12} md={9}>
                  <TextField
                    id='telespectador'
                    label='Telespectador'
                    className={classes.textField}
                    value={telespectador}
                    onChange={event =>
                      setFilterState({
                        ...filterState,
                        telespectador: event.target.value,
                      })
                    }
                    margin='normal'
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.formSection}>
              <span className={classes.formSectionLabel}>Geral</span>

              <Grid container>
                <Grid item xs={12} sm={4} md={3}>
                  <TextField
                    id='praca'
                    select
                    label='Praça'
                    className={classes.textField}
                    value={praca}
                    onChange={event =>
                      setFilterState({
                        ...filterState,
                        praca: event.target.value,
                      })
                    }
                    margin='normal'
                  >
                    {regioes.map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <TextField
                    id='categoria'
                    select
                    label='Categoria'
                    className={classes.textField}
                    value={categoria}
                    onChange={event =>
                      setFilterState({
                        ...filterState,
                        categoria: event.target.value,
                      })
                    }
                    margin='normal'
                  >
                    {categorias.map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4} md={6} style={{paddingTop: 15, paddingLeft: 15, paddingRight: 15}}>
                  <Autocomplete
                    multiple
                    value={palavrasChaveSelecionadas}
                    id='m-palavras-chave-search'
                    loading={uniqueWords.length < 1}
                    loadingText='Carregando...'
                    onChange={(event, newInputValue) => {
                      setFilterState({
                        ...filterState,
                        palavrasChave: `${newInputValue.map(val => val.slug)}`
                      })
                      setPalavrasChaveSelecionadas(newInputValue)
                    }}
                    options={uniqueWords}
                    getOptionLabel={(option) => option.nome}
                    getOptionSelected={(option, value) => option.slug === value.slug}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Palavras-Chave"
                        placeholder="Selecione uma ou mais"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id='mensagem'
                    label='Mensagem'
                    className={classes.textField}
                    value={mensagem}
                    onChange={event =>
                      setFilterState({
                        ...filterState,
                        mensagem: event.target.value,
                      })
                    }
                    margin='normal'
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <Button
          onClick={filter}
          variant='contained'
          className={classes.filterButton}
          disabled={
            JSON.stringify(filterState) ===
            JSON.stringify(mensagensSearchFormFields)
              ? true
              : false
          }
        >
          Filtrar
        </Button>
        <Button
          onClick={resetFilterState}
          variant='contained'
          className={classes.clearButton}
          disabled={
            JSON.stringify(filterState) ===
            JSON.stringify(mensagensSearchFormFields)
              ? true
              : false
          }
        >
          Limpar
        </Button>
      </form>
    </Paper>
  )
}

export default withStyles(styles)(SearchFormMensagens)
