import axios from 'axios'
import { APP_URL, AUTHENTICATION_URL, API_BASE_URL } from '../constants/api'
import { authStatuses } from '../constants/authentication'
import firebaseApp from '../constants/firebase'

export const reloadPage = () => {
  global.location.href = `${APP_URL}${window.location.pathname}`
}

export const logout = () => {
  window.localStorage.clear()
  // window.localStorage.setItem('tokenUsuario', '')
  reloadPage()
}

export const getLocalStorageToken = () => {
  return window.localStorage.getItem('tokenUsuario')
}

export const isAuthenticated = () => {
  const tokenUsuario = getLocalStorageToken()

  if (tokenUsuario) {
    const decodedToken = getDecodedPermissionamentoToken(JSON.parse(tokenUsuario))

    let dateNow = Date.now().toString().substring(0, 10)

    if (decodedToken.exp < parseInt(dateNow)) {
      return authStatuses.EXPIRED
    }
    return authStatuses.AUTHENTICATED
  }

  return authStatuses.UNAUTHENTICATED
}

export const getUrlToken = () => {
  const url = new URL(window.location.href)
  const token = url.searchParams.get("token")
  return token
}

export const redirectToAuthentication = () => {
  const token = getUrlToken()

  if (!token) {
    global.location.href = AUTHENTICATION_URL

  } else {
    fetchPermissionamento(token)
  }
}

export const fetchPermissionamento = (token) => {
  if (token) {
    axios.get(`${API_BASE_URL}auth?token=${token}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`,
      },
      timeout: 20000,
    })
    .then(function (response) {
      window.localStorage.setItem('tokenUsuario', JSON.stringify(response.data))
      
      firebaseApp.auth().signInWithCustomToken(response.data.firebaseToken).then((response) => {
        console.log('firebase:signInWithCustomToken', response)
        reloadPage()
      }).catch((error) => {
        console.log('firebase:signInWithCustomToken:error', error)
      });
    })
    .catch(function (error) {
      console.log(error)
    })
  }
}

export const getDecodedPermissionamentoToken = (tokenUsuario) => {
  let decodedToken

  if (tokenUsuario) {
    let base64Url = tokenUsuario.token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    decodedToken = JSON.parse(window.atob(base64));
  }

  return decodedToken
}
