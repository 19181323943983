import React from 'react'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import ApplicationRouter from './routes/ApplicationRouter.jsx'

const theme = createTheme({
  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottomColor: '#006497',
        },
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      // main: '#006497',
      main: '#006497',
      // #1e9c9e // lembrar de substituir esse também
      // #0075bc // lembrar de substituir esse também
    },
    secondary: {
      // main: '#a60025',
      main: '#a60025',
      // #26C1C3 // lembrar de substituir esse também
      // substituindo #c2dceb por #ebc2dd
    },
  },
})

class ApplicationStyle extends React.Component {
  render() {
    return (
      <div>
        <CssBaseline>
          <MuiThemeProvider theme={theme}>
            <ApplicationRouter />
          </MuiThemeProvider>
        </CssBaseline>
      </div>
    )
  }
}

export default ApplicationStyle
