import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Avatar, Button, Dialog, DialogActions, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, Tooltip } from '@material-ui/core'

import { chatSuggestions } from '../../constants/chat'
import { styles } from '../../styles/js/dialog'

const DialogSuporteComponent = (props) => {

  const {
    classes,
    handleClose,
    dialogOpen,
    handleSelectedSuggestion,
  } = props

  return (
    <React.Fragment>
      <Dialog
        maxWidth={'sm'}
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="dialogTitle"
        scroll="body"
        PaperProps={{
          className: classes.dialog
        }}
      >
        <DialogTitle id="dialogTitle">
          Selecione uma mensagem pronta
        </DialogTitle>
        
        <List>
          {chatSuggestions.map(suggestion => (
            <Tooltip title={suggestion.mensagem} enterDelay={300}>
              <ListItem button onClick={() => handleSelectedSuggestion(suggestion)} key={suggestion.id}>
                <ListItemAvatar>
                  <Avatar style={suggestion.avatarStyle}>
                    {suggestion.icone}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={suggestion.titulo} />
              </ListItem>
            </Tooltip>
          ))}
        </List>

        <DialogActions>
          <Button onClick={handleClose} style={{ color: '#c62334' }}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default withStyles(styles)(DialogSuporteComponent)