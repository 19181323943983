import React, { useState } from 'react'
import { withRouter } from "react-router-dom"

import { Grid } from '@material-ui/core'

import { cidades } from '../constants/cidades'

import SearchFormCidades from '../components/SearchForms/SearchFormCidades'
import TableCidades from '../components/Tables/TableCidades'

const Cidades = (props) => {
  const { 
    populateLookupField,
    withSelect,
    setSelection
  } = props

  const [pagination, setPagination] = useState({ currentPage: 0, pageSize: 400 })
  const [cidadesState, setCidadesState] = useState(cidades)

  const filter = (searchFormState) => {
    if (searchFormState.nome) {
      const filteredCidades = cidades.rows.filter(cidade => cidade.nome.toLowerCase().indexOf(searchFormState.nome.toLowerCase()) > -1)
      setCidadesState({ rows: filteredCidades, count: 400 })
    }
  }

  const handleOnRowClick = (row) => {
    if (populateLookupField && !withSelect) {
      populateLookupField(row)
    } else {
      return
    }
  }

  return (
    <div>
      <Grid container>
        <SearchFormCidades
          filter={filter}
        />

        <TableCidades
          rows={cidadesState.rows === undefined ? [] : cidadesState.rows}
          rowsLength={cidadesState.count}
          onRowClick={handleOnRowClick}
          pagination={pagination}
          setPagination={setPagination}
          withSelect={withSelect}
          setSelection={setSelection}
          withHover={withSelect ? false : true}
        />
      </Grid>
    </div>
  )
}

export default withRouter(Cidades)