export const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
  },

  textField: {
    width: 'calc(100% - 30px)',
    marginLeft: '15px',
    marginRight: '15px',
  },

  paper: {
    padding: '10px 10px 20px 10px',
    position: 'relative',
    border: '1px solid #ddd',
    borderRadius: 0,
    marginTop: 10,
    marginBottom: 48,
    width: '100%',
  },

  formSection: {
    border: '1px solid #DDDDDD',
    position: 'relative',
    marginTop: '5px',
    paddingBottom: '10px',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },

  formSectionLabel: {
    position: 'absolute',
    fontSize: '9pt',
    right: '10px',
    top: '-7px',
    backgroundColor: 'white',
    paddingLeft: '10px',
    paddingRight: '10px',
    color: '#AAAAAA',
  },

  filterButton: {
    width: 110,
    position: 'absolute',
    bottom: -56,
    right: 10,
    zIndex: '0',
    backgroundColor: '#0075bc',
    color: 'white',
    boxShadow: 'none',
    borderRadius: '0px 0px 10px 10px',
    '&:hover': {
      background: '#1b6494',
    },
  },

  clearButton: {
    width: 110,
    position: 'absolute',
    bottom: -56,
    right: 130,
    zIndex: '0',
    backgroundColor: '#bc7a00',
    color: 'white',
    boxShadow: 'none',
    borderRadius: '0px 0px 10px 10px',
    '&:hover': {
      background: '#a56b00',
    },
  },

  formControlLabel: {
    paddingTop: 20,
    marginLeft: '15px',
    paddingBottom: 10,
  },
})
