export const styles = theme => ({
  list: {
    overflowY: 'auto',
  },

  logoRPCDiv: {
    backgroundColor: '#006497',
  },

  logoRPC: {
    width: '50%',
    margin: '0 auto',
    display: 'table',
  },

  logoTitle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    position: 'relative',
    bottom: 17.5,
  },

  userButton: {
    width: '100%',
  },

  userImage: {
    width: 50,
    borderRadius: '100%',
  },

  userName: {
    color: 'white',
    marginLeft: 5,
    marginRight: 5,
  },

  userMenuPaper: {
    position: 'absolute',
    marginTop: 60,
    width: 200,
  },

  reactRouterLink: {
    textDecoration: 'none',
    color: '#333',
  },
})
