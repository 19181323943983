export const styles = theme => ({
  notesSection: {
    display: 'table-row',
    backgroundColor: '#ebebeb',
    border: '1px solid #ddd',
    borderRadius: 0,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      height: 500,
    },
  },
  
  notesSectionHeader: {
    padding: 10,
    paddingLeft: 16,
    height: 50,
    backgroundColor: '#ebebeb',
  },

  notesContent: {
    height: 'calc(100% - 180px)',
    overflowY: 'auto',
  },
  
  notesBalloon: {
    position: 'relative',
    backgroundColor: '#ffffcc',
    margin: 16,
    padding: 8,
    marginTop: 0,
    marginBottom: 20,
    paddingBottom: 30,
    marginRight: 20,
    borderRadius: '0px 0px 5px 5px',
  },


  notesBalloonEditIcon: {
    position: 'absolute',
    bottom: '-15px',
    right: '-15px',
    backgroundColor: '#ffffcc',
    border: '4px solid #ebebeb',
    '&:hover': {
      backgroundColor: '#ededbd'
    }
  },

  notesBalloonAuthor: {
    textAlign: 'right',
    marginRight: 50,
  },
  
  notesBalloonAudit: {
    position: 'absolute', 
    bottom: 10, 
    left: 10, 
    fontSize: '9pt', 
    color: '#555',
    marginRight: 30,
  },

  notesFooter: {
    height: 130,
    backgroundColor: '#ddd',
    paddingLeft: 20,
    paddingRight: 20,
  },

});