export const styles = theme => ({
  searchButton: {
    width: '100%',
    borderRadius: 0,
    border: '1px solid #ddd',
    boxShadow: 'none',
    marginTop: 10,
  },

  previousPageButton: {
    width: '100%',
    borderRadius: 0,
    border: '1px solid #ddd',
    boxShadow: 'none',
    marginTop: 10,
  },

  newButton: {
    width: '100%',
    borderRadius: 0,
    boxShadow: 'none',
    marginTop: 10,
    backgroundColor: '#26C1C3',
    '&:hover': {
      backgroundColor: '#1e9c9e',
    },
  },

  changeButton: {
    width: '100%',
    borderRadius: 0,
    border: '1px solid #ddd',
    boxShadow: 'none',
    marginTop: 10,
  },

  deleteButton: {
    width: '100%',
    borderRadius: 0,
    border: '1px solid #ddd',
    boxShadow: 'none',
    marginTop: 10,
    backgroundColor: '#b46a6a',
    '&:hover': {
      backgroundColor: '#6c3f3f',
    },
  },

  title: {
    marginTop: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#333',
    fontWeight: 400,
    [theme.breakpoints.up('xs')]: {
      marginLeft: 10,
      marginRight: 10,
    },
  },
})
