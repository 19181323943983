import React, { useState, useEffect, useContext } from 'react'

import { Button, Grid } from '@material-ui/core'
import CompareArrowsIcon from '@material-ui/icons/CompareArrows'
import { styles } from '../../styles/js/tableSection'
import { withStyles } from '@material-ui/core/styles'
import AdvancedTable from '../AdvancedTable.jsx'
import { setLocalStorage } from '../../helpers/table'
import { getTableInboxDefinitions } from '../../constants/tablesDefinitions'
import DialogResponsavel from '../../containers/DialogResponsavel.jsx'
import { ApplicationContext } from '../../ContextProvider.jsx'
import * as usuarios from '../../constants/usuarios'

import { READ_ONLY_MODE } from '../../constants/api.js'

const TableInbox = (props) => {
  const { user } = useContext(ApplicationContext)

  const {
    classes,
    rows, 
    rowsLength, 
    statusRequestTickets,
    pagination,
    setPagination,
    onRowClick,
    fetchTickets,
  } = props

  const tableDefinitions = getTableInboxDefinitions(pagination)

  const [tableStates, setTableStates] = useState(
    { ...tableDefinitions, rows }
  )

  const [dialogOpen, setDialogOpen] = useState(false)

  const changePersonInCharge = row => {
    setTableStates({ ...tableStates, row: row })
    setDialogOpen(true)
  }

  useEffect(() => {
    setTableStates({ ...tableStates, rows: rows, rowsLength: rowsLength })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows])

  useEffect(() => {
    setLocalStorage('pageSizeInbox', pagination.pageSize)
  }, [pagination.pageSize])

  useEffect(() => {
    setLocalStorage('columnOrderInbox', tableStates.columnOrder)
    setLocalStorage('columnWidthsInbox', tableStates.columnWidths)
  }, [tableStates.columnOrder, tableStates.columnWidths])

  return (
    <>
      <Grid container>
        <Button
          onClick={() => changePersonInCharge('')}
          className={classes.button} 
          variant="contained" 
          color="primary"
          disabled={
            !tableStates.selection.length || 
            user.usuario_crc !== usuarios.CRC || READ_ONLY_MODE
          }
          >

          <CompareArrowsIcon 
            className={classes.buttonIcon} 
          /> 
          Definir Responsável ({tableStates.selection.length})
        </Button>
      </Grid>

      <br/>
      <AdvancedTable
        tableStates={tableStates}
        setTableStates={setTableStates}
        onRowClick={({ row }) => onRowClick(row)}
        changePersonInCharge={row => changePersonInCharge(row)}
        totalCount={rowsLength}
        pagination={pagination}
        setPagination={setPagination}
        statusRequest={statusRequestTickets}
        user={user}
        showSelect
      />

      <DialogResponsavel
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        fetchTickets={fetchTickets}
        rows={rows}
        selectedRow={tableStates.row}
        selectedRows={tableStates.selection}
        eraseSelectedRows={() => 
          setTableStates({ ...tableStates, selection: [] })
        }
      />
    </>
  )
}

export default withStyles(styles)(TableInbox)
