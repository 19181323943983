import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { styles } from '../styles/js/actions'

const HeaderActions = (props) => {
  const { 
    classes, 
    searchFormOpenClick, 
    searchFormOpenTitle,
    setDialogOpen,
    createButtonTitle,
    hideSearchButton,
  } = props

  return (
    <Grid container spacing={1} justify='flex-end'>
      <Grid item xs={12} sm={4}>
        {!hideSearchButton && 
        <Button color='primary' variant="contained" className={classes.searchButton} onClick={searchFormOpenClick}>
          {searchFormOpenTitle}
        </Button>
        }
      </Grid>

      <Grid item xs={12} sm={4}>
        <Button 
          color='primary' 
          variant="contained" 
          className={classes.newButton} 
          onClick={() => setDialogOpen('create')}
        >
          {createButtonTitle}
        </Button>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(HeaderActions)