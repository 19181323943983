import * as React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import { styles } from '../styles/js/loading'

const Loading = (props) => {
  const { classes } = props
  return (
    <div className={classes.loadingShading}>
      <CircularProgress size={60} className={classes.loadingIcon} />
    </div>
  )
}

export default withStyles(styles)(Loading)
