export const styles = theme => ({
  paper: {
    padding: 24,
    marginBottom: 16,
  },

  viewerMessage: {
    width: '100%',
    padding: 10,
    borderRadius: '0px 10px 10px 10px',
    marginBottom: 8,
    // paddingBottom: 30,
    // backgroundColor: '#eee',
  },

  userMessage: {
    width: '75%',
    padding: 10,
    borderRadius: '10px 10px 0px 10px',
    marginBottom: 20,
    paddingBottom: 30,
    backgroundColor: '#c2dceb',
  },

  viewerName: {
    fontSize: '1rem',
  },

  seeMore: {
    fontSize: '1rem',
  },

  link: {
    textDecoration: 'none',
    color: '#006497',
  },
})
