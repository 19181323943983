import React, { useState, useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import AllInboxIcon from '@material-ui/icons/AllInboxOutlined'
// import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ChatIcon from '@material-ui/icons/Chat'
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined'
import PersonIcon from '@material-ui/icons/PersonOutlined'
import MailIcon from '@material-ui/icons/Mail'
import Typography from '@material-ui/core/Typography'

import { withStyles } from '@material-ui/core/styles'
import { styles } from '../styles/js/drawerContent'
import { ApplicationContext } from '../ContextProvider.jsx'
import { logout } from '../helpers/authenticator'
import * as usuarios from '../constants/usuarios'

import DialogTicket from '../containers/DialogTicket.jsx'

function DrawerContent(props) {
  const [dialogTicketOpen, setDialogTicketOpen] = useState(false)
  const [userMenu, setUserMenu] = useState(null)

  const { user, ticketCreated, setTicketCreated } = useContext(ApplicationContext)

  const handleMenuClick = event => {
    setUserMenu(event.currentTarget)
  }

  const handleMenuClose = () => {
    setUserMenu(null)
  }

  const { classes } = props

  const path = props.match.path

  const drawerContent = (
    <div className={classes.list}>
      <div
        tabIndex={0}
        role="button"
        onClick={props.toggleDrawer ? props.toggleDrawer(false) : () => ''}
        onKeyDown={props.toggleDrawer ? props.toggleDrawer(false) : () => ''}
      >
        <List>
          <Link className={classes.reactRouterLink} to={'/'}>
            <ListItem button key='caixa-de-entrada' style={{ backgroundColor: path === '/' ? '#eee' : '' }}>
                <ListItemIcon>
                  {/* <Badge badgeContent={user.usuario_crc === usuarios.CRC ? 99 : ''} color="primary"> */}
                    <AllInboxIcon />
                  {/* </Badge> */}
                </ListItemIcon>
                <ListItemText primary='Caixa de Entrada' />
            </ListItem>
          </Link>

          <Link className={classes.reactRouterLink} to="/atendimentos">
            <ListItem button key='' style={{ backgroundColor: path === '/atendimentos' || path === '/chat/:id' ? '#eee' : '' }}>
                <ListItemIcon>
                  {/* <Badge badgeContent={99} color="secondary"> */}
                    <MailIcon />
                  {/* </Badge> */}
                </ListItemIcon>
                <ListItemText primary='Atendimentos' />
            </ListItem>
          </Link>

          <Link className={classes.reactRouterLink} to={'/telespectadores'}>
            <ListItem button key='telespectadores' style={{ backgroundColor: path === '/telespectadores' ? '#eee' : '' }}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary='Telespectadores' />
            </ListItem>
          </Link>

          <ListItem 
            button 
            key='ticket' 
            style={{ backgroundColor: path === '/ticket' ? '#eee' : '' }} 
            disabled={
              user.usuario_crc !== usuarios.CRC
            }
            onClick={() => setDialogTicketOpen('create')}>
            <ListItemIcon>
              <ChatIcon />
            </ListItemIcon>
            <ListItemText primary='Criar Atendimento' />
          </ListItem>

          <ListItem 
            button 
            key='ticketApp' 
            style={{ backgroundColor: path === '/ticket' ? '#eee' : '' }} 
            disabled={
              user.usuario_crc === usuarios.SUPORTE || 
              user.usuario_crc === usuarios.SISGB ||
              user.usuario_crc === usuarios.CONVIDADO
            }
            onClick={() => setDialogTicketOpen('createApp')}>
            <ListItemIcon>
              <ChatOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary='Falar c/ Telespectador (APP)' />
          </ListItem>

          <Link className={classes.reactRouterLink} to={'/mensagens'}>
            <ListItem 
              button 
              key='mensagens' 
              disabled={user.usuario_crc !== usuarios.CONVIDADO} 
              style={{ backgroundColor: path === '/mensagens' ? '#eee' : '' }}>
                <ListItemIcon>
                  <ChatOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary='Mensagens' />
            </ListItem>
          </Link>
        </List>
      </div>
    </div>
  )

  return (
    <>
      <div className={classes.logoRPCDiv}>
        <Link to={'/'} style={{ textDecoration: 'none' }}>
          <img className={classes.logoRPC} src="/rpc-logo-fundo-escuro.png" alt="Logo RPC" />

          <Typography className={classes.logoTitle} component="h1" variant="h6">
            Dashboard CRC
          </Typography>
        </Link>


          <Button
            aria-owns={userMenu ? 'userMenu' : undefined}
            aria-haspopup="true"
            onClick={handleMenuClick}
            className={classes.userButton}
          >
            <Grid item xs={12}>
              <p className={classes.userName}>
                {user.nome}
              </p>
            </Grid>
          </Button>
          <Menu
            id="userMenu"
            anchorEl={userMenu}
            open={Boolean(userMenu)}
            onClose={handleMenuClose} 
            PaperProps={{
              className: classes.userMenuPaper,
            }}
          >
            <MenuItem onClick={logout}>Sair</MenuItem>
          </Menu>
      </div>
      {drawerContent}

      <DialogTicket
        dialogOpen={dialogTicketOpen}
        setDialogOpen={setDialogTicketOpen}
        fetchTickets={() => setTicketCreated(!ticketCreated)}
        handleTicketDialogReset={() => {}}
      />
    </>
  )
}
export default withStyles(styles)(withRouter(DrawerContent))