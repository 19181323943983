export const styles = theme => ({
  root: {
    position: 'relative',
  },

  chatPaper: {
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 100px)',
    border: '1px solid #ddd',
    borderRadius: 0,
    boxShadow: 'none',
  },

  chatHeader: {
    position: 'relative',
    height: 70,
    padding: 20,
    paddingTop: 10,
    zIndex: 2,
    borderRadius: 0,
  },

  chatHeaderTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14pt',
  },

  chatHeaderSubtitle: {
    fontWeight: 400,
  },

  chatBody: {
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: 20,
    height: 'calc(100% - 70px)',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 50,
      paddingRight: 50,
      height: 'calc(100% - 300px)',
    },
  },

  chatGuestBalloon: {
    position: 'relative',
    padding: 10,
    width: '75%',
    float: 'left',
    backgroundColor: '#eee',
    borderRadius: '0px 10px 10px 10px',
    marginBottom: 20,
    paddingBottom: 30,
  },

  chatGuestBalloonEditIcon: {
    position: 'absolute',
    bottom: '-15px',
    right: '-15px',
    backgroundColor: '#eee',
    border: '3px solid white',
    '&:hover': {
      backgroundColor: '#ddd',
    },
  },

  chatUserBalloonEditIcon: {
    position: 'absolute',
    bottom: '-15px',
    right: '-15px',
    backgroundColor: '#c2dceb',
    border: '3px solid white',
    '&:hover': {
      backgroundColor: '#a8c7da',
    },
  },

  chatUserBalloon: {
    position: 'relative',
    padding: 10,
    width: '75%',
    float: 'right',
    backgroundColor: '#c2dceb',
    borderRadius: '10px 10px 0px 10px',
    marginBottom: 20,
    paddingBottom: 30,
  },

  chatBalloonTime: {
    fontSize: '10pt',
    marginBottom: 8,
  },

  chatUserBalloonText: {
    color: 'black',
  },

  chatBalloonAudit: {
    position: 'absolute',
    bottom: 10,
    left: 10,
    fontSize: '9pt',
    color: '#555',
  },

  inputSection: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      bottom: 20,
      width: '90%',
      margin: '0 auto',
      right: 0,
      left: 0,
    },
  },

  chatFooter: {
    height: 130,
    backgroundColor: '#eee',
    paddingLeft: 20,
    paddingRight: 20,
  },

  newViewerMessage: {
    border: '1px solid #fff',
    position: 'relative',
    padding: 16,
    width: '95%',
    float: 'left',
    backgroundColor: '#eee',
    borderRadius: '10px 10px 10px 10px',
    paddingBottom: 45,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 20,
    },
  },

  newUserMessage: {
    border: '1px solid #fff',
    position: 'relative',
    padding: 16,
    width: '95%',
    float: 'right',
    backgroundColor: '#c2dceb',
    borderRadius: '10px 10px 10px 10px',
    paddingBottom: 45,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
})
