import React, { useState, useEffect, useContext } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { LinearProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField'
import DatePickerComponent from '../DatePickerComponent.jsx'

import DialogLookup from './DialogLookup'
import Telespectadores from '../../containers/Telespectadores'
import Usuarios from '../../containers/Usuarios'
import { ApplicationContext } from '../../ContextProvider.jsx'

import { statusesCreate, statusesEdit } from '../../constants/selectOptions'
import { styles } from '../../styles/js/dialog'
import * as usuarios from '../../constants/usuarios'

function DialogTicketComponent(props) {
  const { user } = useContext(ApplicationContext)

  const [isDialogTelespectadoresLookupOpen, setIsDialogTelespectadoresLookupOpen] = useState(false)
  const [isDialogTelespectadoresAppLookupOpen, setIsDialogTelespectadoresAppLookupOpen] = useState(false)
  const [isDialogUsuariosLookupOpen, setIsDialogUsuariosLookupOpen] = useState(false)
  
  const {
    classes,
    ticket,
    setTicket,
    regioes,
    categorias,
    origens,
    origensWithoutApp,
    handleAction,
    handleClose,
    dialogOpen,
    statusRequest,
  } = props

  const {
    dataContatoInicio,
    dataContatoFim,
    origem,
    categoria,
    praca,
    status,
    selectedTelespectador,
    selectedResponsavel,
    telespectador,
    responsavel,
    mensagemRecebida,
    mensagemEnviada,
  } = props.ticket

  useEffect(() => {
    if (dialogOpen === 'createApp') {
      setTicket({
        ...ticket,
        selectedResponsavel: user,
        responsavel: user.nome,
        origem: 1,
        status: 2,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen])

  useEffect(() => {
    if (ticket.status !== 1 && !!dialogOpen) {
      setTicket({
        ...ticket,
        selectedResponsavel: user,
        responsavel: user.nome,
      })
    }

    if (ticket.status === 1) {
      setTicket({
        ...ticket,
        selectedResponsavel: '',
        responsavel: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket.status])

  useEffect(() => {
    if (ticket.origem === 1) {
      setTicket({ ...ticket, mensagemEnviada: '', dataContatoFim: null })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket.origem])

  const handleCreate = () => {
    handleAction()
  }

  const handleChange = event => {
    setTicket({ ...ticket, [event.target.id]: event.target.value })
  }

  return (
    <React.Fragment>
      <Dialog
        maxWidth='md'
        fullWidth={true}
        open={!!dialogOpen}
        onClose={handleClose}
        aria-labelledby="dialogTitle"
        scroll="body"
        PaperProps={{
          className: classes.dialog
        }}
      >
        <DialogTitle id="dialogTitle">
          {dialogOpen === 'create' && 'Novo Atendimento'}
          {dialogOpen === 'edit' && 'Editar Atendimento'}
          {dialogOpen === 'createApp' && 'Falar com Telespectador'}
        </DialogTitle>
        
        {statusRequest ?
          <DialogContent className={classes.dialogContent}>
            <Grid container spacing={2}>
              {(dialogOpen === 'create') &&
                <Grid item xs={12} sm={(ticket.status && ticket.status !== 1) ? 6 : 12}>
                  <Button 
                    color={selectedTelespectador ? 'primary' : 'secondary'} 
                    style={{ width: '100%' }}
                    onClick={() => setIsDialogTelespectadoresLookupOpen(true)}
                    >
                    {selectedTelespectador ?
                      'Telespectador: ' + telespectador
                      :
                      'Selecionar Telespectador'
                    }
                  </Button>
                </Grid>
              }

              {(dialogOpen === 'createApp') &&
                <Grid item xs={12} sm={6}>
                  <Button 
                    color={selectedTelespectador ? 'primary' : 'secondary'} 
                    style={{ width: '100%' }}
                    onClick={() => setIsDialogTelespectadoresAppLookupOpen(true)}
                    >
                    {selectedTelespectador ?
                      'Telespectador APP: ' + telespectador
                      :
                      'Selecionar Telespectador APP'
                    }
                  </Button>
                </Grid>
              }

              {((dialogOpen === 'create' && (ticket.status && ticket.status !== 1)) || dialogOpen === 'createApp') &&
                <Grid item xs={12} sm={6}>
                  <Button 
                    color={selectedResponsavel ? 'primary' : 'secondary'} 
                    style={{ width: '100%' }}
                    onClick={() => setIsDialogUsuariosLookupOpen(true)}
                    disabled={
                      user.usuario_crc === usuarios.CONTEUDO ||
                      user.usuario_crc ===  usuarios.CONVIDADO
                    }
                    >
                    {selectedResponsavel ?
                      'Responsável: ' + responsavel
                      :
                      'Selecionar Responsável'
                    }
                  </Button>
                </Grid>
              }

              <Grid item xs={12} sm={6}>
                <TextField
                  id="praca"
                  name="praca"
                  select
                  label="Praça"
                  className={classes.textField}
                  value={praca}
                  onChange={(event) => handleChange({ target: { id: 'praca', value: event.target.value } })}
                  margin="normal"
                  fullWidth
                >
                  {regioes.map(option => (
                    <MenuItem key={option.id} id="praca" value={option.id}>
                      {option.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              
              {dialogOpen !== 'createApp' &&
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="status"
                    select
                    label="Status do Atendimento"
                    className={classes.textField}
                    value={status}
                    onChange={(event) => handleChange({ target: { id: 'status', value: event.target.value } })}
                    margin="normal"
                    fullWidth
                  >
                    {(dialogOpen === 'create' ? statusesCreate : statusesEdit).map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              }

              {(dialogOpen === 'create' || (dialogOpen === 'edit' && ticket.origem !== 1)) &&
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="origem"
                    name="origem"
                    select
                    label="Origem"
                    className={classes.textField}
                    value={origem}
                    onChange={(event) => handleChange({ target: { id: 'origem', value: event.target.value } })}
                    margin="normal"
                    fullWidth
                  >
                    {(dialogOpen === 'create' ? origens : origensWithoutApp).map(option => (
                      <MenuItem key={option.id} id="origem" value={option.id}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              }

              <Grid item xs={12} sm={6}>
                <TextField
                  id="categoria"
                  name="categoria"
                  select
                  label="Categoria"
                  className={classes.textField}
                  value={categoria}
                  onChange={(event) => handleChange({ target: { id: 'categoria', value: event.target.value } })}
                  margin="normal"
                  fullWidth
                >
                  {categorias.map(option => (
                    <MenuItem key={option.id} id="categoria" value={option.id}>
                      {option.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            
            {(dialogOpen === 'create' || dialogOpen === 'createApp') &&
              <div>
                <Grid container justify="center" spacing={2}>
                  <Grid item xs={6}>
                    {dialogOpen !== 'createApp' &&
                      <TextField
                        id="mensagemRecebida"
                        label="Mensagem Recebida"
                        multiline
                        rows="5"
                        value={mensagemRecebida}
                        onChange={handleChange}
                        className={classes.textField}
                        margin="normal"
                        fullWidth
                      />
                    }
                  </Grid>

                  <Grid item xs={6}>
                    {(ticket.origem !== 1 || dialogOpen === 'createApp') &&
                      <TextField
                        id="mensagemEnviada"
                        label="Mensagem Enviada"
                        multiline
                        rows="5"
                        value={mensagemEnviada}
                        onChange={handleChange}
                        className={classes.textField}
                        margin="normal"
                        fullWidth
                      />
                    }
                  </Grid>
                </Grid>

                <Grid container justify="center" spacing={2}>
                  <Grid item xs={6}>
                    {dialogOpen !== 'createApp' &&
                      <DatePickerComponent
                        onChange={value => handleChange({ target: { id: 'dataContatoInicio', value: value } })}
                        value={dataContatoInicio}
                        id="dataContatoInicio"
                        label="Data da Mensagem Recebida"
                        disablePast={false}
                        disableFuture={false}
                        withTime
                        fullWidth
                      />
                    }
                  </Grid>

                  <Grid item xs={6}>
                    {(ticket.origem !== 1 && dialogOpen !== 'createApp') &&
                      <DatePickerComponent
                        onChange={value => handleChange({ target: { id: 'dataContatoFim', value: value } })}
                        value={dataContatoFim}
                        id="dataContatoFim"
                        label="Data da Mensagem Enviada"
                        disablePast={false}
                        disableFuture={false}
                        withTime
                        fullWidth
                      />
                    }
                  </Grid>
                </Grid>
              </div>
            }
          </DialogContent>
        :
          <LinearProgress />
        }

        <DialogActions>
          <Button onClick={handleClose} style={{ color: '#c62334' }}>
            Cancelar
          </Button>
          <Button onClick={handleCreate} style={{ color: '#009d93' }}>
            {dialogOpen === 'create' && 'Criar Atendimento'}
            {dialogOpen === 'createApp' && 'Falar com Telespectador'}
            {dialogOpen === 'edit' && 'Editar Atendimento'}
          </Button>
        </DialogActions>
      </Dialog>

      <DialogLookup 
        Component={Telespectadores}
        title={'Telespectadores'}
        size='lg'
        isOpen={isDialogTelespectadoresLookupOpen}
        setIsOpen={setIsDialogTelespectadoresLookupOpen}
        setLookupField={row => setTicket({ 
          ...ticket, 
          selectedTelespectador: row, 
          telespectador: row.nome,
          praca: row.regiao ? row.regiao.id : ticket.praca,
        })}
      />

      <DialogLookup 
        Component={Telespectadores}
        title={'Telespectadores APP'}
        size='lg'
        isOpen={isDialogTelespectadoresAppLookupOpen}
        setIsOpen={setIsDialogTelespectadoresAppLookupOpen}
        setLookupField={row => setTicket({ 
          ...ticket, 
          selectedTelespectador: row, 
          telespectador: row.nome,
          praca: row.regiao ? row.regiao.id : ticket.praca,
        })}
        telespectadoresApp
      />

      <DialogLookup 
        Component={Usuarios}
        title={'Usuários'}
        size='md'
        isOpen={isDialogUsuariosLookupOpen}
        setIsOpen={setIsDialogUsuariosLookupOpen}
        setLookupField={row => setTicket({ 
          ...ticket, 
          selectedResponsavel: row, 
          responsavel: row.nome,
        })}
        lookupFilter={{ usuarioCrc: 1 }}
      />
    </React.Fragment>
  )
}

export default withStyles(styles)(DialogTicketComponent)