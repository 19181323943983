import React from 'react'
import MaskedInput from 'react-text-mask'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import SearchIcon from '@material-ui/icons/Search'
import { withStyles } from '@material-ui/core/styles'

import { styles } from '../styles/js/maskedInputComponent'

const PhoneField = (functionProps) => {
  const { inputRef, ...other } = functionProps

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  )
}

const ZipCodeField = (functionProps) => {
  const { inputRef, ...other } = functionProps

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  )
}

const MaskedInputComponent = (props) => {
  let container = props.classes.container
  if (props.fromDialog) {
    container = props.classes.containerDialog
  }

  let inputComponentFunction
  if (props.phoneField) {
    inputComponentFunction = PhoneField
  } else if (props.zipCodeField) {
    inputComponentFunction = ZipCodeField
  }

  return (
    <div className={container}>
      <FormControl 
        className={props.classes.formControl} 
        margin="normal">
        <InputLabel htmlFor={props.id}>
          {props.label}
        </InputLabel>
        <Input
          fullWidth
          disabled={props.disabled}
          value={props.value}
          onChange={props.handleParentState}
          id={props.id}
          inputComponent={inputComponentFunction}
          endAdornment= {props.zipCodeField ? (
            <InputAdornment position="end">
              <IconButton onClick={() => props.autocompleteWithZipCode()} disabled={props.disabled}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ) : ''}
        />
      </FormControl>
    </div>
  )
}

export default withStyles(styles)(MaskedInputComponent)