import axios, { CancelToken } from 'axios'
import { useState, useEffect, useRef } from 'react'

import { API_BASE_URL } from './constants/api'

export const useRequest = (initialData, requestOptions, transform = null, events = {}) => {
  const cancelRequestRef = useRef()

  const [requestState, setRequestState] = useState({
    data: initialData,
    statusRequest: {
      done: false,
      isLoading: false,
      success: false,
      error: false,
    }
  })

  const { token } = JSON.parse(window.localStorage.getItem('tokenUsuario'))

  const buildRequest = (options) => {
    if (typeof options === 'string') {
      return {
        url: API_BASE_URL + options,
        method: 'get',
        headers: { 'Authorization': `Bearer ${token}` },
        data: ''
      }
    }
    return {
      url: API_BASE_URL + options.url,
      method: options.method,
      headers: { 'Authorization': `Bearer ${token}` },
      data: options.data,
      params: options.params,
    }
  }

  const buildFetch = async (force = false) => {
    if (!requestState.statusRequest.isLoading || force) {
      setRequestState({
        data: requestState.data,
        statusRequest: {
          done: false,
          isLoading: true,
          success: false,
          error: false
        }
      })
      const { method, url, headers, data, params } = buildRequest(requestOptions)
      try {
        const result = await axios({
          method,
          url,
          headers,
          data,
          params,
          cancelToken: new CancelToken(function executor(c) {
            cancelRequestRef.current = c;
          })
        })

        setRequestState({
          data: transform ? transform(result.data) : result.data,
          statusRequest: {
            done: true,
            isLoading: false,
            success: true,
            error: false
          }
        })

        if (events.onComplete) {
          events.onComplete(transform ? transform(result.data) : result.data)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          setRequestState({
            data: requestState.data,
            statusRequest: {
              done: true,
              isLoading: false,
              success: false,
              error: true
            }
          })
          if (events.onError) {
            events.onError(error)
          }
        }
      }
    }
  }

  const fetchRef = useRef(buildFetch)

  useEffect(() => {
    fetchRef.current = buildFetch
  }, [buildFetch])

  const fetch = () => {
    if (requestOptions.cancelRequest) {
      if (cancelRequestRef.current) {
        cancelRequestRef.current()
      }
      fetchRef.current(true)
    } else {
      fetchRef.current()
    }
  }

  const clearData = () => {
    if (requestOptions.cancelRequest && cancelRequestRef.current) {
      cancelRequestRef.current()
    }

    setRequestState({
      data: initialData,
      statusRequest: {
        done: false,
        isLoading: false,
        success: false,
        error: false,
      }
    })
  }

  return [requestState.data, requestState.statusRequest, fetch, clearData]
}

export default {
  useRequest
}