export const styles = theme => ({
  root: {
    position: 'relative',
    borderRadius: 0,
    border: '1px solid #ccc',
    minWidth: 0,
    marginTop: 20,
  },
  container: {
    position: 'relative',
    width: '100%',
  },
  inputRoot: {
    width: '100%',
  },
  cell: {
    padding: 0,
  },
  selectedRows: {
    fontSize: '11pt',
    position: 'absolute',
    top: -25,
    left: 0,
  },
  detailsMenu: {
    position: 'relative',
    width: 250,
    backgroundColor: '#f5f5f5',
    padding: 10,
  },
})
