import amber from '@material-ui/core/colors/amber'

export const styles = theme => ({
  success: {
    backgroundColor: '#6bb46a',
  },
  error: {
    backgroundColor: '#b46a6a',
  },
  info: {
    backgroundColor: '#6a96b4',
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconType: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});