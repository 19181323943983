import React, { useState, useEffect } from 'react'
import { Grid, Paper, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { usuariosSearchFormFields } from '../../constants/initialConstants'
import { styles } from '../../styles/js/searchForm'
import 'moment/locale/pt-br'

function SearchFormUsuarios(props) {
  const [searchFormState, setSearchFormState] = useState(usuariosSearchFormFields)

  const { 
    classes,
    filter,
  } = props

  const {
    nome,
  } = searchFormState

  useEffect(() => {
    filter(searchFormState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFormState])

  const handleChange = event => {
    setSearchFormState({
      ...searchFormState,
      [event.target.id]: event.target.value
    })
  }

  return (
    <Paper className={classes.paper} elevation={0} style={{ marginBottom: 16 }}>
      <form className={classes.container} noValidate autoComplete="off">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={classes.formSection}>
              <span className={classes.formSectionLabel}>
                Geral
              </span>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    id="nome"
                    label="Nome da Cidade"
                    className={classes.textField}
                    value={nome}
                    onChange={handleChange}
                    margin="normal"
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </form>
    </Paper>
  )
}

export default withStyles(styles)(SearchFormUsuarios)
