import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { styles } from '../styles/js/httpRequestHandler'
import { Typography } from '@material-ui/core'

function HttpRequestHandler(props) {
  const { 
    classes,
    errorCode,
    errorTitle,
    errorDescription,
    redirectLink,
    redirectLinkText,
  } = props
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={10}>
        <Grid container spacing={8}>
          <Grid item md={5} xs={12}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              className={classes.httpIconCircle}
            >
              <Grid item>
                <Typography variant='h1' className={classes.httpIconTitle}>
                  {errorCode}
                </Typography>

                <Typography variant='h6' className={classes.httpIconSubtitle}>
                  {errorTitle}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={7}  xs={12}>
            <Grid 
              container 
              alignItems='center'
              justify='center'>
              <Grid item>
                <Typography variant='h3' className={classes.httpMessageTitle}>
                  Ooops!
                </Typography>
                <Typography variant='h6' className={classes.httpMessageText}>
                  {errorDescription}
                </Typography>

                <Link to={redirectLink} className={classes.httpMessageLink}>
                  <Typography variant='h6' className={classes.httpMessageLink}>
                    {redirectLinkText}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(HttpRequestHandler)