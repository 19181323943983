export const styles = theme => ({
  activeButton: {
    width: '100%',
    borderRadius: 0,
    boxShadow: 'none',
    marginTop: 10,
    backgroundColor: '#26C1C3',
    '&:hover': {
      backgroundColor: '#1e9c9e',
    },
  },

  button: {
    width: '100%',
    borderRadius: 0,
    boxShadow: 'none',
    marginTop: 10,
  },
})
