import * as React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import { styles } from '../styles/js/tableLoading'

const TableLoading = (props) => {
  const { classes } = props
  return (
    <div className={classes.loadingShading}>
      <CircularProgress className={classes.loadingIcon} />
    </div>
  )
}

export default withStyles(styles)(TableLoading)
