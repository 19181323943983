
  export const cidades = 
  {
    count: 399,
    rows:   [
      {
        "id" : 1,
        "id_ibge" : "4100103",
        "mnemonico_regiao" : "LON",
        "nome" : "Abatiá",
        "created_at" : "2019-08-09T20:49:37Z",
        "updated_at" : "2019-08-09T20:49:37Z"
      },
      {
        "id" : 2,
        "id_ibge" : "4100202",
        "mnemonico_regiao" : "CUR",
        "nome" : "Adrianópolis",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 3,
        "id_ibge" : "4100301",
        "mnemonico_regiao" : "CUR",
        "nome" : "Agudos do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 4,
        "id_ibge" : "4100400",
        "mnemonico_regiao" : "CUR",
        "nome" : "Almirante Tamandaré",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 5,
        "id_ibge" : "4100459",
        "mnemonico_regiao" : "GAV",
        "nome" : "Altamira do Paraná",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 6,
        "id_ibge" : "4100509",
        "mnemonico_regiao" : "PR3",
        "nome" : "Altônia",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 7,
        "id_ibge" : "4100608",
        "mnemonico_regiao" : "PR3",
        "nome" : "Alto Paraná",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 8,
        "id_ibge" : "4100707",
        "mnemonico_regiao" : "PR3",
        "nome" : "Alto Piquiri",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 9,
        "id_ibge" : "4100806",
        "mnemonico_regiao" : "LON",
        "nome" : "Alvorada do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 10,
        "id_ibge" : "4100905",
        "mnemonico_regiao" : "PR3",
        "nome" : "Amaporã",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 11,
        "id_ibge" : "4101002",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Ampére",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 12,
        "id_ibge" : "4101051",
        "mnemonico_regiao" : "CAV",
        "nome" : "Anahy",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 13,
        "id_ibge" : "4101101",
        "mnemonico_regiao" : "LON",
        "nome" : "Andirá",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 14,
        "id_ibge" : "4101150",
        "mnemonico_regiao" : "MAR",
        "nome" : "Ângulo",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 15,
        "id_ibge" : "4101200",
        "mnemonico_regiao" : "CUR",
        "nome" : "Antonina",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 16,
        "id_ibge" : "4101309",
        "mnemonico_regiao" : "PGR",
        "nome" : "Antônio Olinto",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 17,
        "id_ibge" : "4101408",
        "mnemonico_regiao" : "LON",
        "nome" : "Apucarana",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 18,
        "id_ibge" : "4101507",
        "mnemonico_regiao" : "LON",
        "nome" : "Arapongas",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 19,
        "id_ibge" : "4101606",
        "mnemonico_regiao" : "PGR",
        "nome" : "Arapoti",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 20,
        "id_ibge" : "4101655",
        "mnemonico_regiao" : "MAR",
        "nome" : "Arapuã",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 21,
        "id_ibge" : "4101705",
        "mnemonico_regiao" : "MAR",
        "nome" : "Araruna",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 22,
        "id_ibge" : "4101804",
        "mnemonico_regiao" : "CUR",
        "nome" : "Araucária",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 23,
        "id_ibge" : "4101853",
        "mnemonico_regiao" : "MAR",
        "nome" : "Ariranha do Ivaí",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 24,
        "id_ibge" : "4101903",
        "mnemonico_regiao" : "LON",
        "nome" : "Assaí",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 25,
        "id_ibge" : "4102000",
        "mnemonico_regiao" : "CAV",
        "nome" : "Assis Chateaubriand",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 26,
        "id_ibge" : "4102109",
        "mnemonico_regiao" : "MAR",
        "nome" : "Astorga",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 27,
        "id_ibge" : "4102208",
        "mnemonico_regiao" : "PR3",
        "nome" : "Atalaia",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 28,
        "id_ibge" : "4102307",
        "mnemonico_regiao" : "CUR",
        "nome" : "Balsa Nova",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 29,
        "id_ibge" : "4102406",
        "mnemonico_regiao" : "LON",
        "nome" : "Bandeirantes",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 30,
        "id_ibge" : "4102505",
        "mnemonico_regiao" : "MAR",
        "nome" : "Barbosa Ferraz",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 31,
        "id_ibge" : "4102604",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Barracão",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 32,
        "id_ibge" : "4102703",
        "mnemonico_regiao" : "LON",
        "nome" : "Barra do Jacaré",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 33,
        "id_ibge" : "4102752",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Bela Vista da Caroba",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 34,
        "id_ibge" : "4102802",
        "mnemonico_regiao" : "LON",
        "nome" : "Bela Vista do Paraíso",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 35,
        "id_ibge" : "4102901",
        "mnemonico_regiao" : "GAV",
        "nome" : "Bituruna",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 36,
        "id_ibge" : "4103008",
        "mnemonico_regiao" : "MAR",
        "nome" : "Boa Esperança",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 37,
        "id_ibge" : "4103024",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Boa Esperança do Iguaçu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 38,
        "id_ibge" : "4103040",
        "mnemonico_regiao" : "GAV",
        "nome" : "Boa Ventura de São Roque",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 39,
        "id_ibge" : "4103057",
        "mnemonico_regiao" : "CAV",
        "nome" : "Boa Vista da Aparecida",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 40,
        "id_ibge" : "4103107",
        "mnemonico_regiao" : "CUR",
        "nome" : "Bocaiúva do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 41,
        "id_ibge" : "4103156",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Bom Jesus do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 42,
        "id_ibge" : "4103206",
        "mnemonico_regiao" : "MAR",
        "nome" : "Bom Sucesso",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 43,
        "id_ibge" : "4103222",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Bom Sucesso do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 44,
        "id_ibge" : "4103305",
        "mnemonico_regiao" : "MAR",
        "nome" : "Borrazópolis",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 45,
        "id_ibge" : "4103354",
        "mnemonico_regiao" : "CAV",
        "nome" : "Braganey",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 46,
        "id_ibge" : "4103370",
        "mnemonico_regiao" : "PR3",
        "nome" : "Brasilândia do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 47,
        "id_ibge" : "4103404",
        "mnemonico_regiao" : "MAR",
        "nome" : "Cafeara",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 48,
        "id_ibge" : "4103453",
        "mnemonico_regiao" : "CAV",
        "nome" : "Cafelândia",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 49,
        "id_ibge" : "4103479",
        "mnemonico_regiao" : "PR3",
        "nome" : "Cafezal do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 50,
        "id_ibge" : "4103503",
        "mnemonico_regiao" : "LON",
        "nome" : "Califórnia",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 51,
        "id_ibge" : "4103602",
        "mnemonico_regiao" : "LON",
        "nome" : "Cambará",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 52,
        "id_ibge" : "4103701",
        "mnemonico_regiao" : "LON",
        "nome" : "Cambé",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 53,
        "id_ibge" : "4103800",
        "mnemonico_regiao" : "MAR",
        "nome" : "Cambira",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 54,
        "id_ibge" : "4103909",
        "mnemonico_regiao" : "GAV",
        "nome" : "Campina da Lagoa",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 55,
        "id_ibge" : "4103958",
        "mnemonico_regiao" : "GAV",
        "nome" : "Campina do Simão",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 56,
        "id_ibge" : "4104006",
        "mnemonico_regiao" : "CUR",
        "nome" : "Campina Grande do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 57,
        "id_ibge" : "4104055",
        "mnemonico_regiao" : "CAV",
        "nome" : "Campo Bonito",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 58,
        "id_ibge" : "4104105",
        "mnemonico_regiao" : "CUR",
        "nome" : "Campo do Tenente",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 59,
        "id_ibge" : "4104204",
        "mnemonico_regiao" : "CUR",
        "nome" : "Campo Largo",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 60,
        "id_ibge" : "4104253",
        "mnemonico_regiao" : "CUR",
        "nome" : "Campo Magro",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 61,
        "id_ibge" : "4104303",
        "mnemonico_regiao" : "MAR",
        "nome" : "Campo Mourão",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 62,
        "id_ibge" : "4104402",
        "mnemonico_regiao" : "GAV",
        "nome" : "Cândido de Abreu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 63,
        "id_ibge" : "4104428",
        "mnemonico_regiao" : "GAV",
        "nome" : "Candói",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 64,
        "id_ibge" : "4104451",
        "mnemonico_regiao" : "GAV",
        "nome" : "Cantagalo",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 65,
        "id_ibge" : "4104501",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Capanema",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 66,
        "id_ibge" : "4104600",
        "mnemonico_regiao" : "CAV",
        "nome" : "Capitão Leônidas Marques",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 67,
        "id_ibge" : "4104659",
        "mnemonico_regiao" : "PGR",
        "nome" : "Carambeí",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 68,
        "id_ibge" : "4104709",
        "mnemonico_regiao" : "LON",
        "nome" : "Carlópolis",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 69,
        "id_ibge" : "4104808",
        "mnemonico_regiao" : "CAV",
        "nome" : "Cascavel",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 70,
        "id_ibge" : "4104907",
        "mnemonico_regiao" : "PGR",
        "nome" : "Castro",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 71,
        "id_ibge" : "4105003",
        "mnemonico_regiao" : "CAV",
        "nome" : "Catanduvas",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 72,
        "id_ibge" : "4105102",
        "mnemonico_regiao" : "LON",
        "nome" : "Centenário do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 73,
        "id_ibge" : "4105201",
        "mnemonico_regiao" : "CUR",
        "nome" : "Cerro Azul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 74,
        "id_ibge" : "4105300",
        "mnemonico_regiao" : "CAV",
        "nome" : "Céu Azul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 75,
        "id_ibge" : "4105409",
        "mnemonico_regiao" : "GAV",
        "nome" : "Chopinzinho",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 76,
        "id_ibge" : "4105508",
        "mnemonico_regiao" : "PR3",
        "nome" : "Cianorte",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 77,
        "id_ibge" : "4105607",
        "mnemonico_regiao" : "PR3",
        "nome" : "Cidade Gaúcha",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 78,
        "id_ibge" : "4105706",
        "mnemonico_regiao" : "GAV",
        "nome" : "Clevelândia",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 79,
        "id_ibge" : "4105805",
        "mnemonico_regiao" : "CUR",
        "nome" : "Colombo",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 80,
        "id_ibge" : "4105904",
        "mnemonico_regiao" : "MAR",
        "nome" : "Colorado",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 81,
        "id_ibge" : "4106001",
        "mnemonico_regiao" : "LON",
        "nome" : "Congonhinhas",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 82,
        "id_ibge" : "4106100",
        "mnemonico_regiao" : "LON",
        "nome" : "Conselheiro Mairinck",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 83,
        "id_ibge" : "4106209",
        "mnemonico_regiao" : "CUR",
        "nome" : "Contenda",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 84,
        "id_ibge" : "4106308",
        "mnemonico_regiao" : "CAV",
        "nome" : "Corbélia",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 85,
        "id_ibge" : "4106407",
        "mnemonico_regiao" : "LON",
        "nome" : "Cornélio Procópio",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 86,
        "id_ibge" : "4106456",
        "mnemonico_regiao" : "GAV",
        "nome" : "Coronel Domingos Soares",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 87,
        "id_ibge" : "4106506",
        "mnemonico_regiao" : "GAV",
        "nome" : "Coronel Vivida",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 88,
        "id_ibge" : "4106555",
        "mnemonico_regiao" : "MAR",
        "nome" : "Corumbataí do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 89,
        "id_ibge" : "4106571",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Cruzeiro do Iguaçu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 90,
        "id_ibge" : "4106605",
        "mnemonico_regiao" : "PR3",
        "nome" : "Cruzeiro do Oeste",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 91,
        "id_ibge" : "4106704",
        "mnemonico_regiao" : "PR3",
        "nome" : "Cruzeiro do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 92,
        "id_ibge" : "4106803",
        "mnemonico_regiao" : "GAV",
        "nome" : "Cruz Machado",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 93,
        "id_ibge" : "4106852",
        "mnemonico_regiao" : "MAR",
        "nome" : "Cruzmaltina",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 94,
        "id_ibge" : "4106902",
        "mnemonico_regiao" : "CUR",
        "nome" : "Curitiba",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 95,
        "id_ibge" : "4107009",
        "mnemonico_regiao" : "PGR",
        "nome" : "Curiúva",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 96,
        "id_ibge" : "4107108",
        "mnemonico_regiao" : "PR3",
        "nome" : "Diamante do Norte",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 97,
        "id_ibge" : "4107124",
        "mnemonico_regiao" : "CAV",
        "nome" : "Diamante do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 98,
        "id_ibge" : "4107157",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Diamante D´oeste",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 99,
        "id_ibge" : "4107207",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Dois Vizinhos",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 100,
        "id_ibge" : "4107256",
        "mnemonico_regiao" : "PR3",
        "nome" : "Douradina",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 101,
        "id_ibge" : "4107306",
        "mnemonico_regiao" : "MAR",
        "nome" : "Doutor Camargo",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 102,
        "id_ibge" : "4107405",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Enéas Marques",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 103,
        "id_ibge" : "4107504",
        "mnemonico_regiao" : "MAR",
        "nome" : "Engenheiro Beltrão",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 104,
        "id_ibge" : "4107520",
        "mnemonico_regiao" : "PR3",
        "nome" : "Esperança Nova",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 105,
        "id_ibge" : "4107538",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Entre Rios do Oeste",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 106,
        "id_ibge" : "4107546",
        "mnemonico_regiao" : "CAV",
        "nome" : "Espigão Alto do Iguaçu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 107,
        "id_ibge" : "4107553",
        "mnemonico_regiao" : "MAR",
        "nome" : "Farol",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 108,
        "id_ibge" : "4107603",
        "mnemonico_regiao" : "MAR",
        "nome" : "Faxinal",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 109,
        "id_ibge" : "4107652",
        "mnemonico_regiao" : "CUR",
        "nome" : "Fazenda Rio Grande",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 110,
        "id_ibge" : "4107702",
        "mnemonico_regiao" : "MAR",
        "nome" : "Fênix",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 111,
        "id_ibge" : "4107736",
        "mnemonico_regiao" : "GAV",
        "nome" : "Fernandes Pinheiro",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 112,
        "id_ibge" : "4107751",
        "mnemonico_regiao" : "PGR",
        "nome" : "Figueira",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 113,
        "id_ibge" : "4107801",
        "mnemonico_regiao" : "MAR",
        "nome" : "Floraí",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 114,
        "id_ibge" : "4107850",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Flor da Serra do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 115,
        "id_ibge" : "4107900",
        "mnemonico_regiao" : "MAR",
        "nome" : "Floresta",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 116,
        "id_ibge" : "4108007",
        "mnemonico_regiao" : "LON",
        "nome" : "Florestópolis",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 117,
        "id_ibge" : "4108106",
        "mnemonico_regiao" : "MAR",
        "nome" : "Flórida",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 118,
        "id_ibge" : "4108205",
        "mnemonico_regiao" : "PR3",
        "nome" : "Formosa do Oeste",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 119,
        "id_ibge" : "4108304",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Foz do Iguaçu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 120,
        "id_ibge" : "4108320",
        "mnemonico_regiao" : "CAV",
        "nome" : "Francisco Alves",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 121,
        "id_ibge" : "4108403",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Francisco Beltrão",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 122,
        "id_ibge" : "4108452",
        "mnemonico_regiao" : "GAV",
        "nome" : "Foz do Jordão",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 123,
        "id_ibge" : "4108502",
        "mnemonico_regiao" : "GAV",
        "nome" : "General Carneiro",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 124,
        "id_ibge" : "4108551",
        "mnemonico_regiao" : "MAR",
        "nome" : "Godoy Moreira",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 125,
        "id_ibge" : "4108601",
        "mnemonico_regiao" : "PR3",
        "nome" : "Goioerê",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 126,
        "id_ibge" : "4108650",
        "mnemonico_regiao" : "GAV",
        "nome" : "Goioxim",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 127,
        "id_ibge" : "4108700",
        "mnemonico_regiao" : "MAR",
        "nome" : "Grandes Rios",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 128,
        "id_ibge" : "4108809",
        "mnemonico_regiao" : "CAV",
        "nome" : "Guaíra",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 129,
        "id_ibge" : "4108908",
        "mnemonico_regiao" : "PR3",
        "nome" : "Guairaçá",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 130,
        "id_ibge" : "4108957",
        "mnemonico_regiao" : "PGR",
        "nome" : "Guamiranga",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 131,
        "id_ibge" : "4109005",
        "mnemonico_regiao" : "LON",
        "nome" : "Guapirama",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 132,
        "id_ibge" : "4109104",
        "mnemonico_regiao" : "PR3",
        "nome" : "Guaporema",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 133,
        "id_ibge" : "4109203",
        "mnemonico_regiao" : "MAR",
        "nome" : "Guaraci",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 134,
        "id_ibge" : "4109302",
        "mnemonico_regiao" : "CAV",
        "nome" : "Guaraniaçu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 135,
        "id_ibge" : "4109401",
        "mnemonico_regiao" : "GAV",
        "nome" : "Guarapuava",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 136,
        "id_ibge" : "4109500",
        "mnemonico_regiao" : "CUR",
        "nome" : "Guaraqueçaba",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 137,
        "id_ibge" : "4109609",
        "mnemonico_regiao" : "CUR",
        "nome" : "Guaratuba",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 138,
        "id_ibge" : "4109658",
        "mnemonico_regiao" : "GAV",
        "nome" : "Honório Serpa",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 139,
        "id_ibge" : "4109708",
        "mnemonico_regiao" : "PGR",
        "nome" : "Ibaiti",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 140,
        "id_ibge" : "4109757",
        "mnemonico_regiao" : "CAV",
        "nome" : "Ibema",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 141,
        "id_ibge" : "4109807",
        "mnemonico_regiao" : "LON",
        "nome" : "Ibiporã",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 142,
        "id_ibge" : "4109906",
        "mnemonico_regiao" : "PR3",
        "nome" : "Icaraíma",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 143,
        "id_ibge" : "4110003",
        "mnemonico_regiao" : "MAR",
        "nome" : "Iguaraçu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 144,
        "id_ibge" : "4110052",
        "mnemonico_regiao" : "CAV",
        "nome" : "Iguatu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 145,
        "id_ibge" : "4110078",
        "mnemonico_regiao" : "PGR",
        "nome" : "Imbaú",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 146,
        "id_ibge" : "4110102",
        "mnemonico_regiao" : "PGR",
        "nome" : "Imbituva",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 147,
        "id_ibge" : "4110201",
        "mnemonico_regiao" : "GAV",
        "nome" : "Inácio Martins",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 148,
        "id_ibge" : "4110300",
        "mnemonico_regiao" : "PR3",
        "nome" : "Inajá",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 149,
        "id_ibge" : "4110409",
        "mnemonico_regiao" : "PR3",
        "nome" : "Indianópolis",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 150,
        "id_ibge" : "4110508",
        "mnemonico_regiao" : "PGR",
        "nome" : "Ipiranga",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 151,
        "id_ibge" : "4110607",
        "mnemonico_regiao" : "PR3",
        "nome" : "Iporã",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 152,
        "id_ibge" : "4110656",
        "mnemonico_regiao" : "PR3",
        "nome" : "Iracema do Oeste",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 153,
        "id_ibge" : "4110706",
        "mnemonico_regiao" : "GAV",
        "nome" : "Irati",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 154,
        "id_ibge" : "4110805",
        "mnemonico_regiao" : "MAR",
        "nome" : "Iretama",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 155,
        "id_ibge" : "4110904",
        "mnemonico_regiao" : "MAR",
        "nome" : "Itaguajé",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 156,
        "id_ibge" : "4110953",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Itaipulândia",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 157,
        "id_ibge" : "4111001",
        "mnemonico_regiao" : "LON",
        "nome" : "Itambaracá",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 158,
        "id_ibge" : "4111100",
        "mnemonico_regiao" : "MAR",
        "nome" : "Itambé",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 159,
        "id_ibge" : "4111209",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Itapejara D´oeste",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 160,
        "id_ibge" : "4111258",
        "mnemonico_regiao" : "CUR",
        "nome" : "Itaperuçu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 161,
        "id_ibge" : "4111308",
        "mnemonico_regiao" : "PR3",
        "nome" : "Itaúna do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 162,
        "id_ibge" : "4111407",
        "mnemonico_regiao" : "PGR",
        "nome" : "Ivaí",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 163,
        "id_ibge" : "4111506",
        "mnemonico_regiao" : "MAR",
        "nome" : "Ivaiporã",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 164,
        "id_ibge" : "4111555",
        "mnemonico_regiao" : "PR3",
        "nome" : "Ivaté",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 165,
        "id_ibge" : "4111605",
        "mnemonico_regiao" : "MAR",
        "nome" : "Ivatuba",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 166,
        "id_ibge" : "4111704",
        "mnemonico_regiao" : "PGR",
        "nome" : "Jaboti",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 167,
        "id_ibge" : "4111803",
        "mnemonico_regiao" : "LON",
        "nome" : "Jacarezinho",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 168,
        "id_ibge" : "4111902",
        "mnemonico_regiao" : "MAR",
        "nome" : "Jaguapitã",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 169,
        "id_ibge" : "4112009",
        "mnemonico_regiao" : "PGR",
        "nome" : "Jaguariaíva",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 170,
        "id_ibge" : "4112108",
        "mnemonico_regiao" : "MAR",
        "nome" : "Jandaia do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 171,
        "id_ibge" : "4112207",
        "mnemonico_regiao" : "PR3",
        "nome" : "Janiópolis",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 172,
        "id_ibge" : "4112306",
        "mnemonico_regiao" : "PGR",
        "nome" : "Japira",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 173,
        "id_ibge" : "4112405",
        "mnemonico_regiao" : "PR3",
        "nome" : "Japurá",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 174,
        "id_ibge" : "4112504",
        "mnemonico_regiao" : "MAR",
        "nome" : "Jardim Alegre",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 175,
        "id_ibge" : "4112603",
        "mnemonico_regiao" : "PR3",
        "nome" : "Jardim Olinda",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 176,
        "id_ibge" : "4112702",
        "mnemonico_regiao" : "LON",
        "nome" : "Jataizinho",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 177,
        "id_ibge" : "4112751",
        "mnemonico_regiao" : "CAV",
        "nome" : "Jesuítas",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 178,
        "id_ibge" : "4112801",
        "mnemonico_regiao" : "LON",
        "nome" : "Joaquim Távora",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 179,
        "id_ibge" : "4112900",
        "mnemonico_regiao" : "LON",
        "nome" : "Jundiaí do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 180,
        "id_ibge" : "4112959",
        "mnemonico_regiao" : "GAV",
        "nome" : "Juranda",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 181,
        "id_ibge" : "4113007",
        "mnemonico_regiao" : "PR3",
        "nome" : "Jussara",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 182,
        "id_ibge" : "4113106",
        "mnemonico_regiao" : "MAR",
        "nome" : "Kaloré",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 183,
        "id_ibge" : "4113205",
        "mnemonico_regiao" : "CUR",
        "nome" : "Lapa",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 184,
        "id_ibge" : "4113254",
        "mnemonico_regiao" : "GAV",
        "nome" : "Laranjal",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 185,
        "id_ibge" : "4113304",
        "mnemonico_regiao" : "GAV",
        "nome" : "Laranjeiras do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 186,
        "id_ibge" : "4113403",
        "mnemonico_regiao" : "LON",
        "nome" : "Leópolis",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 187,
        "id_ibge" : "4113429",
        "mnemonico_regiao" : "MAR",
        "nome" : "Lidianópolis",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 188,
        "id_ibge" : "4113452",
        "mnemonico_regiao" : "CAV",
        "nome" : "Lindoeste",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 189,
        "id_ibge" : "4113502",
        "mnemonico_regiao" : "PR3",
        "nome" : "Loanda",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 190,
        "id_ibge" : "4113601",
        "mnemonico_regiao" : "MAR",
        "nome" : "Lobato",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 191,
        "id_ibge" : "4113700",
        "mnemonico_regiao" : "LON",
        "nome" : "Londrina",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 192,
        "id_ibge" : "4113734",
        "mnemonico_regiao" : "MAR",
        "nome" : "Luiziana",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 193,
        "id_ibge" : "4113759",
        "mnemonico_regiao" : "MAR",
        "nome" : "Lunardelli",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 194,
        "id_ibge" : "4113809",
        "mnemonico_regiao" : "LON",
        "nome" : "Lupionópolis",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 195,
        "id_ibge" : "4113908",
        "mnemonico_regiao" : "PGR",
        "nome" : "Mallet",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 196,
        "id_ibge" : "4114005",
        "mnemonico_regiao" : "MAR",
        "nome" : "Mamborê",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 197,
        "id_ibge" : "4114104",
        "mnemonico_regiao" : "MAR",
        "nome" : "Mandaguaçu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 198,
        "id_ibge" : "4114203",
        "mnemonico_regiao" : "MAR",
        "nome" : "Mandaguari",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 199,
        "id_ibge" : "4114302",
        "mnemonico_regiao" : "CUR",
        "nome" : "Mandirituba",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 200,
        "id_ibge" : "4114351",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Manfrinópolis",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 201,
        "id_ibge" : "4114401",
        "mnemonico_regiao" : "GAV",
        "nome" : "Mangueirinha",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 202,
        "id_ibge" : "4114500",
        "mnemonico_regiao" : "GAV",
        "nome" : "Manoel Ribas",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 203,
        "id_ibge" : "4114609",
        "mnemonico_regiao" : "CAV",
        "nome" : "Marechal Cândido Rondon",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 204,
        "id_ibge" : "4114708",
        "mnemonico_regiao" : "PR3",
        "nome" : "Maria Helena",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 205,
        "id_ibge" : "4114807",
        "mnemonico_regiao" : "MAR",
        "nome" : "Marialva",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 206,
        "id_ibge" : "4114906",
        "mnemonico_regiao" : "LON",
        "nome" : "Marilândia do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 207,
        "id_ibge" : "4115002",
        "mnemonico_regiao" : "PR3",
        "nome" : "Marilena",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 208,
        "id_ibge" : "4115101",
        "mnemonico_regiao" : "PR3",
        "nome" : "Mariluz",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 209,
        "id_ibge" : "4115200",
        "mnemonico_regiao" : "MAR",
        "nome" : "Maringá",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 210,
        "id_ibge" : "4115309",
        "mnemonico_regiao" : "GAV",
        "nome" : "Mariópolis",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 211,
        "id_ibge" : "4115358",
        "mnemonico_regiao" : "CAV",
        "nome" : "Maripá",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 212,
        "id_ibge" : "4115408",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Marmeleiro",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 213,
        "id_ibge" : "4115457",
        "mnemonico_regiao" : "GAV",
        "nome" : "Marquinho",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 214,
        "id_ibge" : "4115507",
        "mnemonico_regiao" : "MAR",
        "nome" : "Marumbi",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 215,
        "id_ibge" : "4115606",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Matelândia",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 216,
        "id_ibge" : "4115705",
        "mnemonico_regiao" : "CUR",
        "nome" : "Matinhos",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 217,
        "id_ibge" : "4115739",
        "mnemonico_regiao" : "GAV",
        "nome" : "Mato Rico",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 218,
        "id_ibge" : "4115754",
        "mnemonico_regiao" : "LON",
        "nome" : "Mauá da Serra",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 219,
        "id_ibge" : "4115804",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Medianeira",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 220,
        "id_ibge" : "4115853",
        "mnemonico_regiao" : "CAV",
        "nome" : "Mercedes",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 221,
        "id_ibge" : "4115903",
        "mnemonico_regiao" : "PR3",
        "nome" : "Mirador",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 222,
        "id_ibge" : "4116000",
        "mnemonico_regiao" : "LON",
        "nome" : "Miraselva",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 223,
        "id_ibge" : "4116059",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Missal",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 224,
        "id_ibge" : "4116109",
        "mnemonico_regiao" : "PR3",
        "nome" : "Moreira Sales",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 225,
        "id_ibge" : "4116208",
        "mnemonico_regiao" : "CUR",
        "nome" : "Morretes",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 226,
        "id_ibge" : "4116307",
        "mnemonico_regiao" : "MAR",
        "nome" : "Munhoz de Melo",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 227,
        "id_ibge" : "4116406",
        "mnemonico_regiao" : "MAR",
        "nome" : "Nossa Senhora Das Graças",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 228,
        "id_ibge" : "4116505",
        "mnemonico_regiao" : "PR3",
        "nome" : "Nova Aliança do Ivaí",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 229,
        "id_ibge" : "4116604",
        "mnemonico_regiao" : "LON",
        "nome" : "Nova América da Colina",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 230,
        "id_ibge" : "4116703",
        "mnemonico_regiao" : "CAV",
        "nome" : "Nova Aurora",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 231,
        "id_ibge" : "4116802",
        "mnemonico_regiao" : "GAV",
        "nome" : "Nova Cantu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 232,
        "id_ibge" : "4116901",
        "mnemonico_regiao" : "PR3",
        "nome" : "Nova Esperança",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 233,
        "id_ibge" : "4116950",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Nova Esperança do Sudoeste",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 234,
        "id_ibge" : "4117008",
        "mnemonico_regiao" : "LON",
        "nome" : "Nova Fátima",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 235,
        "id_ibge" : "4117057",
        "mnemonico_regiao" : "GAV",
        "nome" : "Nova Laranjeiras",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 236,
        "id_ibge" : "4117107",
        "mnemonico_regiao" : "PR3",
        "nome" : "Nova Londrina",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 237,
        "id_ibge" : "4117206",
        "mnemonico_regiao" : "PR3",
        "nome" : "Nova Olímpia",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 238,
        "id_ibge" : "4117214",
        "mnemonico_regiao" : "LON",
        "nome" : "Nova Santa Bárbara",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 239,
        "id_ibge" : "4117222",
        "mnemonico_regiao" : "CAV",
        "nome" : "Nova Santa Rosa",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 240,
        "id_ibge" : "4117255",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Nova Prata do Iguaçu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 241,
        "id_ibge" : "4117271",
        "mnemonico_regiao" : "GAV",
        "nome" : "Nova Tebas",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 242,
        "id_ibge" : "4117297",
        "mnemonico_regiao" : "MAR",
        "nome" : "Novo Itacolomi",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 243,
        "id_ibge" : "4117305",
        "mnemonico_regiao" : "PGR",
        "nome" : "Ortigueira",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 244,
        "id_ibge" : "4117404",
        "mnemonico_regiao" : "MAR",
        "nome" : "Ourizona",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 245,
        "id_ibge" : "4117453",
        "mnemonico_regiao" : "CAV",
        "nome" : "Ouro Verde do Oeste",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 246,
        "id_ibge" : "4117503",
        "mnemonico_regiao" : "MAR",
        "nome" : "Paiçandu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 247,
        "id_ibge" : "4117602",
        "mnemonico_regiao" : "GAV",
        "nome" : "Palmas",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 248,
        "id_ibge" : "4117701",
        "mnemonico_regiao" : "PGR",
        "nome" : "Palmeira",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 249,
        "id_ibge" : "4117800",
        "mnemonico_regiao" : "GAV",
        "nome" : "Palmital",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 250,
        "id_ibge" : "4117909",
        "mnemonico_regiao" : "CAV",
        "nome" : "Palotina",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 251,
        "id_ibge" : "4118006",
        "mnemonico_regiao" : "PR3",
        "nome" : "Paraíso do Norte",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 252,
        "id_ibge" : "4118105",
        "mnemonico_regiao" : "PR3",
        "nome" : "Paranacity",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 253,
        "id_ibge" : "4118204",
        "mnemonico_regiao" : "CUR",
        "nome" : "Paranaguá",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 254,
        "id_ibge" : "4118303",
        "mnemonico_regiao" : "PR3",
        "nome" : "Paranapoema",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 255,
        "id_ibge" : "4118402",
        "mnemonico_regiao" : "PR3",
        "nome" : "Paranavaí",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 256,
        "id_ibge" : "4118451",
        "mnemonico_regiao" : "CAV",
        "nome" : "Pato Bragado",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 257,
        "id_ibge" : "4118501",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Pato Branco",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 258,
        "id_ibge" : "4118600",
        "mnemonico_regiao" : "GAV",
        "nome" : "Paula Freitas",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 259,
        "id_ibge" : "4118709",
        "mnemonico_regiao" : "PGR",
        "nome" : "Paulo Frontin",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 260,
        "id_ibge" : "4118808",
        "mnemonico_regiao" : "MAR",
        "nome" : "Peabiru",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 261,
        "id_ibge" : "4118857",
        "mnemonico_regiao" : "PR3",
        "nome" : "Perobal",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 262,
        "id_ibge" : "4118907",
        "mnemonico_regiao" : "PR3",
        "nome" : "Pérola",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 263,
        "id_ibge" : "4119004",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Pérola D´oeste",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 264,
        "id_ibge" : "4119103",
        "mnemonico_regiao" : "CUR",
        "nome" : "Piên",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 265,
        "id_ibge" : "4119152",
        "mnemonico_regiao" : "CUR",
        "nome" : "Pinhais",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 266,
        "id_ibge" : "4119202",
        "mnemonico_regiao" : "PGR",
        "nome" : "Pinhalão",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 267,
        "id_ibge" : "4119251",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Pinhal de São Bento",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 268,
        "id_ibge" : "4119301",
        "mnemonico_regiao" : "GAV",
        "nome" : "Pinhão",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 269,
        "id_ibge" : "4119400",
        "mnemonico_regiao" : "PGR",
        "nome" : "Piraí do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 270,
        "id_ibge" : "4119509",
        "mnemonico_regiao" : "CUR",
        "nome" : "Piraquara",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 271,
        "id_ibge" : "4119608",
        "mnemonico_regiao" : "GAV",
        "nome" : "Pitanga",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 272,
        "id_ibge" : "4119657",
        "mnemonico_regiao" : "LON",
        "nome" : "Pitangueiras",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 273,
        "id_ibge" : "4119707",
        "mnemonico_regiao" : "PR3",
        "nome" : "Planaltina do Paraná",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 274,
        "id_ibge" : "4119806",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Planalto",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 275,
        "id_ibge" : "4119905",
        "mnemonico_regiao" : "PGR",
        "nome" : "Ponta Grossa",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 276,
        "id_ibge" : "4119954",
        "mnemonico_regiao" : "CUR",
        "nome" : "Pontal do Paraná",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 277,
        "id_ibge" : "4120002",
        "mnemonico_regiao" : "LON",
        "nome" : "Porecatu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 278,
        "id_ibge" : "4120101",
        "mnemonico_regiao" : "PGR",
        "nome" : "Porto Amazonas",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 279,
        "id_ibge" : "4120150",
        "mnemonico_regiao" : "GAV",
        "nome" : "Porto Barreiro",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 280,
        "id_ibge" : "4120200",
        "mnemonico_regiao" : "PR3",
        "nome" : "Porto Rico",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 281,
        "id_ibge" : "4120309",
        "mnemonico_regiao" : "GAV",
        "nome" : "Porto Vitória",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 282,
        "id_ibge" : "4120333",
        "mnemonico_regiao" : "LON",
        "nome" : "Prado Ferreira",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 283,
        "id_ibge" : "4120358",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Pranchita",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 284,
        "id_ibge" : "4120408",
        "mnemonico_regiao" : "MAR",
        "nome" : "Presidente Castelo Branco",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 285,
        "id_ibge" : "4120507",
        "mnemonico_regiao" : "LON",
        "nome" : "Primeiro de Maio",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 286,
        "id_ibge" : "4120606",
        "mnemonico_regiao" : "GAV",
        "nome" : "Prudentópolis",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 287,
        "id_ibge" : "4120655",
        "mnemonico_regiao" : "PR3",
        "nome" : "Quarto Centenário",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 288,
        "id_ibge" : "4120705",
        "mnemonico_regiao" : "LON",
        "nome" : "Quatiguá",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 289,
        "id_ibge" : "4120804",
        "mnemonico_regiao" : "CUR",
        "nome" : "Quatro Barras",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 290,
        "id_ibge" : "4120853",
        "mnemonico_regiao" : "CAV",
        "nome" : "Quatro Pontes",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 291,
        "id_ibge" : "4120903",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Quedas do Iguaçu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 292,
        "id_ibge" : "4121000",
        "mnemonico_regiao" : "PR3",
        "nome" : "Querência do Norte",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 293,
        "id_ibge" : "4121109",
        "mnemonico_regiao" : "MAR",
        "nome" : "Quinta do Sol",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 294,
        "id_ibge" : "4121208",
        "mnemonico_regiao" : "CUR",
        "nome" : "Quitandinha",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 295,
        "id_ibge" : "4121257",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Ramilândia",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 296,
        "id_ibge" : "4121307",
        "mnemonico_regiao" : "LON",
        "nome" : "Rancho Alegre",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 297,
        "id_ibge" : "4121356",
        "mnemonico_regiao" : "PR3",
        "nome" : "Rancho Alegre D´oeste",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 298,
        "id_ibge" : "4121406",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Realeza",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 299,
        "id_ibge" : "4121505",
        "mnemonico_regiao" : "GAV",
        "nome" : "Rebouças",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 300,
        "id_ibge" : "4121604",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Renascença",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 301,
        "id_ibge" : "4121703",
        "mnemonico_regiao" : "PGR",
        "nome" : "Reserva",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 302,
        "id_ibge" : "4121752",
        "mnemonico_regiao" : "GAV",
        "nome" : "Reserva do Iguaçu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 303,
        "id_ibge" : "4121802",
        "mnemonico_regiao" : "LON",
        "nome" : "Ribeirão Claro",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 304,
        "id_ibge" : "4121901",
        "mnemonico_regiao" : "LON",
        "nome" : "Ribeirão do Pinhal",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 305,
        "id_ibge" : "4122008",
        "mnemonico_regiao" : "GAV",
        "nome" : "Rio Azul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 306,
        "id_ibge" : "4122107",
        "mnemonico_regiao" : "MAR",
        "nome" : "Rio Bom",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 307,
        "id_ibge" : "4122156",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Rio Bonito do Iguaçu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 308,
        "id_ibge" : "4122172",
        "mnemonico_regiao" : "MAR",
        "nome" : "Rio Branco do Ivaí",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 309,
        "id_ibge" : "4122206",
        "mnemonico_regiao" : "CUR",
        "nome" : "Rio Branco do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 310,
        "id_ibge" : "4122305",
        "mnemonico_regiao" : "CUR",
        "nome" : "Rio Negro",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 311,
        "id_ibge" : "4122404",
        "mnemonico_regiao" : "LON",
        "nome" : "Rolândia",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 312,
        "id_ibge" : "4122503",
        "mnemonico_regiao" : "GAV",
        "nome" : "Roncador",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 313,
        "id_ibge" : "4122602",
        "mnemonico_regiao" : "PR3",
        "nome" : "Rondon",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 314,
        "id_ibge" : "4122651",
        "mnemonico_regiao" : "MAR",
        "nome" : "Rosário do Ivaí",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 315,
        "id_ibge" : "4122701",
        "mnemonico_regiao" : "MAR",
        "nome" : "Sabáudia",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 316,
        "id_ibge" : "4122800",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Salgado Filho",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 317,
        "id_ibge" : "4122909",
        "mnemonico_regiao" : "PGR",
        "nome" : "Salto do Itararé",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 318,
        "id_ibge" : "4123006",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Salto do Lontra",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 319,
        "id_ibge" : "4123105",
        "mnemonico_regiao" : "LON",
        "nome" : "Santa Amélia",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 320,
        "id_ibge" : "4123204",
        "mnemonico_regiao" : "LON",
        "nome" : "Santa Cecília do Pavão",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 321,
        "id_ibge" : "4123303",
        "mnemonico_regiao" : "PR3",
        "nome" : "Santa Cruz de Monte Castelo",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 322,
        "id_ibge" : "4123402",
        "mnemonico_regiao" : "MAR",
        "nome" : "Santa fé",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 323,
        "id_ibge" : "4123501",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Santa Helena",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 324,
        "id_ibge" : "4123600",
        "mnemonico_regiao" : "MAR",
        "nome" : "Santa Inês",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 325,
        "id_ibge" : "4123709",
        "mnemonico_regiao" : "PR3",
        "nome" : "Santa Isabel do Ivaí",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 326,
        "id_ibge" : "4123808",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Santa Izabel do Oeste",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 327,
        "id_ibge" : "4123824",
        "mnemonico_regiao" : "CAV",
        "nome" : "Santa Lúcia",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 328,
        "id_ibge" : "4123857",
        "mnemonico_regiao" : "GAV",
        "nome" : "Santa Maria do Oeste",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 329,
        "id_ibge" : "4123907",
        "mnemonico_regiao" : "LON",
        "nome" : "Santa Mariana",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 330,
        "id_ibge" : "4123956",
        "mnemonico_regiao" : "PR3",
        "nome" : "Santa Mônica",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 331,
        "id_ibge" : "4124004",
        "mnemonico_regiao" : "PGR",
        "nome" : "Santana do Itararé",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 332,
        "id_ibge" : "4124020",
        "mnemonico_regiao" : "CAV",
        "nome" : "Santa Tereza do Oeste",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 333,
        "id_ibge" : "4124053",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Santa Terezinha de Itaipu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 334,
        "id_ibge" : "4124103",
        "mnemonico_regiao" : "LON",
        "nome" : "Santo Antônio da Platina",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 335,
        "id_ibge" : "4124202",
        "mnemonico_regiao" : "PR3",
        "nome" : "Santo Antônio do Caiuá",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 336,
        "id_ibge" : "4124301",
        "mnemonico_regiao" : "LON",
        "nome" : "Santo Antônio do Paraíso",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 337,
        "id_ibge" : "4124400",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Santo Antônio do Sudoeste",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 338,
        "id_ibge" : "4124509",
        "mnemonico_regiao" : "MAR",
        "nome" : "Santo Inácio",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 339,
        "id_ibge" : "4124608",
        "mnemonico_regiao" : "PR3",
        "nome" : "São Carlos do Ivaí",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 340,
        "id_ibge" : "4124707",
        "mnemonico_regiao" : "LON",
        "nome" : "São Jerônimo da Serra",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 341,
        "id_ibge" : "4124806",
        "mnemonico_regiao" : "FOZ",
        "nome" : "São João",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 342,
        "id_ibge" : "4124905",
        "mnemonico_regiao" : "PR3",
        "nome" : "São João do Caiuá",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 343,
        "id_ibge" : "4125001",
        "mnemonico_regiao" : "MAR",
        "nome" : "São João do Ivaí",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 344,
        "id_ibge" : "4125100",
        "mnemonico_regiao" : "PGR",
        "nome" : "São João do Triunfo",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 345,
        "id_ibge" : "4125209",
        "mnemonico_regiao" : "FOZ",
        "nome" : "São Jorge D´oeste",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 346,
        "id_ibge" : "4125308",
        "mnemonico_regiao" : "MAR",
        "nome" : "São Jorge do Ivaí",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 347,
        "id_ibge" : "4125357",
        "mnemonico_regiao" : "PR3",
        "nome" : "São Jorge do Patrocínio",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 348,
        "id_ibge" : "4125407",
        "mnemonico_regiao" : "PGR",
        "nome" : "São José da Boa Vista",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 349,
        "id_ibge" : "4125456",
        "mnemonico_regiao" : "FOZ",
        "nome" : "São José Das Palmeiras",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 350,
        "id_ibge" : "4125506",
        "mnemonico_regiao" : "CUR",
        "nome" : "São José Dos Pinhais",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 351,
        "id_ibge" : "4125555",
        "mnemonico_regiao" : "PR3",
        "nome" : "São Manoel do Paraná",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 352,
        "id_ibge" : "4125605",
        "mnemonico_regiao" : "PGR",
        "nome" : "São Mateus do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 353,
        "id_ibge" : "4125704",
        "mnemonico_regiao" : "FOZ",
        "nome" : "São Miguel do Iguaçu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 354,
        "id_ibge" : "4125753",
        "mnemonico_regiao" : "CAV",
        "nome" : "São Pedro do Iguaçu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 355,
        "id_ibge" : "4125803",
        "mnemonico_regiao" : "MAR",
        "nome" : "São Pedro do Ivaí",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 356,
        "id_ibge" : "4125902",
        "mnemonico_regiao" : "PR3",
        "nome" : "São Pedro do Paraná",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 357,
        "id_ibge" : "4126009",
        "mnemonico_regiao" : "LON",
        "nome" : "São Sebastião da Amoreira",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 358,
        "id_ibge" : "4126108",
        "mnemonico_regiao" : "PR3",
        "nome" : "São Tomé",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 359,
        "id_ibge" : "4126207",
        "mnemonico_regiao" : "LON",
        "nome" : "Sapopema",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 360,
        "id_ibge" : "4126256",
        "mnemonico_regiao" : "MAR",
        "nome" : "Sarandi",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 361,
        "id_ibge" : "4126272",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Saudade do Iguaçu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 362,
        "id_ibge" : "4126306",
        "mnemonico_regiao" : "PGR",
        "nome" : "Sengés",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 363,
        "id_ibge" : "4126355",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Serranópolis do Iguaçu",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 364,
        "id_ibge" : "4126405",
        "mnemonico_regiao" : "LON",
        "nome" : "Sertaneja",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 365,
        "id_ibge" : "4126504",
        "mnemonico_regiao" : "LON",
        "nome" : "Sertanópolis",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 366,
        "id_ibge" : "4126603",
        "mnemonico_regiao" : "PGR",
        "nome" : "Siqueira Campos",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 367,
        "id_ibge" : "4126652",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Sulina",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 368,
        "id_ibge" : "4126678",
        "mnemonico_regiao" : "LON",
        "nome" : "Tamarana",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 369,
        "id_ibge" : "4126702",
        "mnemonico_regiao" : "PR3",
        "nome" : "Tamboara",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 370,
        "id_ibge" : "4126801",
        "mnemonico_regiao" : "PR3",
        "nome" : "Tapejara",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 371,
        "id_ibge" : "4126900",
        "mnemonico_regiao" : "PR3",
        "nome" : "Tapira",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 372,
        "id_ibge" : "4127007",
        "mnemonico_regiao" : "PGR",
        "nome" : "Teixeira Soares",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 373,
        "id_ibge" : "4127106",
        "mnemonico_regiao" : "PGR",
        "nome" : "Telêmaco Borba",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 374,
        "id_ibge" : "4127205",
        "mnemonico_regiao" : "MAR",
        "nome" : "Terra Boa",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 375,
        "id_ibge" : "4127304",
        "mnemonico_regiao" : "PR3",
        "nome" : "Terra Rica",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 376,
        "id_ibge" : "4127403",
        "mnemonico_regiao" : "CAV",
        "nome" : "Terra Roxa",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 377,
        "id_ibge" : "4127502",
        "mnemonico_regiao" : "PGR",
        "nome" : "Tibagi",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 378,
        "id_ibge" : "4127601",
        "mnemonico_regiao" : "CUR",
        "nome" : "Tijucas do Sul",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 379,
        "id_ibge" : "4127700",
        "mnemonico_regiao" : "CAV",
        "nome" : "Toledo",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 380,
        "id_ibge" : "4127809",
        "mnemonico_regiao" : "PGR",
        "nome" : "Tomazina",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 381,
        "id_ibge" : "4127858",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Três Barras do Paraná",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 382,
        "id_ibge" : "4127882",
        "mnemonico_regiao" : "CUR",
        "nome" : "Tunas do Paraná",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 383,
        "id_ibge" : "4127908",
        "mnemonico_regiao" : "PR3",
        "nome" : "Tuneiras do Oeste",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 384,
        "id_ibge" : "4127957",
        "mnemonico_regiao" : "CAV",
        "nome" : "Tupãssi",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 385,
        "id_ibge" : "4127965",
        "mnemonico_regiao" : "GAV",
        "nome" : "Turvo",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 386,
        "id_ibge" : "4128005",
        "mnemonico_regiao" : "CAV",
        "nome" : "Ubiratã",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 387,
        "id_ibge" : "4128104",
        "mnemonico_regiao" : "PR3",
        "nome" : "Umuarama",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 388,
        "id_ibge" : "4128203",
        "mnemonico_regiao" : "GAV",
        "nome" : "União da Vitória",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 389,
        "id_ibge" : "4128302",
        "mnemonico_regiao" : "PR3",
        "nome" : "Uniflor",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 390,
        "id_ibge" : "4128401",
        "mnemonico_regiao" : "LON",
        "nome" : "Uraí",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 391,
        "id_ibge" : "4128500",
        "mnemonico_regiao" : "PGR",
        "nome" : "Wenceslau Braz",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 392,
        "id_ibge" : "4128534",
        "mnemonico_regiao" : "PGR",
        "nome" : "Ventania",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 393,
        "id_ibge" : "4128559",
        "mnemonico_regiao" : "CAV",
        "nome" : "Vera Cruz do Oeste",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 394,
        "id_ibge" : "4128609",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Verê",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 395,
        "id_ibge" : "4128625",
        "mnemonico_regiao" : "PR3",
        "nome" : "Alto Paraíso",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 396,
        "id_ibge" : "4128633",
        "mnemonico_regiao" : "CUR",
        "nome" : "Doutor Ulysses",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 397,
        "id_ibge" : "4128658",
        "mnemonico_regiao" : "GAV",
        "nome" : "Virmond",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 398,
        "id_ibge" : "4128708",
        "mnemonico_regiao" : "FOZ",
        "nome" : "Vitorino",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      },
      {
        "id" : 399,
        "id_ibge" : "4128807",
        "mnemonico_regiao" : "PR3",
        "nome" : "Xambrê",
        "created_at" : "2008-08-19T03:00:00Z",
        "updated_at" : "2008-08-19T03:00:00Z"
      }
    ]
  }