import React, { useState, useEffect, useContext } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import AutosuggestComponent from '../AutosuggestComponent'
import { generateDialogsSubtitle } from '../../helpers/index.js'
import { styles } from '../../styles/js/dialog'
import { initialFinishTicketState } from '../../constants/initialConstants'
import { ApplicationContext } from '../../ContextProvider.jsx'

let tags = []

function FinishTicketDialog(props) {
  const {
    classes,
    areas,
    categorias,
    finishTicketDialogState,
    setFinishTicketDialogState,
    palavrasChave,
    palavrasChaveInput,
    setPalavrasChaveInput,
    handleAction,
    handlePesquisaAction,
    dialogOpen,
    actionTitle,
    rows,
    selectedRow,
    selectedRows,
    statusRequest,
  } = props

  const [open, setOpen] = useState(false)

  const { checkboxesState, idCategoria, observacoes } = finishTicketDialogState

  const {
    jornalismo,
    programacao,
    engenharia,
    esporteEAventura,
    institucional,
    negocios,
  } = checkboxesState

  const { user } = useContext(ApplicationContext)

  useEffect(() => {
    if (dialogOpen) {
      handleOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen])

  useEffect(() => {
    populateTags()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areas])

  useEffect(() => {
    if (!jornalismo) {
      clearSelectedTags('Jornalismo')
    }

    if (!programacao) {
      clearSelectedTags('Programação')
    }

    if (!engenharia) {
      clearSelectedTags('Engenharia')
    }

    if (!esporteEAventura) {
      clearSelectedTags('Esporte e Aventura')
    }

    if (!institucional) {
      clearSelectedTags('Institucional')
    }

    if (!negocios) {
      clearSelectedTags('Negócios')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishTicketDialogState.checkboxesState])

  const handleOpen = () => {
    setOpen(true)
    setFinishTicketDialogState({
      ...finishTicketDialogState,
      idUsuario: user.id,
    })
    if (tags.length === 0) {
      populateTags()
    }
  }

  const handleClose = () => {
    tags = []
    setFinishTicketDialogState({ ...initialFinishTicketState })
    setOpen(false)
    props.handleClose()
  }

  const handleFinalizarTicket = () => {
    tags = []
    setFinishTicketDialogState({ ...initialFinishTicketState })
    handleAction()
    setOpen(false)
    props.handleClose()
  }
  const handleFinalizarTicketPesquisa = () => {
    tags = []
    setFinishTicketDialogState({ ...initialFinishTicketState })
    handlePesquisaAction()
    setOpen(false)
    props.handleClose()
  }

  const populateTags = () => {
    tags = []
    areas.forEach(area => {
      area.tags.forEach(tag => {
        tags.push({
          id: tag.id,
          name: tag.nome,
          isSelected: false,
          type: area.nome,
        })
      })
    })
  }

  const clearSelectedTags = type => {
    tags
      .filter(tag => {
        return tag.type === type
      })
      .forEach(tag => {
        tag.isSelected = false
      })

    updateWithSelectedTags()
  }

  const updateWithSelectedTags = () => {
    const selectedTags = tags.filter(tag => tag.isSelected === true)
    setFinishTicketDialogState({
      ...finishTicketDialogState,
      tagsState: [...selectedTags],
    })
  }

  const handleTag = tag => {
    tags.find(tagFound => tagFound.id === tag.id).isSelected = !tag.isSelected

    updateWithSelectedTags()
  }

  const renderTagSection = type => {
    return (
      <div className={classes.tagsSection}>
        <Typography paragraph style={{ marginBottom: 0 }}>
          Tags de {type}
        </Typography>
        <Grid container>
          {tags
            .filter(tag => {
              return tag.type === type
            })
            .map(tag => (
              <Chip
                color={tag.isSelected ? 'primary' : 'default'}
                key={tag.id}
                label={tag.name}
                className={classes.chip}
                component='a'
                clickable
                onClick={() => handleTag(tag)}
              />
            ))}
        </Grid>
      </div>
    )
  }

  let titleDescription = generateDialogsSubtitle(
    selectedRow,
    rows,
    selectedRows,
    ''
  )

  return (
    <React.Fragment>
      <Dialog
        maxWidth='md'
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby='dialogTitle'
        scroll='body'
        PaperProps={{
          className: classes.dialog,
        }}
      >
        <DialogTitle id='dialogTitle'>
          Finalizar Ticket
          <Typography variant='body2' gutterBottom>
            {titleDescription}
          </Typography>
        </DialogTitle>

        {statusRequest ? (
          <DialogContent className={classes.dialogContent}>
            <Grid container>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!jornalismo}
                      onChange={event =>
                        setFinishTicketDialogState({
                          ...finishTicketDialogState,
                          checkboxesState: {
                            ...finishTicketDialogState.checkboxesState,
                            jornalismo: event.target.checked,
                          },
                        })
                      }
                      value='jornalismo'
                    />
                  }
                  label='Jornalismo'
                  className={classes.formControlLabel}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!programacao}
                      onChange={event =>
                        setFinishTicketDialogState({
                          ...finishTicketDialogState,
                          checkboxesState: {
                            ...finishTicketDialogState.checkboxesState,
                            programacao: event.target.checked,
                          },
                        })
                      }
                      value='programacao'
                    />
                  }
                  label='Programação'
                  className={classes.formControlLabel}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!engenharia}
                      onChange={event =>
                        setFinishTicketDialogState({
                          ...finishTicketDialogState,
                          checkboxesState: {
                            ...finishTicketDialogState.checkboxesState,
                            engenharia: event.target.checked,
                          },
                        })
                      }
                      value='engenharia'
                    />
                  }
                  label='Engenharia'
                  className={classes.formControlLabel}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!esporteEAventura}
                      onChange={event =>
                        setFinishTicketDialogState({
                          ...finishTicketDialogState,
                          checkboxesState: {
                            ...finishTicketDialogState.checkboxesState,
                            esporteEAventura: event.target.checked,
                          },
                        })
                      }
                      value='esporteEAventura'
                    />
                  }
                  label='Esporte e Aventura'
                  className={classes.formControlLabel}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!institucional}
                      onChange={event =>
                        setFinishTicketDialogState({
                          ...finishTicketDialogState,
                          checkboxesState: {
                            ...finishTicketDialogState.checkboxesState,
                            institucional: event.target.checked,
                          },
                        })
                      }
                      value='institucional'
                    />
                  }
                  label='Institucional'
                  className={classes.formControlLabel}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!negocios}
                      onChange={event =>
                        setFinishTicketDialogState({
                          ...finishTicketDialogState,
                          checkboxesState: {
                            ...finishTicketDialogState.checkboxesState,
                            negocios: event.target.checked,
                          },
                        })
                      }
                      value='negocios'
                    />
                  }
                  label='Negócios'
                  className={classes.formControlLabel}
                />
              </FormGroup>
            </Grid>

            <Grid container>
              {jornalismo && renderTagSection('Jornalismo')}
              {programacao && renderTagSection('Programação')}
              {engenharia && renderTagSection('Engenharia')}
              {esporteEAventura && renderTagSection('Esporte e Aventura')}
              {institucional && renderTagSection('Institucional')}
              {negocios && renderTagSection('Negócios')}
            </Grid>

            <div>
              <Grid container>
                <Grid item xs={12} sm={4} md={3}>
                  <TextField
                    id='idCategoria'
                    select
                    label='Categoria'
                    className={classes.textField}
                    value={idCategoria}
                    onChange={event =>
                      setFinishTicketDialogState({
                        ...finishTicketDialogState,
                        idCategoria: event.target.value,
                      })
                    }
                    margin='normal'
                    fullWidth
                  >
                    {categorias.map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <AutosuggestComponent
                    inputValue={palavrasChaveInput}
                    setInputValue={setPalavrasChaveInput}
                    setParentSuggestions={value =>
                      setFinishTicketDialogState({
                        ...finishTicketDialogState,
                        palavrasChave: value,
                      })
                    }
                    label='Palavras-chave'
                    placeholder='Digite ou escolha uma ou mais palavras-chave'
                    suggestions={palavrasChave || []}
                    multiple
                  />
                </Grid>
              </Grid>

              <Grid container>
                <TextField
                  id='observacoes'
                  label='Observações'
                  multiline
                  rows='7'
                  value={observacoes}
                  onChange={event =>
                    setFinishTicketDialogState({
                      ...finishTicketDialogState,
                      observacoes: event.target.value,
                    })
                  }
                  className={classes.textField}
                  margin='normal'
                  fullWidth
                />
              </Grid>
            </div>
          </DialogContent>
        ) : (
          <LinearProgress />
        )}

        <DialogActions>
          <Button onClick={handleClose} style={{ color: '#c62334' }}>
            Cancelar
          </Button>
          
          {/* {[75, 143, 10, 109, 5].includes(user.id) && ( */}
            <Button onClick={handleFinalizarTicketPesquisa} style={{ color: '#009d93' }}>
              Finalizar e enviar Pesquisa
            </Button>
          {/* )} */}

          <Button onClick={handleFinalizarTicket} style={{ color: '#009d93' }}>
            {actionTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default withStyles(styles)(FinishTicketDialog)
