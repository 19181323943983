import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { redirectToAuthentication } from '../helpers/authenticator'
import Loading from '../components/Loading'

const NotLoggedIn = props => {
  const [isAuthenticating, setIsAuthenticating] = useState(false)

  useEffect(() => {
    const url = new URL(window.location.href)
    const token = url.searchParams.get("token")

    if (token) {
      setIsAuthenticating(true)
      redirectToAuthentication()
    }
  }, [])

  return (
    <div>
      {!isAuthenticating ? 
      <Grid container justify="center" alignItems="center">
        <Paper style={{ maxWidth: 400, width: '80%', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', padding: 20, }}>
          <Typography variant="h4" style={{ marginTop: 20 }}>
            A sua sessão expirou.
          </Typography>
          <br />
          <Link onClick={() => redirectToAuthentication()} style={{ cursor: 'pointer' }}>
            Clique aqui para se reconectar
          </Link>
          <br /><br />
        </Paper>
      </Grid>
      : <Loading />}
    </div>
  )
}

export default NotLoggedIn