import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core'

const DialogFinishTicketConteudo = (props) => {
  const { 
    dialogOpen,
    description,
    handleClose,
    handleFinalizar,
  } = props

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby="dialog-finalizar"
    >
      <DialogTitle id="dialog-finalizar">Finalizar Atendimento</DialogTitle>

      <DialogContent>
        <DialogContentText id="dialog-finalizar-description">
          Você tem certeza que deseja finalizar este atendimento?
        </DialogContentText>
        <Typography paragraph>
          {description}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>

        <Button onClick={handleFinalizar} color="primary">
          Finalizar Atendimento
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogFinishTicketConteudo
