import React, { Fragment } from 'react'
import { Switch, BrowserRouter, Route } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import Navbar from '../components/Navbar.jsx'
import HttpRequestHandler from '../components/HttpRequestHandler.jsx'
import NotLoggedIn from '../components/NotLoggedIn.jsx'

import Atendimentos from '../containers/Atendimentos'
import Inbox from '../containers/Inbox'
import Telespectadores from '../containers/Telespectadores'
import Chat from '../containers/Chat.jsx'
import Mensagens from '../containers/Mensagens.jsx'

import { authStatuses } from '../constants/authentication'
import { isAuthenticated } from '../helpers/authenticator'

import '../styles/css/style.css'

const ApplicationRouter = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path='/' component={withNavbar(Inbox, 'Caixa de Entrada')} />
      <Route
        exact
        path='/atendimentos'
        component={withNavbar(Atendimentos, 'Atendimentos')}
      />
      <Route
        exact
        path='/telespectadores'
        component={withNavbar(Telespectadores, 'Telespectadores')}
      />
      <Route
        exact
        path='/mensagens'
        component={withNavbar(Mensagens, 'Mensagens')}
      />
      <Route exact path='/chat/:id' component={withNavbar(Chat, 'Chat')} />

      <Route
        component={withNavbar(HttpRequestHandler, 'Página não encontrada')}
      />
    </Switch>
  </BrowserRouter>
)

export default ApplicationRouter

// HOC to wrap component with Navbar
const withNavbar = (WrappedComponent, appBarTitle) => {
  const styles = theme => ({
    root: {
      display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      maxWidth: 1366,
      margin: '0 auto',
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    },
  })

  class withNavbar extends React.Component {
    renderContent = () => {
      const authenticated = isAuthenticated()

      if (
        authenticated === authStatuses.AUTHENTICATED &&
        appBarTitle === 'Página não encontrada'
      ) {
        return (
          <WrappedComponent
            errorCode='404'
            errorTitle={appBarTitle}
            errorDescription='O link que você tentou acessar não pode ser encontrado. Tem certeza que você o digitou corretamente?'
            redirectLink='/'
            redirectLinkText='Clique aqui para voltar à Home'
          />
        )
      }

      if (authenticated === authStatuses.AUTHENTICATED) {
        return <WrappedComponent />
      }

      if (authenticated === authStatuses.EXPIRED) {
        return <NotLoggedIn />
      }

      if (authenticated === authStatuses.UNAUTHENTICATED) {
        return (
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            <CircularProgress color='primary' size={60} disableShrink />
          </div>
        )
      }

      return <WrappedComponent />
    }

    render() {
      const authenticated = isAuthenticated()

      return (
        <Fragment>
          {authenticated === authStatuses.AUTHENTICATED && (
            <Navbar appBarTitle={appBarTitle} />
          )}
          <main className={this.props.classes.content}>
            <div style={{ minHeight: 35 }} />
            <Grid container justifyContent='center'>
              <Grid item xs={12}>
                {this.renderContent()}
              </Grid>
            </Grid>
          </main>
        </Fragment>
      )
    }
  }

  withNavbar.displayName = `withNavbar(${getDisplayName(WrappedComponent)})`
  return withStyles(styles)(withNavbar)
}

const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component'
