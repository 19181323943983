export const styles = theme => ({
  // autosuggestContainer: {
  //   position: "relative",
  // },
  
  // suggestion: {
  //   display: 'block',
  // },

  // suggestionsList: {
  //   margin: 0,
  //   padding: 0,
  //   listStyleType: 'none',
  // },

  // textField: {
  //   width: 'calc(100% - 30px)',
  //   marginLeft: "15px",
  //   marginRight: "15px",
  // },

  // textFieldTable: {
  //   bottom: "4px",
  //   marginLeft: "0px",
  //   marginRight: "0px",
  // },

  // menuItemPaper: {
  //   maxHeight: 240,
  //   overflowY: 'auto',
  //   position: 'absolute',
  //   zIndex: 999,
  //   marginLeft: "15px",
  //   width: "calc(100% - 30px)",
  // },

  // menuItemPaperTable: {
  //   maxHeight: 120,
  //   overflowY: 'auto',
  //   position: 'absolute',
  //   zIndex: 999,
  //   marginLeft: "0px",
  //   width: "100%",
  // },

  root: {
    flexGrow: 1,
    marginTop: 16,
    marginBottom: 8,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,

    maxHeight: 170,
    overflowY: 'auto',
    zIndex: 999,
    // marginLeft: "15px",
    // width: "calc(100% - 30px)",
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
});