import React, { useState, useEffect, useContext } from 'react'

import { useRequest } from '../hooks'

import DialogSuporteComponent from '../components/Dialogs/DialogSuporteComponent.jsx'
import { initialSuporteState } from '../constants/initialConstants'
import { SnackbarContext } from '../ContextProvider'

const DialogSuporte = props => {
  const { showSnackbar } = useContext(SnackbarContext)

  const {
    dialogOpen,
    setDialogOpen,
    fetchTickets,
    rows,
    selectedRow,
    selectedRows,
    eraseSelectedRows,
  } = props

  const [suporteDialogState, setSuporteDialogState] = useState(initialSuporteState)

  const [, , solicitarSuporte] = useRequest(
    {},
    {
      url: 'tickets_usuarios',
      method: 'post',
      data: suporteDialogState
    },
    null,
    {
      onComplete: () => {
        handleClose()
        eraseSelectedRows()
        showSnackbar('Suporte solicitado com sucesso!', 'success')
        fetchTickets()
      },
      onError: error => {
        showSnackbar(error.response.data.code, 'error')
      },
    }
  )

  useEffect(() => {
    if (selectedRow) {
      setSuporteDialogState({ ...suporteDialogState, idsTickets: [selectedRow.id] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow])

  useEffect(() => {
    if (selectedRows) {
      setSuporteDialogState({ ...suporteDialogState, idsTickets: selectedRows })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows])


  const handleClose = () => {
    setSuporteDialogState(initialSuporteState)
    setDialogOpen(false)
  }

  const handleAction = () => {
    solicitarSuporte()
  }

  return (
    <DialogSuporteComponent
      suporteDialogState={suporteDialogState}
      setSuporteDialogState={setSuporteDialogState}
      handleAction={handleAction}
      handleClose={handleClose}
      dialogOpen={dialogOpen}
      actionTitle='Solicitar Suporte'
      rows={rows}
      selectedRow={selectedRow}
      selectedRows={selectedRows}
    />
  )
}

export default DialogSuporte
