export const API_BASE_URL = 'https://api.atendimento.vocenarpc.com.br/v1/'
export const APP_URL = 'https://atendimento.vocenarpc.com.br'
export const AUTHENTICATION_URL = 'https://auth.grpcom.com.br/auth/rpc-atendimento?next=atendimento.vocenarpc.com.br'
export const SALESFORCE_URL = 'https://rpccrm.lightning.force.com'

export const FIREBASE_SERVICE_ACCOUNT = {
  apiKey: "AIzaSyBH16Vo5ncQqDnH2JDvPOBL81bZxQv2F-g",
  authDomain: "vocenarpc-239119.firebaseapp.com",
  databaseURL: "https://vocenarpc-239119.firebaseio.com",
  projectId: "vocenarpc-239119",
  storageBucket: "vocenarpc-239119.appspot.com",
  messagingSenderId: "35097694314",
  appId: "1:35097694314:web:b0cef00f2f068bb5"
}

export default {
  API_BASE_URL,
  APP_URL,
  AUTHENTICATION_URL,
  SALESFORCE_URL,
  FIREBASE_SERVICE_ACCOUNT
}