import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import DialogLookup from './DialogLookup'
import Usuarios from '../../containers/Usuarios'

import { generateDialogsSubtitle } from '../../helpers/index'

import { styles } from '../../styles/js/dialog'

const DialogResponsavelComponent = (props) => {
  const [isDialogLookupOpen, setIsDialogLookupOpen] = useState(false)

  const {
    classes,
    responsavelDialogState,
    setResponsavelDialogState,
    handleAction,
    handleClose,
    dialogOpen,
    actionTitle,
    rows,
    selectedRow,
    selectedRows,
  } = props

  let titleDescription = ''
  if (selectedRow || selectedRows.length > 0) {
    titleDescription = generateDialogsSubtitle(selectedRow, rows, selectedRows)
  }

  return (
    <React.Fragment>
      <Dialog
        maxWidth={'sm'}
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="dialogTitle"
        scroll="body"
        PaperProps={{
          className: classes.dialog
        }}
      >
        <DialogTitle id="dialogTitle">
          {actionTitle}
          <Typography variant="body2" gutterBottom>
            {titleDescription}
          </Typography>
        </DialogTitle>
        
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button 
                color='primary' 
                style={{ width: '100%' }}
                onClick={() => setIsDialogLookupOpen(true)}
                >
                {responsavelDialogState.responsavel ?
                  'Responsável: ' + responsavelDialogState.responsavel
                  :
                  'Clique aqui para escolher um responsável'
                }
              </Button>
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="informacoesInternas"
                label="Observações"
                multiline
                rows="5"
                value={responsavelDialogState.informacoesInternas}
                onChange={event => setResponsavelDialogState({ ...responsavelDialogState, informacoesInternas: event.target.value })}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} style={{ color: '#c62334' }}>
            Cancelar
          </Button>
          <Button onClick={handleAction} style={{ color: '#009d93' }}>
            {actionTitle}
          </Button>
        </DialogActions>
      </Dialog>

      <DialogLookup 
        Component={Usuarios}
        title={'Usuários'}
        size='md'
        isOpen={isDialogLookupOpen}
        setIsOpen={setIsDialogLookupOpen}
        setLookupField={row => setResponsavelDialogState({ 
          ...responsavelDialogState, 
          idResponsavel: row.id, 
          responsavel: row.nome,
        })}
        lookupFilter={{ usuarioCrc: 1 }}
      />
    </React.Fragment>
  )
}

export default withStyles(styles)(DialogResponsavelComponent)