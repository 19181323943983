import React from 'react'

import DialogChatSuggestionsComponent from '../components/Dialogs/DialogChatSuggestionsComponent.jsx'

const DialogChatSuggestions = props => {

  const {
    dialogOpen,
    setDialogOpen,
    getSuggestion,
  } = props

  const handleClose = () => {
    setDialogOpen(false)
  }

  const handleSelectedSuggestion = suggestion => {
    getSuggestion(suggestion)
    handleClose()
  }

  return (
    <DialogChatSuggestionsComponent
      handleClose={handleClose}
      dialogOpen={dialogOpen}
      handleSelectedSuggestion={handleSelectedSuggestion}
    />
  )
}

export default DialogChatSuggestions
