import React, { useState, useEffect } from 'react'

import { useRequest } from '../hooks'

import DialogTicketsFromTelespectadorComponent from '../components/Dialogs/DialogTicketsFromTelespectadorComponent'

import { initialPagination } from '../constants/initialConstants'

const DialogTicketsFromTelespectador = props => {
  const [pagination, setPagination] = useState(initialPagination)

  const { dialogOpen, setDialogOpen, telespectador } = props

  const [tickets, statusRequestTickets, fetchTickets] = useRequest(
    [],
    {
      url: 'tickets',
      method: 'get',
      params: {
        pageSize: pagination.pageSize,
        currentPage: pagination.currentPage,
        telespectadorId: telespectador ? telespectador.id : '',
      },
    },
    null
  )

  useEffect(() => {
    if (telespectador.id) {
      fetchTickets()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [telespectador])

  useEffect(() => {
    if (telespectador.id) {
      fetchTickets()
    }
  }, [pagination])

  const handleClose = () => {
    setDialogOpen(false)
  }

  return (
    <DialogTicketsFromTelespectadorComponent
      handleClose={handleClose}
      dialogOpen={dialogOpen}
      tickets={tickets}
      telespectador={telespectador}
      statusRequestTickets={statusRequestTickets}
      fetchTickets={fetchTickets}
      pagination={pagination}
      setPagination={setPagination}
    />
  )
}

export default DialogTicketsFromTelespectador
