import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom"

import { Grid } from '@material-ui/core'

import { useRequest } from '../hooks'

import { usuariosSearchFormFields, initialPagination } from '../constants/initialConstants'
import SearchFormUsuarios from '../components/SearchForms/SearchFormUsuarios'
import TableUsuarios from '../components/Tables/TableUsuarios'

const Usuarios = (props) => {
  const { 
    populateLookupField, 
    lookupFilter, 
    withSelect,
    setSelection
  } = props

  const [filterState, setFilterState] = useState({})
  
  const [pagination, setPagination] = useState(initialPagination)

  const [usuarios, statusRequestUsuarios, fetchUsuarios] = useRequest(
    [],
    {
      url: 'usuarios',
      params: {
        pageSize: pagination.pageSize,
        currentPage: pagination.currentPage,
        ...lookupFilter,
        ...filterState,
      },
      method: 'get',
    },
    null
  )

  useEffect(() => {
    fetchUsuarios()
    setFilterState(usuariosSearchFormFields)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState])

  useEffect(() => {
    fetchUsuarios()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  const filter = (searchFormState) => {
    setFilterState(searchFormState)
  }

  const handleOnRowClick = (row) => {
    if (populateLookupField && !withSelect) {
      populateLookupField(row)
    } else {
      return
    }
  }

  return (
    <div>
      <Grid container>
        <SearchFormUsuarios
          setFilterState={setFilterState}
          filter={filter}
        />

        <TableUsuarios
          rows={usuarios.rows === undefined ? [] : usuarios.rows}
          rowsLength={usuarios.count}
          onRowClick={handleOnRowClick}
          pagination={pagination}
          setPagination={setPagination}
          statusRequestUsuarios={statusRequestUsuarios}
          withSelect={withSelect}
          setSelection={setSelection}
          withHover={withSelect ? false : true}
        />
      </Grid>
    </div>
  )
}

export default withRouter(Usuarios)