import React, { useState, useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
  AppBar,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Grid
} from '@material-ui/core'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import MenuIcon from '@material-ui/icons/Menu'

import DrawerContent from '../components/DrawerContent.jsx'
import { ApplicationContext } from '../ContextProvider.jsx'
import { logout } from '../helpers/authenticator'

import { withStyles } from '@material-ui/core/styles'
import { styles } from '../styles/js/navbar'
import DialogTicket from '../containers/DialogTicket.jsx'

import * as usuarios from '../constants/usuarios'

import { READ_ONLY_MODE } from '../constants/api.js'

function Navbar(props) {
  const [dialogTicketOpen, setDialogTicketOpen] = useState(false)
  const [mobileOpen, setMobileOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const { classes } = props
  const { user, ticketCreated, setTicketCreated } = useContext(
    ApplicationContext
  )

  const path = props.match.path

  return (
    <div>
      <CssBaseline />
      <AppBar position='fixed' className={classes.appBar} elevation={0}>
        <Toolbar variant='dense'>
          <IconButton
            color='inherit'
            aria-label='Open drawer'
            onClick={() => setMobileOpen(!mobileOpen)}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <img
            className={classes.logoRPC}
            src='/rpc-logo-fundo-escuro.png'
            alt='Logo RPC'
          />

          <Typography
            variant='h6'
            color='inherit'
            noWrap
            className={classes.navLinkMobile}
          >
            {props.appBarTitle}
          </Typography>

          <Link to='/' className={classes.link}>
            {/* <Badge badgeContent={user.usuario_crc === usuarios.CRC ? 99 : ''} classes={{ badge: classes.badge }}> */}
            <Typography
              variant='h6'
              color='inherit'
              noWrap
              className={
                path === '/' ? classes.navLinkSelected : classes.navLink
              }
            >
              Caixa de Entrada
            </Typography>
            {/* </Badge> */}
          </Link>

          <Link to='/atendimentos' className={classes.link}>
            {/* <Badge badgeContent={user.usuario_crc === usuarios.CRC ? 99 : ''} color='secondary'> */}
            <Typography
              variant='h6'
              color='inherit'
              noWrap
              className={
                path === '/atendimentos'
                  ? classes.navLinkSelected
                  : classes.navLink
              }
            >
              Atendimentos
            </Typography>
            {/* </Badge> */}
          </Link>

          <Link to='/telespectadores' className={classes.link}>
            <Typography
              variant='h6'
              color='inherit'
              noWrap
              className={
                path === '/telespectadores'
                  ? classes.navLinkSelected
                  : classes.navLink
              }
            >
              Telespectadores
            </Typography>
          </Link>

          {user.usuario_crc === usuarios.CRC && !READ_ONLY_MODE && (
            <Typography
              variant='h6'
              color='inherit'
              noWrap
              className={classes.navAction}
              style={{ borderLeft: '1px solid white' }}
              onClick={() => setDialogTicketOpen('create')}
            >
              Criar Atendimento
            </Typography>
          )}

          {(user.usuario_crc === usuarios.CRC ||
            user.usuario_crc === usuarios.CONTEUDO) && !READ_ONLY_MODE && (
            <Typography
              variant='h6'
              color='inherit'
              noWrap
              className={classes.navAction}
              onClick={() => setDialogTicketOpen('createApp')}
            >
              Falar c/ Telespectador (APP)
            </Typography>
          )}

          {(user.usuario_crc === usuarios.CONVIDADO ||
            user.usuario_crc === usuarios.CRC) && (
            <Link to='/mensagens' className={classes.link}>
              <Typography
                variant='h6'
                color='inherit'
                noWrap
                className={
                  path === '/mensagens'
                    ? classes.navLinkSelected
                    : classes.navLink
                }
              >
                Mensagens
              </Typography>
            </Link>
          )}

          <div className={classes.profileIcon}>
            <IconButton
              aria-label='Conta do usuário'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={event => setAnchorEl(event.currentTarget)}
              color='inherit'
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              getContentAnchorEl={null}
              open={!!anchorEl}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={() => setAnchorEl(null)}>{user.nome}</MenuItem>
              <MenuItem onClick={() => logout()}>Sair</MenuItem>
            </Menu>
          </div>
        </Toolbar>
        {READ_ONLY_MODE && (
          <Grid container justifyContent='center' style={{ background: 'yellow', color: 'black', fontWeight: 'bold' }}>
            <Typography variant='h6' color='inherit'>
              Modo somente leitura
            </Typography>
          </Grid>
        )}
      </AppBar>
      {READ_ONLY_MODE && (
        <div style={{ height: 56 }} />
      )}
      <nav className={classes.drawer}>
        <Hidden smUp implementation='css'>
          <Drawer
            container={props.container}
            variant='temporary'
            anchor={'left'}
            open={mobileOpen}
            onClose={() => setMobileOpen(!mobileOpen)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <DrawerContent />
          </Drawer>
        </Hidden>
      </nav>

      <DialogTicket
        dialogOpen={dialogTicketOpen}
        setDialogOpen={setDialogTicketOpen}
        fetchTickets={() => setTicketCreated(!ticketCreated)}
        handleTicketDialogReset={() => {}}
      />
    </div>
  )
}

export default withStyles(styles)(withRouter(Navbar))
