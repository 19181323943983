import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import ChatActions from '../components/Chat/ChatActions.jsx'
import ChatComponent from '../components/Chat/ChatComponent.jsx'
import ChatNotes from '../components/Chat/ChatNotes.jsx'
import { styles } from '../styles/js/chat'
import { useRequest } from '../hooks'
import { initialTelespectadorState, initialChatNotesState, initialChatComponentState } from '../constants/initialConstants'

import firebaseApp from '../constants/firebase'

const Chat = (props) => {
  const { classes } = props

  const [initOnSnapshot, setInitOnSnapshot] = useState(null)
  const [telespectador, setTelespectador] = useState(initialTelespectadorState)
  const [chatNotesState, setChatNotesState] = useState(initialChatNotesState)
  const [chatComponentState, setChatComponentState] = useState(initialChatComponentState)
  const [renderFullTicket, setRenderFullTicket] = useState(true)

  const [ticket, statusRequestTicket, fetchTicket] = useRequest(
    [],
    {
      url: 'ticket/' + props.match.params.id,
      method: 'get',
    },
    null
  )

  const [, , criarComentario] = useRequest(
    {},
    {
      url: 'tickets_comentarios',
      method: 'post',
      data: chatNotesState
    },
    null,
    {
      onComplete: () => {
        setRenderFullTicket(false)
        fetchTicket()
      }
    }
  )

  const [, , criarMensagemUsuario] = useRequest(
    {},
    {
      url: 'tickets_mensagens',
      method: 'post',
      data: chatComponentState,
      params: {
        mensagemUsuario: true
      }
    },
    null,
    {
      onComplete: () => {
        setRenderFullTicket(false)
        fetchTicket()
      }
    }
  )

  const [, , criarMensagemTelespectador] = useRequest(
    {},
    {
      url: 'tickets_mensagens',
      method: 'post',
      data: chatComponentState,
      params: {
        mensagemTelespectador: true
      }
    },
    null,
    {
      onComplete: () => {
        setRenderFullTicket(false)
        fetchTicket()
      }
    }
  )

  const [, , updateMensagem] = useRequest(
    {},
    {
      url: 'tickets_mensagens',
      method: 'put',
      data: chatComponentState
    },
    null,
    {
      onComplete: () => {
        setRenderFullTicket(false)
        fetchTicket()
      }
    }
  )

  const [, , updateComentario] = useRequest(
    {},
    {
      url: 'tickets_comentarios',
      method: 'put',
      data: chatNotesState
    },
    null,
    {
      onComplete: () => {
        setRenderFullTicket(false)
        fetchTicket()
      }
    }
  )

  useEffect(() => {
    fetchTicket()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (ticket.length !== 0) {
      setTelespectador({ ...ticket.telespectador })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket.telespectador])

  const onSnapshot = useRef(() => {})
  useEffect(() => {
    if (initOnSnapshot === null || initOnSnapshot === 'criando') {
      onSnapshot.current = () => {
        setInitOnSnapshot('criado')
      }
    } else {
      onSnapshot.current = () => {
        fetchTicket()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initOnSnapshot])

  const createOnSnapshot = useRef(() => {})
  useEffect(() => {
    if (ticket.telespectador && ticket.telespectador.uid) {
      createOnSnapshot.current = () => {
        firebaseApp.firestore().collection('flerken-dash-control').doc("DASH_CRC").collection('CHATS').doc(ticket.telespectador.uid).onSnapshot((doc) => {
          onSnapshot.current()
        }, (error) => {
          console.log('FirebaseError', error)
        })
      }
    }
  }, [ticket])

  useEffect(() => {
    if (statusRequestTicket.done && initOnSnapshot === null && ticket.origem?.nome?.toLowerCase() === 'app') {
      setInitOnSnapshot('criando')
      createOnSnapshot.current()
    }
  }, [ticket, statusRequestTicket, initOnSnapshot])

  const renderComponents = () => {
    if (!statusRequestTicket.done && renderFullTicket) {
      return <p></p>
    }

    return (
      <Grid container style={{ marginTop: 16 }}>
        <Grid item xs={12} md={8}>
          <ChatComponent 
            fromApp={ticket.origem?.nome?.toLowerCase() === 'app'}
            isClosed={ticket.status === 3}
            ticket={ticket}
            chatComponentState={chatComponentState}
            setChatComponentState={setChatComponentState}
            statusRequestTicket={statusRequestTicket}
            renderFullTicket={renderFullTicket}
            criarMensagemUsuario={criarMensagemUsuario}
            criarMensagemTelespectador={criarMensagemTelespectador}
            updateMensagem={updateMensagem}
          />
        </Grid>
        
        <Grid item xs={12} md={4} className={classes.viewerSectionRoot}>
          <ChatActions 
            isClosed={ticket.status === 3}
            ticket={ticket}
            setRenderFullTicket={setRenderFullTicket}
            fetchTicket={fetchTicket}
            telespectador={telespectador}
          />
          <ChatNotes 
            ticket={ticket}
            chatNotesState={chatNotesState}
            setChatNotesState={setChatNotesState}
            criarComentario={criarComentario}
            updateComentario={updateComentario}
            statusRequestTicket={statusRequestTicket}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <div>
      {renderComponents()}
    </div>
  )
}

export default withStyles(styles)(withRouter(Chat))