import React, { useState, useEffect } from 'react'

import AdvancedTable from '../AdvancedTable.jsx'
import { setLocalStorage } from '../../helpers/table'
import { getTableCidadesDefinitions } from '../../constants/tablesDefinitions'

const TableCidades = (props) => {
  const { 
    rows, 
    rowsLength,
    pagination,
    setPagination,
    onRowClick,
    withSelect,
    setSelection,
    withHover,
  } = props

  const tableDefinitions = getTableCidadesDefinitions(pagination)

  const [tableStates, setTableStates] = useState(
    { ...tableDefinitions, rows }
  )

  useEffect(() => {
    setTableStates({ ...tableStates, rows: rows, rowsLength: rowsLength })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows])

  useEffect(() => {
    let selectedRows = []
    tableStates.selection.map(selection => {
      return selectedRows.push(rows.find(row => row.id === selection))
    })

    setSelection(selectedRows)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableStates.selection])

  useEffect(() => {
    setLocalStorage('columnOrderCidades', tableStates.columnOrder)
    setLocalStorage('columnWidthsCidades', tableStates.columnWidths)
  }, [tableStates.columnOrder, tableStates.columnWidths])

  return (
    <AdvancedTable
      tableStates={tableStates}
      setTableStates={setTableStates}
      onRowClick={row => onRowClick(row)}
      totalCount={rowsLength}
      pagination={pagination}
      setPagination={setPagination}
      statusRequest={{ done: true }}
      bigPadding
      showSelect={withSelect}
      withHover={withHover}
    />
  )
}

export default TableCidades
