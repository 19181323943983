import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'

import { Grid, LinearProgress } from '@material-ui/core'

import { useRequest } from '../hooks'

import { mensagensSearchFormFields } from '../constants/initialConstants'
import SearchFormMensagens from '../components/SearchForms/SearchFormMensagens'
import TableMensagens from '../components/Tables/TableMensagens'
import { styles } from '../styles/js/atendimentos'
import { withStyles } from '@material-ui/core/styles'

import moment from 'moment'

const Mensagens = props => {
  const [filterState, setFilterState] = useState({
    ...mensagensSearchFormFields,
  })

  const [pagination, setPagination] = useState({
    limit: 20,
    offset: 0,
  })

  const paginationRef = useRef(pagination)
  const filterStateRef = useRef(filterState)

  const [mensagens, statusRequestMensagens, fetchMensagens] = useRequest(
    [],
    {
      url: 'tickets_mensagens',
      method: 'get',
      params: {
        limit: pagination.limit,
        offset: pagination.offset,
        ...filterState,
        dataInicial: filterState.dataInicial
          ? moment(filterState.dataInicial).format('YYYY-MM-DD 00:00:00')
          : '',
        dataFinal: filterState.dataFinal
          ? moment(filterState.dataFinal).format('YYYY-MM-DD 23:59:59')
          : '',
      },
    },
    null
  )

  const [regioes, , fetchRegioes] = useRequest(
    [],
    {
      url: 'regioes',
      params: {
        active: 1,
      },
      method: 'get',
    },
    null
  )

  const [categorias, , fetchCategorias] = useRequest(
    [],
    {
      url: 'categorias',
      params: {
        active: 1,
      },
      method: 'get',
    },
    null
  )

  useEffect(() => {
    if(regioes.length === 0) {
      fetchRegioes()
    }

    if(categorias.length === 0) {
      fetchCategorias()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regioes, categorias])

  useEffect(() => {
    const { current } = paginationRef

    if((current.offset !== pagination.offset) || (current.offset === 0 && pagination.offset === 0)) {
      fetchMensagens()
    }
    // window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  const filter = () => {
    setPagination({
      limit: 20,
      offset: 0
    })
  }


  return (
    <div>
      <Grid container spacing={1}>
        <SearchFormMensagens
          filterState={filterState}
          setFilterState={setFilterState}
          filter={filter}
          regioes={regioes}
          categorias={categorias}
        />
        {/* {statusRequestMensagens.isLoading && (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        )} */}
        {/* {JSON.stringify(mensagens)} */}
        <TableMensagens
          rows={mensagens.rows === undefined ? [] : mensagens.rows}
          rowsLength={mensagens.rows === undefined ? 0 : mensagens.rows.length}
          pagination={pagination}
          setPagination={setPagination}
          fetchMensagens={fetchMensagens}
          filterState={filterState}
          filterStateRef={filterStateRef}
        />
      </Grid>
    </div>
  )
}

export default withRouter(withStyles(styles)(Mensagens))
