export const styles = theme => ({
  title: {
    paddingBottom: 0,
  },

  chip: {
    marginRight: 10,
    marginTop: 10,
  },

  tagsSection: {
    marginLeft: 15, 
    marginRight: 15, 
    borderLeft: '1px solid grey', 
    padding: 10,
  }
})