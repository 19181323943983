import React, { useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import { SALESFORCE_URL, READ_ONLY_MODE } from '../../constants/api'

import { Link } from 'react-router-dom'
import { Chip, Link as MaterialLink } from '@material-ui/core'
import { Grid, Paper, Typography, LinearProgress } from '@material-ui/core'
import DialogTelespectador from '../../containers/DialogTelespectador'
import { withStyles } from '@material-ui/core/styles'
import { styles } from '../../styles/js/tableMensagens'
import moment from 'moment'
import 'moment/locale/pt-br'

const TableMensagens = ({
  classes,
  rows,
  rowsLength,
  pagination,
  setPagination,
  fetchMensagens,
  filterState,
  filterStateRef,
}) => {
  const [idTelespectador, setIdTelespectador] = useState(false)
  const [mensagens, setMensagens] = useState([])

  useEffect(() => {
    setMensagens([...mensagens, ...rows])
    
    // window.scrollTo({
    //   top: document.body.scrollHeight,
    //   behavior: 'smooth'
    // });
  }, [rows])

  useEffect(() => {
    const prevFilterState = JSON.stringify(filterStateRef.current)
    const currentFilterState = JSON.stringify(filterState)

    if(prevFilterState !== currentFilterState) {
      setMensagens([])
    }
  }, [filterState])

  const handlePaginationClick = () => {
    setPagination({ ...pagination, offset: pagination.offset + 20 })
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <InfiniteScroll
            style={{ overflow: 'hidden' }}
            dataLength={mensagens.length}
            next={handlePaginationClick}
            endMessage={
              <Grid container justifyContent='center'>
                <Grid><Typography variant='h6'>Fim das mensagens</Typography></Grid>
              </Grid>
            }
            loader={<Grid item xs={12}><LinearProgress /></Grid>}
            hasMore={rowsLength > 0}
          >
            {mensagens.map(row => (
              <Paper className={classes.paper} key={row.id}>
                <Grid container justifyContent='flex-start'>
                  <Grid item xs={6}>
                    <Typography variant='h6'>
                      {row.telespectador.nome} -{' '}
                      <span style={{ color: '#666', fontWeight: 400 }}>
                        {row.telespectador.telefone || row.telespectador.celular || 'Sem telefone'}{' '}-{' '}
                      </span>
                      <span style={{ color: '#666', fontWeight: 400 }}>
                        {row.regiao?.nome || 'Sem região'}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                        {row.palavras_chaves.map(val => <Chip label={val.nome} style={{marginLeft: 5}}/>)}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant='subtitle2'
                      style={{ marginBottom: 16, fontWeight: 400, color: '#666' }}
                    >
                      {moment(row.abertura).format('LLL')}
                    </Typography>
                  </Grid>
                </Grid>
                {row.mensagens.map(mensagem =>
                  mensagem.tipo === 1 ? (
                    <Grid container justifyContent='flex-start' key={mensagem.id}>
                      <div className={classes.viewerMessage}>
                        <Typography variant='body2'>
                          {mensagem.mensagem}
                        </Typography>
                      </div>
                    </Grid>
                  ) : (
                    <Grid container justifyContent='flex-end' key={mensagem.id}>
                      <div className={classes.userMessage}>
                        {mensagem.mensagem}
                      </div>
                    </Grid>
                  )
                )}
                <Grid container justifyContent='space-between' style={{ marginTop: 16 }}>
                  <Typography variant='h6' className={classes.viewerName}>
                    {READ_ONLY_MODE && row.telespectador.id_salesforce_telespectador ? (
                      <MaterialLink
                        href={`${SALESFORCE_URL}/${row.telespectador.id_salesforce_telespectador}`}
                        target='_blank'
                      >
                        Ver na Salesforce
                      </MaterialLink>
                    ) : (<>
                      <MaterialLink
                        onClick={() => setIdTelespectador(row.telespectador.id)}
                      >
                        Editar Telespectador
                      </MaterialLink>
                      {/* {' '}|{' '}
                      <span style={{ color: '#666', fontWeight: 400 }}>
                        {row.telespectador.telefone || row.telespectador.celular}
                      </span> */}
                    </>)}
                  </Typography>

                  <Typography variant='h6' className={classes.seeMore}>
                    <Link
                      to={'/chat/' + row.id}
                      target='_blank'
                      rel='noopener'
                      className={classes.link}
                    >
                      Ver mais...
                    </Link>
                  </Typography>
                </Grid>
              </Paper>
            ))}
          </InfiniteScroll>
        </Grid>

        {/* <Grid item xs={12}>
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 24 }}
          >
            <Pagination
              limit={pagination.pageSize}
              offset={pagination.currentPage * pagination.pageSize}
              total={rowsLength ? rowsLength : 0}
              onClick={(e, offset) => handlePaginationClick(offset)}
              size='large'
            />
          </div>
        </Grid> */}
      </Grid>
      <DialogTelespectador
        dialogOpen={!!idTelespectador ? 'edit' : false}
        setDialogOpen={setIdTelespectador}
        idTelespectador={idTelespectador}
        fetchTelespectadores={fetchMensagens}
        handleTelespectadorDialogReset={() => {}}
      />
    </>
  )
}

export default withStyles(styles)(TableMensagens)
