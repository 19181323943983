import React, { useState, useEffect } from 'react'
import { Button, Grid, Paper, MenuItem, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import DatePickerComponent from '../../components/DatePickerComponent'
import { inboxSearchFormFields } from '../../constants/initialConstants'
import { styles } from '../../styles/js/searchForm'
import 'moment/locale/pt-br'

function SearchFormInbox(props) {
  const {
    classes,
    filter,
    filterState,
    setFilterState,
    regioes,
    origens,
  } = props

  const {
    id,
    dataInicial,
    dataFinal,
    telespectador,
    praca,
    origem,
  } = filterState

  // useEffect(() => {
  //   if (JSON.stringify(filterState) === JSON.stringify(inboxSearchFormFields)) {
  //     resetFilterState()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filterState])

  // useEffect(() => {
  //   if (
  //     JSON.stringify(searchFormState) === JSON.stringify(inboxSearchFormFields)
  //   ) {
  //     setFilterState('')
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [searchFormState])

  const resetFilterState = async () => {
    await setFilterState(inboxSearchFormFields)
    filter('')
  }

  // const resetSearchFormState = () => {
  //   setSearchFormState(inboxSearchFormFields)
  //   setFilterState('')
  // }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      filter('')
    }
  }

  return (
    <Paper className={classes.paper} elevation={0} onKeyPress={handleKeyPress}>
      <form className={classes.container} noValidate autoComplete='off'>
        <Grid container spacing={1}>
          <Grid item xs={12} md={5}>
            <div className={classes.formSection}>
              <span className={classes.formSectionLabel}>
                Datas de Cadastro
              </span>

              <Grid container>
                <Grid item xs={12} sm={6}>
                  <DatePickerComponent
                    onChange={value =>
                      setFilterState({
                        ...filterState,
                        dataInicial: value,
                      })
                    }
                    value={dataInicial}
                    id='dataInicial'
                    label='Data Início'
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <DatePickerComponent
                    onChange={value =>
                      setFilterState({
                        ...filterState,
                        dataFinal: value,
                      })
                    }
                    value={dataFinal}
                    id='dataFinal'
                    label='Data Fim'
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} md={7}>
            <div className={classes.formSection}>
              <span className={classes.formSectionLabel}>Geral</span>

              <Grid container>
                <Grid item xs={12} sm={2}>
                  <TextField
                    id='id'
                    label='ID'
                    className={classes.textField}
                    value={id || ''}
                    onChange={event =>
                      setFilterState({
                        ...filterState,
                        id: event.target.value,
                      })
                    }
                    margin='normal'
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    id='telespectador'
                    label='Telespectador'
                    className={classes.textField}
                    value={telespectador}
                    onChange={event =>
                      setFilterState({
                        ...filterState,
                        telespectador: event.target.value,
                      })
                    }
                    margin='normal'
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    id='praca'
                    select
                    label='Praça'
                    className={classes.textField}
                    value={praca}
                    onChange={event =>
                      setFilterState({
                        ...filterState,
                        praca: event.target.value,
                      })
                    }
                    margin='normal'
                  >
                    {regioes.map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    id='origem'
                    select
                    label='Origem'
                    className={classes.textField}
                    value={origem}
                    onChange={event =>
                      setFilterState({
                        ...filterState,
                        origem: event.target.value,
                      })
                    }
                    margin='normal'
                  >
                    {origens.map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <Button
          onClick={() => filter(filterState)}
          variant='contained'
          className={classes.filterButton}
          disabled={
            JSON.stringify(filterState) ===
            JSON.stringify(inboxSearchFormFields)
              ? true
              : false
          }
        >
          Filtrar
        </Button>
        <Button
          onClick={resetFilterState}
          variant='contained'
          className={classes.clearButton}
          disabled={
            JSON.stringify(filterState) ===
            JSON.stringify(inboxSearchFormFields)
              ? true
              : false
          }
        >
          Limpar
        </Button>
      </form>
    </Paper>
  )
}

export default withStyles(styles)(SearchFormInbox)
